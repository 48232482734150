import {
  AccountDetails,
  BankStatement,
  BankStatementDetails,
  CreditDebit,
  DailyCashBalance,
  DrawRequests,
  CashBalance,
  AdminManagement,
  CashManagement,
  CapitalCall,
  ShortTermLoanPlan,
  OutstandingCheckList
} from "../pages";
import {
  DollarCircleOutlined,
  BankOutlined,
  CreditCardOutlined,
  LineChartOutlined,
  BarChartOutlined,
  ContainerOutlined,
  FundOutlined,
  AuditOutlined,
  PartitionOutlined,
  ProfileOutlined
} from "@ant-design/icons";
import { UnauthorizedPage } from "../components";
import { Statements } from "../pages/Statements/Statements";

export const routes = [
  {
    key: "/",
    element: <CashBalance />,
    label: "Cash Balance",
    icon: <DollarCircleOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/cash-management",
    element: <CashManagement />,
    label: "Cash Management",
    icon: <BarChartOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/short-term-loan-plan",
    element: <ShortTermLoanPlan />,
    label: "Short-term loan plan",
    icon: <FundOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/draw-requests",
    element: <DrawRequests />,
    label: "Draw Requests",
    icon: <ContainerOutlined style={{ fontSize: "1rem" }} />
  },
  {
    key: "/check-list",
    element: <OutstandingCheckList />,
    label: "Outstanding Check List",
    icon: <ProfileOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/bank-statement",
    element: <BankStatement />,
    label: "Bank Statement",
    icon: <BankOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/statements",
    element: <Statements />,
    label: "CMS Statements",
    icon: <BarChartOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/distribution-capital-call",
    element: <CapitalCall />,
    label: "Distribution & Capital Call",
    icon: <AuditOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/debit-credit",
    element: <CreditDebit />,
    label: "Debit/Credit",
    icon: <CreditCardOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/daily-cash-balance",
    element: <DailyCashBalance />,
    label: "Daily Cash Balance",
    icon: <LineChartOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/admin-management",
    element: <AdminManagement />,
    label: "Admin Management",
    icon: <PartitionOutlined style={{ fontSize: "1rem" }} />,
    guard: ["Treasury", "Accounting"]
  },

  {
    key: "/bank-statement/:id",
    element: <BankStatementDetails />,
    label: "Bank Statement Details",
    skipMenuRender: true,
    guard: ["Treasury", "Accounting"]
  },

  {
    key: "/account-details/:id",
    element: <AccountDetails />,
    label: "Account Details",
    skipMenuRender: true,
    guard: ["Treasury", "Accounting"]
  },
  {
    key: "/401",
    element: <UnauthorizedPage />,
    label: "Unauthorized",
    skipMenuRender: true
  }
];
