import { FC, useContext, useMemo, useState } from "react";
import { Row, Col, Tag, message } from "antd";
import { IManipulateReportsFilters, IManipulateReportsOptions, ManipulateReports } from "../../components";
import { Account, DownloadFormat } from "../../types/account";
import { AccountModal, DisplaySelection } from "./components";
import moment, { isMoment } from "moment";
import { DataContext } from "../../contexts/DataContext";
import { CashManagementTable } from "./components/CashManagementTable/CashManagementTable";
import { CashManagementModalOptions } from "../../types";
import { CashManagementRecord } from "../../types/cash-management";
import { useMutation, useQuery } from "@tanstack/react-query";
import { cashManagementService } from "../../services/cash-management";
import { generateDownload } from "../../helpers/generateDownload";

interface Props {}

const options: IManipulateReportsOptions = {
  displayOptions: [{ value: "monthly", label: "Monthly" }],
  rangeOptions: [
    { value: "30-days", label: "Next 30 Days" },
    { value: "yesterday", label: "Yesterday" },
    { value: "today", label: "Today" },
    { value: "this-month", label: "This Month" },
    { value: "next-month", label: "Next Month" },
    { value: "range", label: "Range" }
  ],
  searchOption: true,
  downloadOptions: ["excel"]
};

export const CashManagement: FC<Props> = () => {
  const [filters, setFilters] = useState<IManipulateReportsFilters>({
    displayMode: "monthly",
    downloadType: "excel",
    rangeOptionValue: "30-days",
    searchValue: "",
    range: [moment(), moment().add(30, "day")]
  });
  const { accounts } = useContext(DataContext);
  const [selectedValue, setSelectedValue] = useState<number | number[]>(3);
  const isMultipleAccounts = useMemo(() => Array.isArray(selectedValue), [selectedValue]);
  const [modalOptions, setModalOptions] = useState<CashManagementModalOptions>({ visible: false, payload: undefined });
  const { addRecord, editRecord } = cashManagementService;
  const {
    data: records,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    [
      "cash-management",
      selectedValue,
      isMoment(filters.range) ? filters.range.format("MM/DD/YYYY") : (filters.range as moment.Moment[])[0].format("MM/DD/YYYY"),
      isMoment(filters.range) ? filters.range.format("MM/DD/YYYY") : (filters.range as moment.Moment[])[1].format("MM/DD/YYYY"),
      filters.searchValue
    ],
    (context) =>
      cashManagementService.getCashManagementRecords(
        selectedValue,
        context.queryKey[2] as string,
        context.queryKey[3] as string,
        filters.searchValue || ""
      ),
    {
      initialData: []
    }
  );
  console.log("-=-=-CHECK RECORDS HERE-=-=-", records);

  const { mutate: createOrUpdateRecord, isLoading: mutationLoading } = useMutation(
    async (data: CashManagementRecord[]) => {
      const ifNewIndex = data.findIndex((item) => item.id === -1);
      if (ifNewIndex >= 0) {
        if (data.length === 2) {
          cashManagementService.addRecord([data[ifNewIndex]]);
          return cashManagementService.editRecord(data.filter((item) => item.id !== -1));
        } else return cashManagementService.addRecord([data[ifNewIndex]]);
      } else {
        return cashManagementService.editRecord(data);
      }
    },
    {
      onSuccess: (data, variables) => {
        message.success(`Successfully updated/created record.`);
        refetch();
      },
      onError: (_) => {
        message.error(`Error updating/creating record.`);
      }
    }
  );

  const filterAccounts = (id: number) => accounts.filter((item) => item.accountID === id)[0];

  const onFiltersChange = (filters: IManipulateReportsFilters) => {
    setFilters(filters);
  };

  const handleDownload = async (value: DownloadFormat) => {
    try {
      message.loading({ content: "Downloading file...", key: "downloading" });
      const params = {
        id: selectedValue,
        startDate: isMoment(filters.range)
          ? filters.range.format("MM/DD/YYYY")
          : (filters.range as moment.Moment[])[0].format("MM/DD/YYYY"),
        endDate: isMoment(filters.range) ? filters.range.format("MM/DD/YYYY") : (filters.range as moment.Moment[])[1].format("MM/DD/YYYY")
      };
      const file = await cashManagementService.downloadCashManagementRecords(params.id, params.startDate, params.endDate);
      generateDownload(
        file,
        `${accounts
          .filter((account) =>
            Array.isArray(selectedValue) ? selectedValue.includes(account.accountID!) : account.accountID === selectedValue
          )
          .map((account) => `${account.accountName} (${account.accountNumber})`)
          .join("-")} - Cash Management for ${params.startDate} - ${params.endDate}.xlsx`,
        "excel"
      );
      message.success({ content: "Success", key: "downloading" });
    } catch (err) {
      message.error({ content: err, key: "downloading" });
    }
  };

  const handleSearch = (value: string) => {};
  const handleCopy = (arg: (Account & CashManagementRecord) | undefined) => {
    setModalOptions({ visible: true, payload: arg });
  };

  const handleSave = (arg: CashManagementRecord[]) => {
    createOrUpdateRecord(arg);
  };
  return (
    <div>
      <Row justify="space-between" gutter={[60, 0]}>
        <Col span={14}>
          <ManipulateReports options={options} filters={filters} onFiltersChange={onFiltersChange} onDownloadClick={handleDownload} />
        </Col>
        <Col span={10}>
          <DisplaySelection selection={{ selectedValue, setSelectedValue }} />
        </Col>
        <Col span={24} style={{ margin: "2rem" }}>
          <Tag color="#108ee9" className="tag" style={{ fontSize: "1.2rem", padding: "0.5rem" }}>
            {moment().format("MMMM Do YYYY")}
          </Tag>
        </Col>
        <Col span={isMultipleAccounts ? 12 : 24} style={{ paddingLeft: "30px" }}>
          <CashManagementTable
            account={{ ...filterAccounts(Array.isArray(selectedValue) ? selectedValue[0] : selectedValue), code: "----" }}
            handleCopy={handleCopy}
            handleSave={handleSave}
            data={records[0] || []}
            isLoading={isLoading || isFetching || mutationLoading}
            refetchFunction={refetch}
          />
        </Col>
        {isMultipleAccounts && (selectedValue as number[])[1] ? (
          <Col span={12}>
            <CashManagementTable
              data={records[1] || []}
              account={{ ...filterAccounts((selectedValue as number[])[1]), code: "----" }}
              handleCopy={handleCopy}
              handleSave={handleSave}
              isLoading={isLoading || isFetching || mutationLoading}
              refetchFunction={refetch}
            />
          </Col>
        ) : null}
      </Row>
      <AccountModal options={modalOptions} setOptions={setModalOptions} />
    </div>
  );
};
