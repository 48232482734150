import { useContext } from "react";
import { convertWithCommas } from "../../../helpers";
import moment from "moment";
import { DataContext } from "../../../contexts/DataContext";
import { Account } from "../../../types/account";
import { ColumnTypes } from "../../../components/EditableTable/interfaces";

export const useColumnsOutstanding = () => {

  const { accounts } = useContext(DataContext);

  /**========================================================================
   *                          Table's Columns
   *========================================================================**/
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; type?: 'text' | 'number' | 'date' })[] = [
    {
      title: "Check Date",
      dataIndex: "checkDate",
      sorter: (a: any, b: any) => {
        try {
          return (new Date(a.checkDate).getTime()) - (new Date(b.checkDate).getTime())
        } catch (error) {
          return -1
        }
      },
      render: (text: string) => moment(text).format("MM/DD/YYYY"),
      align: "left",
      width: "15%"
    },
    {
      title: "Bank",
      dataIndex: "accountID",
      sorter: (a: any, b: any) => {

        try {
          const { accountName: a_accountName, accountNumber: a_accountNumber } = accounts.find(
            (item: Account) => item.accountID === a.accountID
          )!;
          const nameA = `${a_accountName} (${a_accountNumber})`;

          const { accountName: b_accountName, accountNumber: b_accountNumber } = accounts.find(
            (item: Account) => item.accountID === b.accountID
          )!;
          const nameB = `${b_accountName} (${b_accountNumber})`;

          return nameA > nameB ? 1 : -1;
        } catch (error) {
          return -1
        }
      },
      render: (id: number) => {
        const { accountName, accountNumber } = accounts.filter((item: any) => item.accountID === id)[0];
        return `${accountName} (${accountNumber})`;
      },
      align: "left",
      width: "25%"
    },
    {
      title: "Check No.",
      dataIndex: "checkNo",
      align: "center",
      width: "10%",
      sorter: (a: any, b: any) => {
        try {
          return Number(a.checkNo) - Number(b.checkNo)
        } catch (error) {
          return -1
        }
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      align: "left",
      width: "20%",
      editable: true,
      type: 'text',
      sorter: (a: any, b: any) => {
        try {
          return a.vendor > b.vendor ? 1 : -1
        } catch (error) {
          return -1
        }
      },
      render: (text: string | null) => (text ? text : "unknown")
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a: any, b: any) => {
        try {
          return a.amount - b.amount
        } catch (error) {
          return -1
        }
      },
      render: (text: number) => "$" + convertWithCommas(text),
      align: "right",
      width: "15%"
    },
    {
      title: "Cleared Date",
      editable: true,
      type: 'date',
      dataIndex: "clearedDate",
      sorter: (a: any, b: any) => {
        try {
          return (new Date(a.clearedDate).getTime()) - (new Date(b.clearedDate).getTime())
        } catch (error) {
          return -1
        }
      },
      render: (text: string) => (text ? moment(text).format("MM/DD/YYYY") : "-"),
      align: "right",
      width: "15%"
    }
  ];

  /*============================ END OF SECTION ============================*/
  return {
    defaultColumns
  };
};
