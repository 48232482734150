import { CreateCreditFacility, CreditFacility } from "../types/admin-management";
import { callApiInstance } from "./api";

class CreditFacilitiesService {
  async getAllFacilities(showInactive: boolean): Promise<CreditFacility[]> {
    return await callApiInstance({ url: `/CreditFacility?showInactive=${showInactive}` });
  }
  async createFacility(data: CreateCreditFacility) {
    return await callApiInstance({ url: "/CreditFacility", method: "POST", data });
  }
  async getSingleFacility(id: number) {
    return await callApiInstance({ url: `/CreditFacility/${id}` });
  }
  async editFacility(id: number, data: CreateCreditFacility) {
    return await callApiInstance({ url: `/CreditFacility/${id}`, method: "PUT", data });
  }
  async deleteFacility(id: number) {
    return await callApiInstance({ url: `/CreditFacility/${id}`, method: "DELETE" });
  }
}
export const creditFacilitiesService = new CreditFacilitiesService();
