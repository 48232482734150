import { CreateProject, EditProject, Project } from "../types/admin-management";
import { callApiInstance } from "./api";

class ProjectsService {
  async getAllProjects(showInactive: boolean): Promise<Project[]> {
    return await callApiInstance({ url: `/Project?showInactive=${showInactive}` });
  }
  async createProject(data: CreateProject) {
    return await callApiInstance({
      url: "/Project",
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  async getSingleProject(id: number) {
    return await callApiInstance({ url: `/Project/${id}` });
  }
  async editProject(data: EditProject) {
    return await callApiInstance({
      url: `/Project/${data.id}`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  async deleteProject(id: number) {
    return await callApiInstance({ url: `/Project/${id}`, method: "DELETE" });
  }
}
export const projectsService = new ProjectsService();
