import { FC, useEffect, useState } from "react";
import { routes } from "../routes";
import styles from "./layout.module.css";
import { Link, useNavigate, Route, Routes, useLocation } from "react-router-dom";
import { Avatar, Button, Drawer, Empty, Layout, Menu, message, PageHeader } from "antd";
import { UserOutlined, BarsOutlined } from "@ant-design/icons";
import { useWindowSize } from "../helpers/useWindowSize";
import { useMsal } from "@azure/msal-react";
import { DataContextProvider } from "../contexts/DataContext";
import { authService } from "../services/auth";
import { useAuth } from "../contexts/AuthContext";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Header, Content } = Layout;

export interface ModalOptions {
  visible: boolean;
  payload: any;
}

export const CustomLayout: FC = () => {
  //Hooks
  const { user } = useAuth();
  const isTreasury = user.accountGroups.filter((item: string) => item === "Treasury")?.length;
  const isAccountant = user.accountGroups.filter((item: string) => item === "Accounting")?.length;
  // const userRoles = user.accountGroups
  // should be modified to new group
  const isNewGroup = user.accountGroups.filter((item: string) => item === "New Group").length;
  const [menuVisible, setMenuVisible] = useState(false);
  // const [modalOptions, setModalOptions] = useState<ModalOptions>({ visible: false, payload: "" });
  const { instance, accounts } = useMsal();
  const location = useLocation();
  let navigate = useNavigate();
  const windowSize = useWindowSize();

  const logoutUser = async () => await authService.logoutUser(accounts[0].localAccountId);
  //Helpers

  const currentRouteLabels = () => {
    if (/bank-statement\/.+/gm.test(location.pathname)) return { title: "Bank Statement", subtitle: location.pathname.split("/")[2] };
    else if (/account-details\/.+/gm.test(location.pathname))
      return { title: "Account Details", subtitle: location.pathname.split("/")[2] };
    else return { title: "", subtitle: "" };
  };

  const checkUserRole = (guardRoles?: string[]) => {
    if (!guardRoles || !guardRoles.length) return true;

    for (let i = 0; i < guardRoles.length; i++) {
      const role = user.accountGroups.find((item: string) => item === guardRoles[i]);
      if (!!role) return true;
    }
    return false;
  };

  const findLocationByKey = (key: string) => {
    return routes.find((item) => item.key === key);
  };
  const headerRoutes = routes.filter((item) => user && !item.skipMenuRender && checkUserRole(item.guard));

  // useEffect(() => {
  //   if (user) {
  //     // if (isNewGroup || isAccountant) navigate("/draw-requests");
  //     // else if (!isTreasury && !isAccountant && !isNewGroup) navigate("/401");
  //     if (isTreasury) {
  //       // navigate("/");
  //       console.log("redirecting to a new place");
  //     } else {
  //       navigate("/draw-requests");
  //     }
  //   }
  // }, []);

  return (
    <DataContextProvider>
      <Layout className={styles.mainLayout}>
        <Header>
          <div className={styles.headerLogActions}>
            {windowSize.width > 1000 ? (
              <Button
                type="primary"
                onClick={async () => {
                  message.loading({ content: "Logging out...", key: "log", duration: 5 });
                  await logoutUser();
                  await instance.logoutRedirect();
                }}
              >
                Log Out
              </Button>
            ) : (
              <BarsOutlined className={styles.hamburgerIcon} onClick={() => setMenuVisible(!menuVisible)} />
            )}
          </div>
          {windowSize.width > 1000 ? (
            <div className={styles.profileDiv}>
              <Avatar icon={<UserOutlined />} />
              <span className="user-name">{accounts && accounts.length ? accounts[0].name : "No name"}</span>
            </div>
          ) : null}
          <Link className={styles.menuLogo} to="/">
            <img src={process.env.PUBLIC_URL + "/logo-menu.png"} alt="MFA-Logo" />
          </Link>
          <Menu
            theme="dark"
            mode="horizontal"
            items={headerRoutes}
            selectedKeys={[location.pathname]}
            onSelect={(item) => navigate(item.key)}
            style={{ fontSize: "0.8rem" }}
          />
        </Header>
        <Content className={styles.contentWrapper}>
          <div className={styles.contentWrapperInner}>
            {checkUserRole(findLocationByKey(location.pathname)?.guard) && (
              <PageHeader
                backIcon={isTreasury || isAccountant ? <ArrowLeftOutlined /> : false}
                onBack={() => navigate(-1)}
                title={findLocationByKey(location.pathname) ? findLocationByKey(location.pathname)?.label : currentRouteLabels().title}
              />
            )}
            <Routes>
              {routes
                .filter(
                  (item) => checkUserRole(item.guard)
                  // (isTreasury ? true : isAccountant ? item.label === "Draw Requests" : item.label === "Unauthorized")
                  // isTreasury || isAccountant ? true : isNewGroup ? item.label === "Draw Requests" : item.label === "Unauthorized"
                )
                .map(({ key, element }) => (
                  <Route key={key} path={key} element={element} />
                ))}
              <Route path="*" element={<Empty className={styles.notFoundPage} description="404 Page Not Found" />} />
            </Routes>
          </div>
          <Drawer placement="right" closable={false} visible={menuVisible} width={200} className={styles.sideMenu}>
            <Menu
              theme="dark"
              mode="vertical"
              items={headerRoutes}
              selectedKeys={[location.pathname]}
              onSelect={(item) => {
                navigate(item.key);
                setMenuVisible(false);
              }}
              style={{ fontSize: "1rem" }}
            />
          </Drawer>
        </Content>
      </Layout>
      {/* <InactivityModal options={modalOptions} setOptions={setModalOptions} resetTimeout={resetTimeout} /> */}
    </DataContextProvider>
  );
};
