import { FC, useEffect, useState } from "react";
import { Form, FormInstance, Input, Button, Select } from "antd";
import { CreateGlCode } from "../../../../types/gl-code";


interface IGlCodeForm {
  form: FormInstance<CreateGlCode>;
  onFinish?: ((values: CreateGlCode) => void) | undefined;
  currentValues: string;
}

const { Item: FormItem } = Form;

export const GlCodeForm: FC<IGlCodeForm> = ({ form, onFinish, currentValues }) => {
  const [balances] = useState([
    { label: "Credit", value: "Credit" },
    { label: "Debit", value: "Debit" }
  ]);
  
  const [sameValue, setSameValue] = useState(true);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setSameValue(true);
    setWasSubmitted(false);
    setIsDisabled(false);
  }, [currentValues]);



  return (
    <Form
      name="glCode-form"
      labelCol={{ span: 8 }}
      labelAlign="left"
      form={form}
      initialValues={{ account_name: "", gl_code: "", normal_balance: "" } as CreateGlCode}
      onFinish={(values) => {
        if (onFinish) {
          // setIsDisabled(true);
          onFinish(values);
        }
      }}
      onValuesChange={() => {
        setSameValue(currentValues === JSON.stringify(form.getFieldsValue()));
      }}
    >
      <FormItem label="Account Name" name="account_name" rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem label="GL Code" name="gl_code" rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem label="Normal Balance" name="normal_balance" rules={[{ required: true }]}>
        <Select options={balances} />
      </FormItem>

      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Button
          disabled={isDisabled}
          type="primary"
          style={{ width: "200px" }}
          onClick={() => {
            setWasSubmitted(true);
            if (!sameValue) form.submit();
          }}
        >
          Submit
        </Button>
        {(sameValue && wasSubmitted && <div style={{ height: "22px", color: "#ff4d4f" }}>values did not change</div>) || (
          <div style={{ height: "22px" }}></div>
        )}
      </div>
    </Form>
  );
};
