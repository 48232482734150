import { FC, useContext, useState } from "react";
import { Col, Row, Descriptions, Divider, Radio, RadioChangeEvent, Button, Skeleton, Segmented, message } from "antd";
import moment from "moment";
import styles from "./BankStatementDetails.module.css";
import { DetailsTable } from "../../components";
import { CheckListTable } from "./components";
import { FilePdfOutlined, FileExcelOutlined, DownloadOutlined } from "@ant-design/icons";
import { DataContext } from "../../contexts/DataContext";
import { useParams } from "react-router-dom";
import { SegmentedValue } from "rc-segmented";
import { Account } from "../../types/account";
import { useQuery } from "@tanstack/react-query";
import { accountsService } from "../../services/accounts";
import { TableSortersAndFilters } from "../../components";
import { generateDownload } from "../../helpers/generateDownload";

const { Item } = Descriptions;
const { Group, Button: RadioButton } = Radio;

interface BankStatementResponse {
  details: any[];
  checks: any[];
  totals: { closingBalance: number; totalChecks: number; openingBalance: number };
}

export const BankStatementDetails: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentSortersAndFilters, setCurrentSortersAndFilters] = useState<TableSortersAndFilters>({
    filters: null,
    field: null,
    direction: null
  });
  const [selectedFormat, setSelectedFormat] = useState<"pdf" | "excel">("excel");
  const [selectedTab, setSelectedTab] = useState<SegmentedValue>("Cleared");
  const startDate = moment().startOf("month").format("MM/DD/YYYY");
  const { id } = useParams();
  const { loading, accounts } = useContext(DataContext);
  const currentAccount = !loading && accounts.length && id ? accounts.filter((item: Account) => item.accountID === parseInt(id))[0] : null;

  ///////// Queries

  const { data: statementData, isLoading } = useQuery(
    ["account-statement", id, moment().startOf("month").format("MM/DD/YYYY"), currentSortersAndFilters.filters],
    async () => {
      const response: BankStatementResponse = await accountsService.getBankStatement({
        id: id ? id : "",
        startDate,
        filterValue: currentSortersAndFilters.filters ? currentSortersAndFilters.filters.join(",") : ""
      });
      setCurrentPageSize(response.details.length === 0 ? 50 : response.details.length);
      return response;
    }
  );

  ////////

  ///////// Handlers

  const handleDownload = async () => {
    try {
      message.loading({ content: "Downloading file...", key: "downloading", duration: 0 });
      const file = await accountsService.getBankStatement(
        { id: id ? id : "", startDate, filterValue: currentSortersAndFilters.filters ? currentSortersAndFilters.filters.join(",") : "" },
        selectedFormat
      );
      const fileName = `(${currentAccount?.accountNumber})${currentAccount?.accountName}-Bank Statement.${
        selectedFormat === "pdf" ? "pdf" : "xlsx"
      }`;
      generateDownload(file, fileName, selectedFormat);
      message.success({ content: "Success", key: "downloading" });
    } catch (error) {
      message.error({ content: error, key: "downloading" });
    }
  };

  const handleFormatChange = (e: RadioChangeEvent) => setSelectedFormat(e.target.value);

  /////////

  return (
    <Row gutter={[32, { xs: 16, sm: 32 }]} justify="space-between">
      <Col xs={24} sm={12} md={10}>
        <Descriptions column={1} size="small" bordered>
          <Item label="Account Name">
            <b>
              <b>{currentAccount ? `${currentAccount.accountName} (${currentAccount.accountNumber})` : <Skeleton.Input active />}</b>
            </b>
          </Item>
          <Item label="Date">{moment().format("MM/DD/YYYY")}</Item>
        </Descriptions>
      </Col>
      <Col xs={24} sm={11} md={8} className={styles.downloadButtonsWrapper}>
        <Group value={selectedFormat} size="large" buttonStyle="solid" onChange={handleFormatChange}>
          <RadioButton value="pdf">
            PDF <FilePdfOutlined />
          </RadioButton>
          <RadioButton value="excel">
            <FileExcelOutlined /> Excel
          </RadioButton>
        </Group>
        <Button icon={<DownloadOutlined />} size="large" onClick={() => handleDownload()}>
          Download
        </Button>
      </Col>
      <Col span={24}>
        <Descriptions column={{ xs: 2, sm: 3, md: 6 }} size="small" bordered layout="vertical">
          <Item label="Balance">
            <div className={styles.innerItemWrapper}>
              <i>No Data</i>
            </div>
          </Item>
          <Item label="Opening Balance">
            <div className={styles.innerItemWrapper}>
              {!isLoading && statementData && statementData.totals.openingBalance !== null ? (
                "$" + statementData.totals.openingBalance.toLocaleString("en-US")
              ) : (
                <i>No Data</i>
              )}
            </div>
          </Item>
          <Item label="Closing Balance">
            <div className={styles.innerItemWrapper}>
              {!isLoading && statementData && statementData.totals.closingBalance !== null ? (
                "$" + statementData.totals.closingBalance.toLocaleString("en-US")
              ) : (
                <i>No Data</i>
              )}
            </div>
          </Item>
          <Item label="Issued Check Balance">
            <div className={styles.innerItemWrapper}>
              {!isLoading && statementData && statementData.totals.totalChecks !== null ? (
                "$" + statementData.totals.totalChecks.toLocaleString("en-US")
              ) : (
                <i>No Data</i>
              )}
            </div>
          </Item>
          <Item label="Cleared Check Balance">
            <div className={styles.innerItemWrapper}>
              {!isLoading && statementData && statementData.totals.totalChecks !== null ? (
                "$" + statementData.totals.totalChecks.toLocaleString("en-US")
              ) : (
                <i>No Data</i>
              )}
            </div>
          </Item>
          <Item label="Stop Payment">
            <div className={styles.innerItemWrapper}>
              <i>No Data</i>
            </div>
          </Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <Divider orientation="left">Last Month Bank Statement</Divider>
        <DetailsTable
          data={statementData ? statementData.details : []}
          sorters={["description", "check", "customerReferenceNumber", "debit", "credit"]}
          isLoading={isLoading}
          totalTransactions={statementData ? statementData.details.length : 0}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          currentSortersAndFilters={currentSortersAndFilters}
          setCurrentPageSize={setCurrentPageSize}
          setCurrentPage={setCurrentPage}
          setCurrentSortersAndFilters={setCurrentSortersAndFilters}
        />
      </Col>
      <Divider orientation="left">Check Lists</Divider>
      <Col>
        <Segmented options={["Cleared", "Uncleared", "Stop Payment"]} onChange={(value) => setSelectedTab(value)} />
      </Col>

      <Col span={24}>
        <CheckListTable
          data={selectedTab === "Uncleared" ? [] : selectedTab === "Cleared" && statementData ? statementData.checks : []}
          loading={isLoading}
        />
      </Col>
    </Row>
  );
};
