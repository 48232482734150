import { FC, useContext, useEffect, useState } from "react";
import { Form, Input, InputNumber, DatePicker, Button, Select, FormInstance } from "antd";
import { useMsal } from "@azure/msal-react";
import { CreateDrawRequest } from "../../../../types/draw-request";
import moment from "moment";
import { DataContext } from "../../../../contexts/DataContext";
import Holidays from "date-holidays";
import { federalBankHolyDays } from "../../data/holydays";

interface IDrawRequestForm {
  form: FormInstance<CreateDrawRequest>;
  onFinish?: ((values: CreateDrawRequest) => void) | undefined;
}

const { Item: FormItem } = Form;

export const DrawRequestForm: FC<IDrawRequestForm> = ({ form, onFinish }) => {
  const { accounts } = useMsal();
  const { projects, areProjectsLoading, codes, loading } = useContext(DataContext);
  const accountName = accounts && accounts.length ? accounts[0].name : "";
  const [dueDate, setDueDate] = useState<moment.Moment>(moment());
  const [tenDueDate, setTenDueDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    let dueDateAux = moment();
    const hd = new Holidays("US");
    const holidays = hd
      .getHolidays(dueDateAux.year())
      .filter((holy) => federalBankHolyDays.includes(holy.name))
      .map((holy: any) => {
        return holy.date.split(" ")[0];
      });
    const holidaysNextYear = hd
      .getHolidays(dueDateAux.year() + 1)
      .filter((holy) => federalBankHolyDays.includes(holy.name))
      .map((holy: any) => {
        return holy.date.split(" ")[0];
      });
    let totalDays = 0;

    console.log("-=-=-=-=--=- while started -=-=-=-=-=-");

    while (totalDays !== 10) {
      const formattedDate = dueDateAux.format("YYYY-MM-DD");
      // console.log("loop ", totalDays, dueDateAux);

      if (
        dueDateAux.day() !== 0 &&
        dueDateAux.day() !== 6 &&
        !holidays.includes(formattedDate) &&
        !holidaysNextYear.includes(formattedDate)
      ) {
        totalDays++;
      } else {
        console.log(`No valid date ${dueDateAux.format("YYYY-MM-DD")}`);
      }

      dueDateAux.add(1, "day");
    }
    let formattedDate = dueDateAux.format("YYYY-MM-DD");
    while (
      dueDateAux.day() === 0 ||
      dueDateAux.day() === 6 ||
      holidays.includes(formattedDate) ||
      holidaysNextYear.includes(formattedDate)
    ) {
      dueDateAux.add(1, "day");
      formattedDate = dueDateAux.format("YYYY-MM-DD");
    }

    console.log("-=-=-=-=--=- while ended -=-=-=-=-=-");
    setDueDate(dueDateAux);
    setTenDueDate(dueDateAux);
    form.setFieldsValue({ dueDate: dueDateAux });
  }, []);

  return (
    <Form
      name="draw-request-form"
      labelCol={{ span: 12 }}
      labelAlign="left"
      form={form}
      initialValues={{ initiator: accountName, dueDate, amount: 0, drawNumber: "", projectName: "" }}
      onFinish={onFinish}
    >
      <FormItem label="Initiator" name="initiator" rules={[{ required: true }]}>
        <Input disabled />
      </FormItem>
      <FormItem label="Project" name="projectID" rules={[{ required: true }]}>
        <Select
          showSearch={true}
          options={projects.filter((item) => item.active).map((item) => ({ label: item.name, value: item.id }))}
          loading={areProjectsLoading}
          onChange={(value) => form.setFieldsValue({ accountID: projects.filter((item) => item.id === value)[0].accountID })}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        />
      </FormItem>
      <FormItem name="accountID" noStyle />
      <FormItem label="Draw No." name="drawNumber">
        <Input />
      </FormItem>
      <FormItem label="Amount" name="amount" rules={[{ required: true }]}>
        <InputNumber addonBefore="$" style={{ width: "100%" }} inputMode="decimal" />
      </FormItem>
      <FormItem label="Due Date" name="dueDate" rules={[{ required: true }]}>
        <DatePicker format="MM/DD/YYYY" style={{ width: "100%" }} disabledDate={(currentDate) => currentDate.isBefore(tenDueDate)} />
      </FormItem>
      <FormItem label="Gl Code" name="MainCmsGlCodeID" rules={[{ required: true }]}>
        <Select
          showSearch={true}
          options={[...codes.map((item) => ({ label: `${item.gl_code} (${item.account_name})`, value: item.id }))]}
          loading={loading}
          onChange={(value) => form.setFieldsValue({ MainCmsGlCodeID: value })}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        />
      </FormItem>
      <FormItem label="JV Deposit GL Code" name="JvDepositGlCodeID" rules={[{ required: true }]}>
        <Select
          showSearch={true}
          options={codes.map((item) => ({ label: `${item.gl_code} (${item.account_name})`, value: item.id }))}
          loading={loading}
          onChange={(value) => form.setFieldsValue({ JvDepositGlCodeID: value })}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        />
      </FormItem>
      <FormItem label="JV Withdrawal GL Code" name="JvWithdrawalGlCodeID" rules={[{ required: true }]}>
        <Select
          showSearch={true}
          options={codes.map((item) => ({ label: `${item.gl_code} (${item.account_name})`, value: item.id }))}
          loading={loading}
          onChange={(value) => form.setFieldsValue({ JvWithdrawalGlCodeID: value })}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          // filterSort={(optionA, optionB) =>
          //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        />
      </FormItem>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button htmlType="submit" type="primary" style={{ width: "200px" }}>
          Submit
        </Button>
      </div>
    </Form>
  );
};
