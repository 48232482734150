// // import { useMutation } from "@tanstack/react-query";
import { Modal, Button, Table } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "../../../CashBalance/CashBalance.module.css";
import { ColumnsType } from "antd/lib/table";
import { convertWithCommas } from "../../../../helpers";
import moment from "moment";
import { ShortTermLoanRecord } from "../../../../types/short-term-loan";

interface IMultiLoan {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleCellClick: (
    record: { name: string; date: string; id: string | number; recordId: number | string; amount: number } | undefined
  ) => void;

  recordToEdit: { record: any; data: { name: string; date: string; id: number[] | string[] } } | null;
  setRecordToEdit: React.Dispatch<
    React.SetStateAction<{
      record: any;
      data: {
        name: string;
        date: string;
        id: number[] | string[];
      };
    } | null>
  >;
  shortTermLoans: ShortTermLoanRecord[];
}

interface RecordItem {
  name: string;
  date: string;
  id: number | string;
  amount: number;
  endDate: string;
  interestRate: string;
}

export const MultiLoan: FC<IMultiLoan> = ({ visible, setVisible, handleCellClick, recordToEdit, setRecordToEdit, shortTermLoans }) => {
  const [data, setData] = useState<RecordItem[]>([]);

  useEffect(() => {
    try {
      const el = [];
      if (recordToEdit?.data.id.length) {
        for (let i = 0; i < recordToEdit?.data.id.length; i++) {
          const oLoan = shortTermLoans.find((lo: ShortTermLoanRecord) => lo.id === recordToEdit.data.id[i]);

          if (oLoan)
            el.push({
              id: oLoan.id,
              name: oLoan.creditFacilityName,
              date: oLoan.startDate,
              endDate: oLoan.endDate,
              amount: oLoan.amount,
              interestRate: oLoan.interestRate
            });
        }
        setData([...el]);
      }

      console.log("HERE THE RECORD TO EDIT", { data });
    } catch (error) {
      console.error(error);
    }
  }, [recordToEdit]);

  const handleEdit = (item: RecordItem) => {
    setRecordToEdit(null);
    setVisible(false);
    handleCellClick({
      name: item.name,
      amount: item.amount,
      date: item.date,
      id: item.id,
      recordId: item.id
    });
  };

  const columns: ColumnsType<any> = [
    {
      key: "id",
      dataIndex: "id",
      width: 80,
      title: () => "Id",
      render: (value, item: RecordItem) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
          {value}
        </div>
      )
    },
    {
      key: "bank",
      dataIndex: "name",
      title: () => "Bank",
      render: (value, item) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
          {value}
        </div>
      )
    },
    {
      key: "date",
      dataIndex: "date",
      title: () => "Start Date",
      render: (value, item) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
          {moment(value).format("MM/DD/YYYY")}
        </div>
      )
    },
    {
      key: "endDate",
      dataIndex: "endDate",
      title: () => "End Date",
      render: (value, item) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
          {moment(value).format("MM/DD/YYYY")}
        </div>
      )
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: () => "Amount",
      render: (value, item) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
          {"$" + convertWithCommas(value)}
        </div>
      )
    },
    {
      key: "interestRate",
      dataIndex: "interestRate",
      title: () => "Interest Rate",
      render: (value, item) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleEdit(item)}>
          {value}
        </div>
      )
    }
  ];

  return (
    <>
      <Modal
        visible={visible}
        width={"50vw"}
        title={"Pick a loan to edit"}
        centered
        onOk={() => setVisible(false)}
        footer={
          <Button
            key="cancel"
            onClick={() => {
              setRecordToEdit(null);
              setVisible(false);
            }}
          >
            Close
          </Button>
        }
      >
        <Table
          style={{ maxHeight: "233px", overflow: "auto" }}
          columns={columns}
          size={"small"}
          dataSource={data}
          bordered
          rowClassName={styles.stripedRow}
          sticky
          pagination={false}
          key="id"
          rowKey="id"
        />
      </Modal>
    </>
  );
};
