import { FC, useEffect, useState } from "react";
import { Form, FormInstance, InputNumber, DatePicker, Input, Button } from "antd";
import { CreateCreditFacility } from "../../../../types/admin-management";
import moment from "moment";

interface ICreditFacilityForm {
  form: FormInstance<CreateCreditFacility>;
  onFinish?: ((values: CreateCreditFacility) => void) | undefined;
  currentValues: string;
}

const { Item: FormItem } = Form;

export const CreditFacilityForm: FC<ICreditFacilityForm> = ({ form, onFinish, currentValues }) => {
  const [sameValue, setSameValue] = useState(true);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setSameValue(true);
    setWasSubmitted(false);
    setIsDisabled(false);
  }, [currentValues]);

  return (
    <Form
      name="facility-form"
      labelCol={{ span: 6 }}
      labelAlign="left"
      form={form}
      initialValues={{ name: "", expiryDate: moment(), amount: 0, rate: "" } as CreateCreditFacility}
      onFinish={(values) => {
        if (onFinish) {
          setIsDisabled(true);
          onFinish(values);
        }
      }}
      onValuesChange={() => {
        setSameValue(currentValues === JSON.stringify(form.getFieldsValue()));
      }}
    >
      <FormItem label="Name" name="name">
        <Input />
      </FormItem>
      <FormItem label="Rate" name="rate">
        <Input />
      </FormItem>
      <FormItem label="Amount" name="amount">
        <InputNumber addonBefore="$" style={{ width: "50%" }} inputMode="decimal" />
      </FormItem>
      <FormItem label="Expiry Date" name="expiryDate">
        <DatePicker format="MM/DD/YYYY" style={{ width: "50%" }} />
      </FormItem>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Button
          disabled={isDisabled}
          type="primary"
          style={{ width: "200px" }}
          onClick={() => {
            setWasSubmitted(true);
            if (!sameValue) form.submit();
          }}
        >
          Submit
        </Button>
        {(sameValue && wasSubmitted && <div style={{ height: "22px", color: "#ff4d4f" }}>values did not change</div>) || (
          <div style={{ height: "22px" }}></div>
        )}
      </div>
    </Form>
  );
};
