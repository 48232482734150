import { Radio, Row, Col, RadioChangeEvent, Button, Space, Input, DatePicker } from "antd";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { FilePdfOutlined, FileExcelOutlined, DownloadOutlined } from "@ant-design/icons";
import { DownloadFormat } from "../../types/account";
import { useDebounce } from "../../helpers/useDebounce";
import moment, { Moment } from "moment";

const { Group, Button: RadioButton } = Radio;
const { Search } = Input;

export interface RadioOptions {
  label: string;
  value: string;
}

export interface IManipulateReportsFilters {
  displayMode?: "monthly" | "yearly" | "yearly-5" | "summary";
  selection?: "distribution" | "capital_call";
  searchValue?: string;
  rangeOptionValue?: string;
  range: Moment | Moment[] | undefined;
  downloadType: DownloadFormat;
}

export type IManipulateReportsOptions = {
  displayOptions?: RadioOptions[];
  downloadOptions?: DownloadFormat[];
  searchOption: boolean;
  selectionOptions?: RadioOptions[];
  rangeOptions?: RadioOptions[];
};

interface IManipulateReports {
  onDownloadClick?: (value: DownloadFormat) => void;
  onFiltersChange: (filters: IManipulateReportsFilters) => void;
  options: IManipulateReportsOptions;
  filters: IManipulateReportsFilters;
}

export const ManipulateReports: FC<IManipulateReports> = ({ options, onDownloadClick, onFiltersChange, filters }) => {
  const { searchValue: search, displayMode, selection, range, downloadType, rangeOptionValue } = filters;
  const { displayOptions, downloadOptions, searchOption, selectionOptions, rangeOptions } = options;
  const [searchValue, setSearchValue] = useState<string>(searchOption ? search! : "");
  const debouncedValue = useDebounce(searchValue, 500);

  const exportRange = (range: string) => {
    switch (range) {
      case "30-days":
        return [moment(), moment().add(30, "day")] as [Moment, Moment];
      case "this-month":
        return [moment().startOf("month"), moment().endOf("month")] as [Moment, Moment];
      case "last-month":
        return [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")] as [Moment, Moment];
      case "next-month":
        return [moment().add(1, "month").startOf("month"), moment().add(1, "month").endOf("month")] as [Moment, Moment];
      case "range":
        return [moment(), moment()] as [Moment, Moment];
      case "yesterday":
        return moment().subtract(1, "day");
      default:
        return moment();
    }
  };

  const renderRadioButtons = (options: RadioOptions[]) =>
    options.map(({ value, label }) => <RadioButton value={value}>{label}</RadioButton>);

  useEffect(() => {
    onFiltersChange({ ...filters, searchValue });
  }, [debouncedValue]);

  return (
    <Row gutter={[24, 16]}>
      {displayOptions && displayOptions.length > 1 ? (
        <Col span={24}>
          <Space>
            <Group
              onChange={(e: RadioChangeEvent) => onFiltersChange({ ...filters, displayMode: e.target.value })}
              value={displayMode}
              optionType="button"
              buttonStyle="solid"
              size="large"
            >
              {renderRadioButtons(displayOptions)}
            </Group>
          </Space>
        </Col>
      ) : null}
      {displayMode === "monthly" ? (
        <Col span={24}>
          <Space>
            <Group
              options={rangeOptions}
              value={rangeOptionValue}
              size="large"
              buttonStyle="solid"
              optionType="button"
              onChange={(e: RadioChangeEvent) =>
                onFiltersChange({ ...filters, rangeOptionValue: e.target.value, range: exportRange(e.target.value) })
              }
            />
            {rangeOptionValue === "range" ? (
              <DatePicker.RangePicker
                size="large"
                value={range as [Moment, Moment]}
                onChange={(values) => {
                  let newValues = values;
                  if (!values) newValues = [moment(), moment()];
                  onFiltersChange({ ...filters, range: newValues as [Moment, Moment] });
                }}
              />
            ) : null}
          </Space>
        </Col>
      ) : null}
      {selectionOptions && (displayMode === "yearly" || displayMode === "yearly-5") ? (
        <Col span={24}>
          <Group
            options={selectionOptions}
            value={selection}
            size="large"
            buttonStyle="solid"
            optionType="button"
            onChange={(e: RadioChangeEvent) => onFiltersChange({ ...filters, selection: e.target.value })}
          />
        </Col>
      ) : null}
      {downloadOptions && onDownloadClick ? (
        <Col span={24}>
          <Space>
            <Group
              value={downloadType}
              size="large"
              buttonStyle="solid"
              onChange={(e: RadioChangeEvent) => onFiltersChange({ ...filters, downloadType: e.target.value })}
            >
              {downloadOptions.includes("pdf") ? (
                <RadioButton value="pdf">
                  PDF <FilePdfOutlined />
                </RadioButton>
              ) : null}
              <RadioButton value="excel">
                <FileExcelOutlined /> Excel
              </RadioButton>
            </Group>
            <Button
              disabled={filters.displayMode === "monthly" && !Array.isArray(filters.range)}
              icon={<DownloadOutlined />}
              size="large"
              onClick={() => onDownloadClick(downloadType)}
            >
              Download
            </Button>
          </Space>
        </Col>
      ) : null}
      {searchOption ? (
        <Col span={24}>
          <Search
            style={{ width: "60%" }}
            size="large"
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          />
        </Col>
      ) : null}
    </Row>
  );
};
