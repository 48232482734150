import { DatePicker } from "antd";
import moment from "moment";
import { FC, useContext } from "react";
import { DataContext } from "../../contexts/DataContext";

interface IMainDatePicker {
  type: "year" | "month";
}

export const MainDatePicker: FC<IMainDatePicker> = ({ type }) => {
  const { startDate, setStartDate } = useContext(DataContext);
  
  return (
    <DatePicker
      size="large"
      picker={type}
      value={startDate}
      onChange={(value: moment.Moment | null) => setStartDate(value!)}
      allowClear={false}
      format={type === "year" ? "YYYY" : "MMMM-YY"}
      style={{ width: "100%" }}
    />
  );
};
