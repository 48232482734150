export const generateDownload = (file: any, fileName: string, type: "excel" | "pdf"): void => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: type === "excel" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
