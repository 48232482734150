import { Row, Col, Table, message, Spin } from "antd";
import { FC, useCallback, useMemo, useState } from "react";
import { IManipulateReportsFilters, IManipulateReportsOptions, ManipulateReports } from "../../components";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { AlignType } from "rc-table/lib/interface";
import { LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, Line, CartesianGrid } from "recharts";
import { useQuery } from "@tanstack/react-query";
import { distributionCapitalCallService } from "../../services/capital-call";
import {
  DistributionCapitalCallDisplayMode,
  DistributionCapitalCallSummary,
  DistributionCapitalCallYearlyRecord
} from "../../types/capital-call";
import { months } from "../../types";
import { generateDownload } from "../../helpers/generateDownload";
import { getCurrentDate, getCurrentDateFormatted } from "../../helpers";

interface Props {}

const { Summary } = Table;

const options: IManipulateReportsOptions = {
  displayOptions: [
    { value: "monthly", label: "Monthly" },
    { value: "yearly-5", label: "Yearly (5 Years)" },
    { value: "yearly", label: "All Years" },
    { value: "summary", label: "Summary" }
  ],
  rangeOptions: [
    { value: "this-month", label: "This Month" },
    { value: "last-month", label: "Last Month" },
    { value: "range", label: "Range" }
  ],
  searchOption: false,
  selectionOptions: [
    { value: "capital_call", label: "Capital Call" },
    { value: "distribution", label: "Distributions" }
  ],
  downloadOptions: ["excel"]
};

const CustomLabel = () => (
  <g>
    <foreignObject x={5} y={"75%"} width={100} height={25}>
      <b>$(milion)</b>
    </foreignObject>
  </g>
);

export const CapitalCall: FC<Props> = () => {
  const [filters, setFilters] = useState<IManipulateReportsFilters>({
    displayMode: "monthly",
    downloadType: "excel",
    selection: "distribution",
    range: [moment().date(1), moment()],
    rangeOptionValue: "this-month"
  });
  const { displayMode, range, selection } = filters;
  const [yearlyTotals, setYearlyTotals] = useState({});
  const { isMoment } = moment;

  const getDates = (start: boolean) => {
    switch (displayMode) {
      case "yearly":
        return null;

      case "summary":
        return null;

      case "yearly-5":
        const currentDate = moment();
        return start ? currentDate.format("MM/DD/YYYY") : currentDate.subtract(5, "year").format("MM/DD/YYYY");
      //
      default:
        return start ? (range as any)[1].format("MM/DD/YYYY") : (range as any)[0].format("MM/DD/YYYY");
    }
  };

  const {
    data: records,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    [
      "distributions-capital-call",
      displayMode && displayMode === "yearly-5" ? "yearly" : displayMode,
      getDates(false),
      getDates(true),
      selection
    ],
    (context) =>
      distributionCapitalCallService.getDistributionsAndCapitalCall(
        context.queryKey[1] as DistributionCapitalCallDisplayMode,
        context.queryKey[2] as string,
        context.queryKey[3] as string,
        selection
      ),
    {
      initialData: []
    }
  );

  const handleDownload = async () => {
    // const auxDisplayMode = displayMode ? displayMode :"yearly"
    let startDate = undefined;
    let endDate = undefined;

    if (!isMoment(range) && displayMode === "monthly") {
      startDate = (range as moment.Moment[])[0].format("MM/DD/YYYY");
      endDate = (range as moment.Moment[])[1].format("MM/DD/YYYY");
    }

    if (displayMode === "yearly-5") {
      const { day, month, year } = getCurrentDate();
      startDate = `${month}/${day}/${year - 5}`;
      endDate = `${month}/${day}/${year}`;
    }

    try {
      let auxDisplayMode = displayMode || "yearly";
      auxDisplayMode = auxDisplayMode === "yearly-5" ? "yearly" : auxDisplayMode;
      const excelFile = await distributionCapitalCallService.downloadExcel(auxDisplayMode, startDate, endDate);
      const fileName = `Distribution & Capital Call_REPORT_${getCurrentDateFormatted()}`;

      generateDownload(excelFile, fileName, "excel");
      message.info("File downloaded");
    } catch (error) {
      message.error("File was not downloaded, something happened !!!");
    }
  };
  const onFiltersChange = (filters: IManipulateReportsFilters) => {
    setFilters(filters);
  };

  const flattenYearlyObject = (records: DistributionCapitalCallYearlyRecord[]) => {
    let newRecords = records.reduce((previous: any, current, index) => {
      let currentObject: any = { key: index, projectName: current.projectName };
      Object.entries(current)
        .filter((item) => item[0] !== "projectName")
        .forEach((entry) => {
          Object.entries(entry[1]).forEach((nestedEntry) => {
            currentObject[`${entry[0]}${nestedEntry[0]}`] = nestedEntry[1];
          });
        });
      return [...previous, currentObject];
    }, []);
    return newRecords;
  };

  const convertSummaryData = (records: DistributionCapitalCallSummary[]) => {
    return records.reduce(
      (previous: any, current: any) => {
        let month = months[current.month as number];
        let index = `${current.year}${month}`;
        previous[0][index] = current.distribution;
        previous[0].total += current.distribution;
        previous[1][index] = current.captitalCall;
        previous[1].total += current.captitalCall;
        return previous;
      },
      [
        { type: "Distributions", total: 0 },
        { type: "Capital Call", total: 0 }
      ]
    );
  };

  const getColumns = useCallback(() => {
    const projectColumn: ColumnsType<any> = [
      {
        title: displayMode === "monthly" ? "Projects" : "Project USD(M)",
        dataIndex: "projectName",
        key: "projectName",
        fixed: displayMode === "monthly" ? undefined : "left",
        align: displayMode === "monthly" ? "left" : "center",
        width: displayMode === "monthly" ? "20%" : "auto",
        render: (text: string) => <strong style={{ width: 200, whiteSpace: "nowrap" }}>{text}</strong>
      }
    ];
    const summaryColumn: ColumnsType<any> = [
      {
        title: "USD(M)",
        dataIndex: "type",
        key: "type",
        fixed: "left",
        render: (text: string) => <strong>{text}</strong>
      }
    ];
    switch (displayMode) {
      case "monthly":
        return [
          ...projectColumn,
          {
            title: "Account",
            dataIndex: "accountName",
            key: "accountName",
            // fixed: "left" as FixedType,
            align: "left" as AlignType,
            width: displayMode === "monthly" ? "20%" : "auto",
            render: (text: string, record: any) => (
              <strong style={{ width: 200, whiteSpace: "nowrap" }}>{`${text} (${record.accountNumber})`}</strong>
            )
          },
          {
            title: "Distributions",
            dataIndex: "distribution",
            key: "distribution",
            align: "right" as AlignType,
            width: "15%",
            render: (text: number) => "$" + convertWithCommas(text)
          },
          {
            title: "Capital Calls",
            dataIndex: "capitalCall",
            key: "capitalCall",
            align: "right" as AlignType,
            width: "15%",
            render: (text: number) => "$" + convertWithCommas(text)
          },
          {
            title: "Memo",
            dataIndex: "memo",
            key: "memo",
            width: "30%"
          }
        ];
      case "yearly":
      case "yearly-5":
        return selection && records.length
          ? [
              ...projectColumn,
              ...Object.entries(records[0] as DistributionCapitalCallYearlyRecord)
                .filter((item) => item[0] !== "projectName")
                .map((entry) => {
                  return {
                    title: entry[0],
                    align: "center" as AlignType,
                    width: "auto",
                    children: Object.entries(entry[1]).map((item) => {
                      return {
                        dataIndex: entry[0] + item[0],
                        key: entry[0] + item[0],
                        title: item[0].toUpperCase(),
                        align: "center" as AlignType,
                        render: (value: number) => (
                          <span style={{ width: 60, display: "inline-block" }}>{value ? convertWithCommas(value, true) : "0.0"}</span>
                        )
                      };
                    })
                  };
                })
            ]
          : [];
      case "summary":
        return [
          ...summaryColumn,
          ...(records as any).reduce((previous: any, current: any) => {
            let month = months[current.month as number];
            let yearIndex = previous.findIndex((item: any) => item.title === current.year.toString());
            if (yearIndex >= 0)
              previous[yearIndex].children.push({
                dataIndex: current.year + month,
                key: current.year + month,
                title: month.toUpperCase(),
                align: "center" as AlignType,
                width: "auto",
                render: (value: number) => convertWithCommas(value, true)
              });
            else
              previous = [
                ...previous,
                {
                  key: current.year.toString(),
                  title: current.year.toString(),
                  align: "center" as AlignType,
                  children: [
                    {
                      dataIndex: current.year + month,
                      key: current.year + month,
                      title: month.toUpperCase(),
                      align: "center" as AlignType,
                      width: "auto",
                      render: (value: number) => convertWithCommas(value, true)
                    }
                  ]
                }
              ];
            return previous;
          }, [])
        ];
    }
  }, [displayMode, selection, records]);

  const generateChartData = (records: DistributionCapitalCallSummary[]) => {
    return records.map(({ captitalCall, distribution, month, year }) => ({
      name: `${months[month]} ${year}`,
      distribution,
      captitalCall
    }));
  };

  console.log("-==-=-=CAPITAL CALL RECORDS-=-=-", records);

  return (
    <div>
      <Row justify="space-between" gutter={[60, 40]}>
        <Col span={14}>
          <ManipulateReports options={options} filters={filters} onFiltersChange={onFiltersChange} onDownloadClick={handleDownload} />
        </Col>
        <Col span={24}>
          {(displayMode === "yearly" || displayMode === "yearly-5") && !selection ? (
            "Please select Distribution or Capital Call"
          ) : (
            <Table
              key="id"
              rowKey="id"
              tableLayout="auto"
              columns={getColumns()}
              dataSource={
                displayMode === "yearly" || displayMode === "yearly-5"
                  ? flattenYearlyObject(records as DistributionCapitalCallYearlyRecord[])
                  : displayMode === "monthly"
                  ? records
                  : displayMode === "summary"
                  ? convertSummaryData(records as DistributionCapitalCallSummary[])
                  : []
              }
              scroll={{ x: 1300 }}
              loading={isLoading || isFetching}
              sticky
              pagination={false}
              bordered
              summary={(data) => {
                if (displayMode === "monthly") {
                  let totals = data.reduce(
                    (previous, current) => {
                      previous.capitalCall += current.capitalCall;
                      previous.distribution += current.distribution;
                      return previous;
                    },
                    { capitalCall: 0, distribution: 0 }
                  );
                  return (
                    <Summary>
                      <Summary.Row style={{ fontWeight: 700, background: "#fafafa" }}>
                        <Summary.Cell index={0}></Summary.Cell>
                        <Summary.Cell index={1}></Summary.Cell>
                        <Summary.Cell index={2} align="right">
                          {"$" + convertWithCommas(totals.distribution)}
                        </Summary.Cell>
                        <Summary.Cell index={3} align="right">
                          {"$" + convertWithCommas(totals.capitalCall)}
                        </Summary.Cell>
                        <Summary.Cell index={4}></Summary.Cell>
                      </Summary.Row>
                    </Summary>
                  );
                } else if (displayMode === "yearly-5" || displayMode === "yearly") {
                  let yearlyTotals = data.reduce((previous: any, current, index) => {
                    let { key, projectName, ...rest } = current;
                    if (index === 0) previous = rest;
                    else Object.entries(rest).forEach((entry) => (previous[entry[0]] += entry[1]));
                    return previous;
                  }, {});
                  return (
                    <Summary>
                      <Summary.Row style={{ fontWeight: 700, background: "#fafafa" }}>
                        <Summary.Cell index={0}></Summary.Cell>
                        {Object.values(yearlyTotals).map((item, index) => (
                          <Summary.Cell index={index + 1} align="right">
                            {convertWithCommas(item as number, true)}
                          </Summary.Cell>
                        ))}
                      </Summary.Row>
                    </Summary>
                  );
                }
                return <></>;
              }}
            />
          )}
        </Col>
        {displayMode === "summary" ? (
          <Col span={24}>
            <Spin spinning={isFetching || isLoading}>
              <ResponsiveContainer height={450}>
                <LineChart
                  width={730}
                  height={250}
                  data={generateChartData(records as DistributionCapitalCallSummary[])}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3" />
                  <XAxis dataKey="name" />
                  <YAxis label={<CustomLabel />} tickFormatter={(value) => value && (value / 1000000).toString()} />

                  <Tooltip
                    formatter={(value: number, name: string) => {
                      return [
                        `$${value < 0 ? "(" + Math.abs(value).toLocaleString("en-US") + ")" : value.toLocaleString("en-US")}`,
                        name === "distribution" ? "Distributions" : "Capital Call"
                      ];
                    }}
                  />
                  <Legend formatter={(value, entry, index) => (value === "distribution" ? "Distributions" : "Capital Call")} />
                  <Line type="linear" dataKey="distribution" stroke="orange" strokeWidth={3} dot={false} legendType="plainline" />
                  <Line type="linear" dataKey="captitalCall" stroke="#1890ff" strokeWidth={3} dot={false} legendType="plainline" />
                  {/* <Line type="monotone" dataKey="pv" stroke="orange" strokeWidth={3} dot={false} legendType="plainline" strokeDasharray="4" />
              <Line type="monotone" dataKey="uv" stroke="#1890ff" strokeWidth={3} dot={false} legendType="plainline" strokeDasharray="4" /> */}
                </LineChart>
              </ResponsiveContainer>
            </Spin>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </div>
  );
};
