import { Link } from "react-router-dom";
import { Account } from "../../../../types/account";

export const tableColumns = [
  {
    title: "#",
    dataIndex: "accountID",
    key: "key",
    width: "10%"
  },
  {
    title: "Account Name",
    dataIndex: "accountName",
    key: "accountName",
    editable: true,
    render: (value: string, record: Account) => <Link to={`/bank-statement/${record.accountID}`}>{value}</Link>
  },
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
    render: (value: string, record: Account) => <Link to={`/bank-statement/${record.accountID}`}>{value}</Link>
  }
];
