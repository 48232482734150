import { callApiInstance } from "./api";

class AuthService {
  async sendSms(accountId: string, phoneNumber: string) {
    return callApiInstance({ url: `/Message/send-sms?accountID=${accountId}&phoneNumber=${phoneNumber}` });
  }
  async verifyCode(phoneNumber: string, code: string) {
    return callApiInstance({ url: `/Message/verify-code?phoneNumber=${phoneNumber}&code=${code}` });
  }
  async userRequiresVerification(accountId: string) {
    return callApiInstance({ url: `/Message/requires-code?phoneNumber=${accountId}` });
  }
  async logoutUser(accountId: string) {
    return callApiInstance({ url: `/Message/logout?phoneNumber=${accountId}`, method: "POST" });
  }
}

export const authService = new AuthService();
