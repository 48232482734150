import { FC, useContext, useEffect, useState } from "react";
import { DatePicker, Descriptions, Radio, RadioChangeEvent, Input, Space, Row, Col, Skeleton, Button, message } from "antd";
import moment from "moment";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import { DetailsTable, TableSortersAndFilters } from "../../components";
import { useDebounce } from "../../helpers/useDebounce";
import { useWindowSize } from "../../helpers/useWindowSize";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { DataContext } from "../../contexts/DataContext";
import { AccountDetailsQueryParams, AccountTransactionsParams, SortersAndFilterParams } from "../../types/transactions";
import { Account } from "../../types/account";
import { DetailsType } from "../../components/DetailsTable/columns";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { generateDownload } from "../../helpers/generateDownload";
import { transactionsService } from "../../services/transactions";

const { Group, Button: RadioButton } = Radio;

interface DetailsResults {
  data: DetailsType[];
  currentPage: number;
  totalPageCount: number;
  totalResultCount: number;
  openingBalance: number;
  closingBalance: number;
}

type DisplayMode = "month" | "range" | "last-month" | null;
const { Item } = Descriptions;
const { RangePicker } = DatePicker;

export const AccountDetails: FC = () => {
  const { startDate } = useContext(DataContext);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    startDate.clone().startOf("month") === moment().startOf("month") ? "month" : null
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedRange, setSelectedRange] = useState<[moment.Moment, moment.Moment]>([startDate, startDate.clone().endOf("month")]);
  const [selectedFileType, setSelectedFileType] = useState<"pdf" | "excel">("excel");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(50);
  const [currentSortersAndFilters, setCurrentSortersAndFilters] = useState<TableSortersAndFilters>({
    filters: null,
    field: null,
    direction: null
  });
  const debouncedValue = useDebounce(searchValue, 800);
  const windowSize = useWindowSize();
  const { id } = useParams();
  const { loading, accounts } = useContext(DataContext);
  const currentAccount = !loading && accounts.length && id ? accounts.filter((item: Account) => item.accountID === parseInt(id))[0] : null;

  useEffect(() => {
    if (displayMode === "month") setSelectedRange([moment().startOf("month"), moment()]);
    else if (displayMode === "last-month")
      setSelectedRange([moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]);
  }, [displayMode]);

  // useEffect(() => {
  //   if (location.state && location.state.date) setSelectedRange([moment(location.state.date), moment(location.state.date).endOf("month")]);
  //   return () => window.history.replaceState({}, document.title);
  // }, [location]);

  ///Queries

  //Queries

  const {
    isLoading,
    data: detailsData,
    refetch
  } = useQuery<DetailsResults>(
    ["account-details", id, selectedRange[0], selectedRange[1], debouncedValue, currentSortersAndFilters],
    async () => {
      const params: AccountTransactionsParams = {
        id: parseInt(id ? id : "0"),
        startDate: selectedRange[0].clone().format("MM/DD/YYYY"),
        endDate: selectedRange[1].clone().format("MM/DD/YYYY")
      };
      let additionalParams: AccountDetailsQueryParams = {
        searchValue: debouncedValue,
        sortColumn: currentSortersAndFilters.field && currentSortersAndFilters.direction ? currentSortersAndFilters.field : null,
        sortDirection: currentSortersAndFilters.direction ? (currentSortersAndFilters.direction === "ascend" ? "asc" : "desc") : null,
        filterValue: currentSortersAndFilters.filters ? currentSortersAndFilters.filters.join(",") : ""
      };
      const response = await transactionsService.getAccountTransactions(params, additionalParams);
      setCurrentPageSize(response.totalResultCount);
      return response;
    },
    { keepPreviousData: true, enabled: !!currentAccount }
  );

  const handleDownload = async () => {
    try {
      message.loading({ content: "Downloading file...", key: "downloading" });
      const params: SortersAndFilterParams & AccountTransactionsParams = {
        id: parseInt(id ? id : "0"),
        startDate: displayMode === "month" ? moment().startOf("month").format("MM/DD/YYYY") : selectedRange[0].clone().format("MM/DD/YYYY"),
        endDate: displayMode === "month" ? moment().format("MM/DD/YYYY") : selectedRange[1].clone().format("MM/DD/YYYY"),
        searchValue: debouncedValue,
        sortColumn: currentSortersAndFilters.field && currentSortersAndFilters.direction ? currentSortersAndFilters.field : null,
        sortDirection: currentSortersAndFilters.direction ? (currentSortersAndFilters.direction === "ascend" ? "asc" : "desc") : null,
        filterValue: currentSortersAndFilters.filters ? currentSortersAndFilters.filters.join(",") : ""
      };
      const file = await transactionsService.getAccountDetailsFile(selectedFileType, params);
      generateDownload(
        file,
        `(${currentAccount?.accountNumber})${currentAccount?.accountName} - ${selectedRange[1].clone().format("MM-DD-YYYY")}.${
          selectedFileType === "pdf" ? "pdf" : "xlsx"
        }`,
        selectedFileType
      );
      message.success({ content: "Success", key: "downloading" });
    } catch (err) {
      message.error({ content: err, key: "downloading" });
    }
  };

  const resetPageSettings = (): void => {
    setCurrentPage(1);
    setCurrentPageSize(50);
  };

  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Descriptions
          column={{ xs: 1, sm: 1, md: 2, lg: 4 }}
          size="small"
          bordered
          layout={windowSize.width < 600 ? "vertical" : "horizontal"}
        >
          <Item label="Account Name">
            <b>{currentAccount ? `${currentAccount.accountName} (${currentAccount.accountNumber})` : <Skeleton.Input active />}</b>
          </Item>
          <Item label="Display Mode" span={2}>
            <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                setDisplayMode(e.target.value);
                resetPageSettings();
              }}
              value={displayMode}
            >
              <Space direction="vertical">
                <Radio value="month">Within this month</Radio>
                <Radio value="last-month">Last month</Radio>
                <Radio value="range">
                  Range{" "}
                  <RangePicker
                    disabled={displayMode !== "range"}
                    style={{ marginLeft: "1rem" }}
                    value={selectedRange}
                    onCalendarChange={(values: any) => {
                      setSelectedRange(values);
                      resetPageSettings();
                    }}
                    format="MM/DD/YYYY"
                    allowClear={false}
                  />
                </Radio>
              </Space>
            </Radio.Group>
          </Item>
          <Item label="Search">
            <Input.Search
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                resetPageSettings();
              }}
            />
          </Item>
          <Item label="Date" span={4}>
            {selectedRange[0].clone().format("MM/DD/YYYY") + " - " + selectedRange[1].clone().format("MM/DD/YYYY")}
          </Item>
          <Item label="Opening Balance">
            {!isLoading && detailsData ? "$" + convertWithCommas(detailsData.openingBalance) : <i>No Data</i>}
          </Item>
          <Item label="Month to Date">
            {!isLoading && detailsData ? "$" + convertWithCommas(detailsData.closingBalance) : <i>No Data</i>}
          </Item>
          <Item label="Download" span={2}>
            <Group buttonStyle="solid" onChange={(e) => setSelectedFileType(e.target.value)} value={selectedFileType}>
              <RadioButton value="pdf">
                PDF <FilePdfOutlined />
              </RadioButton>
              <RadioButton value="excel">
                <FileExcelOutlined /> Excel
              </RadioButton>
            </Group>
            <Button htmlType="submit" type="primary" style={{ marginLeft: "1rem" }} onClick={() => handleDownload()}>
              Download
            </Button>
          </Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <DetailsTable
          data={detailsData ? detailsData.data : []}
          sorters={["date", "description", "customerReferenceNumber", "debit", "credit", "runningTotal"]}
          isLoading={isLoading}
          totalTransactions={detailsData ? detailsData.totalResultCount : 0}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          currentSortersAndFilters={currentSortersAndFilters}
          setCurrentPageSize={setCurrentPageSize}
          setCurrentPage={setCurrentPage}
          setCurrentSortersAndFilters={setCurrentSortersAndFilters}
          refetch={refetch}
        />
      </Col>
    </Row>
  );
};
