import { FC, useContext, useState } from "react";
import { Row, Col, Tag, RadioChangeEvent, Radio, DatePicker, Button, message } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import styles from "./CreditDebit.module.css";
import { CreditDebitTable } from "./components";
import { DateParams } from "../../types/transactions";
import { useQuery } from "@tanstack/react-query";
import { accountsService } from "../../services/accounts";
import { generateDownload } from "../../helpers/generateDownload";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { DataContext } from "../../contexts/DataContext";

const radioOptions = ["Month", "Range"];
const { RangePicker, MonthPicker } = DatePicker;
const { Group, Button: RadioButton } = Radio;

export const CreditDebit: FC = () => {
  const { startDate, setStartDate } = useContext(DataContext);
  const [selectedOption, setSelectedOption] = useState("Month");
  const [selectedRange, setSelectedRange] = useState<[moment.Moment, moment.Moment]>([
    startDate.clone().startOf("month"),
    startDate.clone().endOf("month")
  ]);
  const [selectedFormat, setSelectedFormat] = useState<"pdf" | "excel">("pdf");

  ///////// Queries

  const { isLoading, data: totalsData } = useQuery(["account-totals", selectedRange[0], selectedRange[1]], async () => {
    const params: DateParams = {
      startDate: selectedRange[0].format("MM/DD/YYYY"),
      endDate: selectedRange[1].format("MM/DD/YYYY")
    };
    const response = await accountsService.getAccountsTotals(params);
    return response;
  });

  ////////

  const handleDateChange = (value: moment.Moment | null) => {
    if (value) {
      setSelectedRange([moment(value.startOf("month").format("MM/DD/YYYY")), moment(value.endOf("month").format("MM/DD/YYYY"))]);
      setStartDate(value.startOf("month"));
    }
  };
  const handleRangeChange = (value: any) => {
    setSelectedRange(value);
    setStartDate(value[0].startOf("month"));
  };

  const handleDownload = async () => {
    try {
      message.loading({ content: "Downloading file...", key: "downloading", duration: 0 });
      const params: DateParams = {
        startDate: selectedRange[0].format("MM/DD/YYYY"),
        endDate: selectedRange[1].format("MM/DD/YYYY")
      };
      const file = await accountsService.getAccountsTotals(params, selectedFormat);
      const fileName = `${selectedRange[0].format("MMMM-YY")}-Total Debit/Credit.${selectedFormat === "pdf" ? "pdf" : "xlsx"}`;
      generateDownload(file, fileName, selectedFormat);
      message.success({ content: "Success", key: "downloading" });
    } catch (error) {
      message.error({ content: error, key: "downloading" });
    }
  };

  return (
    <>
      <Row style={{ marginBottom: "1rem" }} justify="space-between">
        <Col xs={24} sm={8} md={6}>
          <Tag style={{ fontSize: "1rem", padding: "0.5rem" }} icon={<CalendarOutlined />} color="processing">
            Today's date {moment().format("MMM, DD, YYYY")}
          </Tag>
        </Col>
        <Col xs={24} sm={12} md={10} className={styles.colFlex}>
          <Radio.Group
            options={radioOptions}
            onChange={(e: RadioChangeEvent) => {
              setSelectedOption(e.target.value);
              if (e.target.value === "Month") setSelectedRange([startDate.clone().startOf("month"), startDate.clone().endOf("month")]);
            }}
            value={selectedOption}
            optionType="button"
            buttonStyle="solid"
          />
          {selectedOption === "Month" ? (
            <MonthPicker
              value={selectedRange[0]}
              onChange={handleDateChange}
              allowClear={false}
              format="MMMM-YY"
              disabledDate={(date) => date > moment()}
            />
          ) : (
            <RangePicker value={selectedRange} onChange={handleRangeChange} allowClear={false} format="MM/DD/YYYY" />
          )}
          <Group
            value={selectedFormat}
            buttonStyle="solid"
            onChange={(e) => setSelectedFormat(e.target.value)}
            style={{ marginTop: "1rem" }}
          >
            <RadioButton value="pdf">
              PDF <FilePdfOutlined />
            </RadioButton>
            <RadioButton value="excel">
              <FileExcelOutlined /> Excel
            </RadioButton>
          </Group>
          <Button type="primary" onClick={handleDownload} style={{ marginTop: "1rem" }}>
            Download
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CreditDebitTable data={totalsData} isLoading={isLoading} />
        </Col>
      </Row>
    </>
  );
};
