import { FC, ReactElement } from "react";
import { Navigate } from "react-router";
import { Spinner } from "..";
import { useAuth } from "../../contexts/AuthContext";

type ProtectedRouteProps = {
  // isAuthenticated: boolean;
  children: ReactElement;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useAuth();
  if (!user) return <Spinner />;
  if (user.requiresVerification) {
    return <Navigate to="/auth" replace />;
  } else return children;
};
