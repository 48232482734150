import { FC } from "react";
import { Table } from "antd";
import style from "./BankStatementTable.module.css";
import { EditableCell } from "..";

interface Props {
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  data: any;
  loading: boolean;
  columns: any;
}

export const BankStatementTable: FC<Props> = ({ selectedRowKeys, setSelectedRowKeys, data, loading, columns }) => {
  //Table selection handler

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  ////

  return (
    <Table
      components={{
        body: {
          cell: EditableCell
        }
      }}
      dataSource={data}
      rowSelection={rowSelection}
      columns={columns}
      rowClassName={style.customRow}
      bordered={true}
      scroll={{ x: 500 }}
      loading={loading}
      size="small"
      style={{ marginTop: "2rem" }}
      className={style.customThumbTable}
      pagination={false}
      rowKey="accountID"
      sticky
    />
  );
};
