export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID || "", // This is the ONLY mandatory field that you need to supply.
    authority: process.env.REACT_APP_ADB2C_AUTHORITY || "", // Choose SUSI as your default authority.
    // knownAuthorities: process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES ? [process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES] : [], // Mark your B2C tenant's domain as trusted.
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: window.location.origin // Indicates the page to navigate after logout.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const scopes = ["api://169c4068-eb6b-4afd-987c-0813867b919b/readaccess"];
