import "antd/dist/antd.min.css";
import { FC, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CustomLayout } from "./layout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { message } from "antd";
import { ProtectedRoute, Spinner } from "./components";
import { scopes } from "./services/msal-config";
import { AuthPage } from "./pages";
import { AuthContextProvider } from "./contexts/AuthContext";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

export const App: FC = () => {
  const { instance } = useMsal();
  const isLoggedIn = useIsAuthenticated();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1
      }
    }
  });

  const initLogin = useCallback(async () => {
    try {
      await instance.handleRedirectPromise();
      instance.loginRedirect({ scopes });
    } catch (err: any) {
      message.error(err);
    }
  }, [instance]);

  useEffect(() => {
    if (!isLoggedIn) initLogin();
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/auth" element={<AuthPage currentURl={window.location.pathname} />} />
                <Route
                  path="/*"
                  element={
                    <ProtectedRoute>
                      <CustomLayout />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<p>There's nothing here: 404!</p>} />
              </Routes>
            </BrowserRouter>
          </AuthContextProvider>
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Spinner />
      </UnauthenticatedTemplate>
    </>
  );
};
