import { FC, useMemo, useState } from "react";
import { Form, Popconfirm, Space, Table } from "antd";
import { EditableCell } from "../../../CashManagement/components";
import styles from "../../../CashBalance/CashBalance.module.css";
import { creditFacilitiesService } from "../../../../services/credit-facilities";
import { UseMutateFunction, useQuery } from "@tanstack/react-query";
import { AlignType } from "rc-table/lib/interface";
import moment from "moment";
import { convertWithCommas } from "../../../../helpers/convertWithCommas";
import { SaveOutlined, EditOutlined, CloseOutlined, CaretUpOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { MainTableRecord } from "../../ShortTermLoanPlan";
import { Memo, ShortTermLoanRecord } from "../../../../types/short-term-loan";
import { DynamicTextarea } from "../../../../components/DynamicTextearea/DynamicTextarea";
import { MultiLoan } from "../MultiLoan/MultiLoan";

interface IMainTable {
  data: MainTableRecord[];
  handleCellClick: (
    record: { name: string; date: string; id: string | number; recordId: number | string; amount: number } | undefined
  ) => void;
  handleMemoCreation: UseMutateFunction<any, unknown, Memo, unknown>;
  loading: boolean;
  shortTermLoans: ShortTermLoanRecord[];
}

export const MainTable: FC<IMainTable> = ({ data, handleCellClick, handleMemoCreation, loading, shortTermLoans }) => {
  const [editingKey, setEditingKey] = useState<string | undefined>(undefined);
  const [form] = Form.useForm();

  // console.log(editingKey, "KEYYY");

  // console.log("CHECKING DATA HERE =====>", { data });
  const [visible, setVisible] = useState(false);

  const formOnFinish = (values: any) => {
    // console.log(values);
    handleMemoCreation({ date: editingKey!, memo: values.memo });
    setEditingKey(undefined);
  };
  const isEditing = (record: any) => record.date === editingKey;
  const edit = (record: any) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.date);
  };
  const cancel = () => {
    setEditingKey(undefined);
  };
  const {
    data: creditFacilities,
    isLoading: areCreditFacilitiesLoading,
    refetch: refetchFacilities
  } = useQuery(["credit-facilities"], () => creditFacilitiesService.getAllFacilities(false), { initialData: [] });

  const firstColumn: ColumnsType<any> = [
    {
      key: 0,
      dataIndex: "date",
      title: () => <div style={{ textAlign: "center" }}>Date</div>,
      width: 100,
      align: "center" as AlignType,
      fixed: "left",
      render: (date: string) => moment(date).format("MM/DD/YYYY"),
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      defaultSortOrder: "descend"
    }
  ];

  const getSumArr = (array: number[]) => {
    let sum = 0;
    array.forEach((element) => {
      sum += element;
    });
    return sum;
  };

  const [recordToEdit, setRecordToEdit] = useState<{ record: any; data: { name: string; date: string; id: number[] | string[] } } | null>(
    null
  );

  const handleCellClickNew = (record: any, data: { name: string; date: string; id: number[] | string[] }) => {
    console.log("YOU ARE HERE ===>", { data });

    const dataEdit = {
      name: data.name,
      date: data.date
    };

    if (data.id.length > 1) {
      setRecordToEdit({ record, data });
      setVisible(true);
    } else {
      console.log("DATA LESS THAN 1", data.id);
      handleCellClick({
        ...dataEdit,
        id: data.id[0],
        recordId: data.id[0],
        amount: record[data.name][0]
      });
    }

    // name: item.name,
    //             date: record.date,
    //             id: record[`${item.name}_id`],
    //             recordId: record[`${item.name}_id`],
    //             amount: amount
  };

  const columns: ColumnsType<any> = creditFacilities.map((item, index) => {
    return {
      key: index + 1,
      dataIndex: item.name,
      title: () => <div style={{ textAlign: "center" }}>{item.name}</div>,
      width: 150,
      align: "right" as AlignType,
      render: (amount: number, record: any) => (
        <>
          {/* {JSON.stringify(item)} */}
          <span
            style={{ cursor: "pointer", width: "100%", display: "inline-block", height: "100%" }}
            onDoubleClick={() =>
              handleCellClickNew(record, {
                name: item.name,
                date: record.date,
                id: record[`${item.name}_id`]
              })
            }
          >
            {record[item.name]?.length > 1 && <CaretUpOutlined></CaretUpOutlined>}
            {record[item.name] ? "$" + convertWithCommas(getSumArr(record[item.name])) : ""}
          </span>
        </>
      )
    };
  });

  const lastColumns = [
    {
      key: creditFacilities.length + 1,
      dataIndex: "loanSubtotal",
      title: () => <div style={{ textAlign: "center" }}>Loan Subtotal</div>,
      width: 120,
      fixed: "right",
      align: "right" as AlignType,
      render: (amount: number) => (amount ? "$" + convertWithCommas(amount) : "")
    },
    {
      key: creditFacilities.length + 2,
      dataIndex: "balance",
      title: () => <div style={{ textAlign: "center" }}>Balance</div>,
      width: 130,
      fixed: "right",
      align: "right" as AlignType,
      render: (amount: number) => (amount ? "$" + convertWithCommas(amount) : "")
    },
    {
      key: creditFacilities.length + 3,
      dataIndex: "memo",
      title: () => <div style={{ textAlign: "center" }}>Memo</div>,
      width: 250,
      fixed: "right",
      editable: true,
      align: "left" as AlignType,
      render: (memo: string, record: any) => {
        return <DynamicTextarea defaultValue={memo} disabled />;
      }
    },
    {
      key: "actions",
      title: "Actions",
      align: "center" as AlignType,
      fixed: "right",
      width: 80,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size="small">
            <Popconfirm placement="left" title="Save the record?" onConfirm={() => form.submit()}>
              <SaveOutlined style={{ fontSize: "1.2rem", color: "green" }} />
            </Popconfirm>
            <Popconfirm placement="left" title="Are you sure you want to cancel editing this record?" onConfirm={cancel}>
              <CloseOutlined style={{ fontSize: "1.2rem", color: "red" }} />
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <EditOutlined
              style={{ fontSize: "1.2rem", color: "#1890ff" }}
              disabled={editingKey !== undefined}
              onClick={() => edit(record)}
            />
          </Space>
        );
      }
    }
  ];

  const clickableColumns = [...firstColumn, ...columns, ...lastColumns].map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        inputType: col.dataIndex === "memo" ? "text" : "",
        dataIndex: col.dataIndex,
        title: col.dataIndex === "memo" ? "Memo" : col.title,
        editing: isEditing(record)
      })
    };
  });

  console.log("check the wrong data", data);

  return (
    <>
      <Form form={form} onFinish={formOnFinish}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          loading={loading || areCreditFacilitiesLoading}
          columns={clickableColumns}
          size={"small"}
          dataSource={data}
          bordered
          rowClassName={styles.stripedRow}
          sticky
          pagination={false}
          scroll={{ x: 1500 }}
          key="id"
          rowKey="id"
        />
      </Form>
      <MultiLoan
        setVisible={setVisible}
        visible={visible}
        handleCellClick={handleCellClick}
        recordToEdit={recordToEdit}
        setRecordToEdit={setRecordToEdit}
        shortTermLoans={shortTermLoans}
      ></MultiLoan>
    </>
  );
};
