import { ColumnsType } from "antd/es/table/interface";
import moment from "moment";
import { Link } from "react-router-dom";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import { CashBalance, DailyMonthlyCashBalance, QuarterlyCashBalance } from "../../types/balance";

export type MixedColumnType = DailyMonthlyCashBalance & QuarterlyCashBalance;

export const defaultColumns: ColumnsType<MixedColumnType> = [
  {
    title: "Account Name",
    dataIndex: "accountName",
    key: "accountName",
    render: (value: string, record: CashBalance) => <Link to={`/account-details/${record.accountID}`}>{value}</Link>
  },
  {
    title: "Account No.",
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: "10%",
    render: (value: string, record: CashBalance) => <Link to={`/account-details/${record.accountID}`}>{value}</Link>
  }
];

export const dailyMonthlyColumns = (type: string, date?: moment.Moment) => {
  let columns = [
    ...defaultColumns,
    {
      title: type === "daily" ? "Yesterday's Balance" : "Last Month Balance",
      dataIndex: "lastBalance",
      key: "lastBalance",
      sorter: (a: MixedColumnType, b: MixedColumnType) => a.lastBalance - b.lastBalance,
      render: (text: number) => "$ " + convertWithCommas(text)
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      sorter: (a: MixedColumnType, b: MixedColumnType) => a.debit - b.debit,
      render: (text: number) => "$" + convertWithCommas(text)
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      sorter: (a: MixedColumnType, b: MixedColumnType) => a.credit - b.credit,
      render: (text: number) => "$ " + convertWithCommas(text)
    },
    {
      title: type === "daily" ? "Current Balance" : "This Month's Balance",
      dataIndex: "currentBalance",
      key: "currentBalance",
      sorter: (a: MixedColumnType, b: MixedColumnType) => a.currentBalance - b.currentBalance,
      render: (text: number) => "$ " + convertWithCommas(text)
    },
    {
      title: "Variances",
      dataIndex: "variances",
      key: "variances",
      sorter: (a: MixedColumnType, b: MixedColumnType) => a.currentBalance - a.lastBalance - (b.currentBalance - b.lastBalance),
      render: (text: number, record: DailyMonthlyCashBalance) => "$ " + convertWithCommas(record.currentBalance - record.lastBalance)
    }
  ];
  // had problems with react router, apparently you have to serialize the state object cause browser will reload if you pass something thats not serializable
  if (type === "monthly" && !!date) {
    const renderFunction = (value: string, record: CashBalance) => (
      <Link to={`/account-details/${record.accountID}`} state={{ date: date.format("MM/DD/YYYY") }}>
        {value}
      </Link>
    );
    columns[0].render = renderFunction;
    columns[1].render = renderFunction;
  }

  return columns;
};

export const quarterlyColumns: ColumnsType<MixedColumnType> = [
  ...defaultColumns,
  {
    title: "Q1",
    dataIndex: "q1",
    key: "q1",
    render: (text: number) => "$ " + convertWithCommas(text)
  },
  {
    title: "Q2",
    dataIndex: "q2",
    key: "q2",
    render: (text: number) => "$ " + convertWithCommas(text)
  },
  {
    title: "Q3",
    dataIndex: "q3",
    key: "q3",
    render: (text: number) => "$ " + convertWithCommas(text)
  },
  {
    title: "Q4",
    dataIndex: "q4",
    key: "q4",
    render: (text: number) => "$ " + convertWithCommas(text)
  }
];
