import { FC, useEffect, useRef, useState } from "react";
import styles from "./DynamicTextarea.module.css";

interface Props {
  defaultValue: string;
  disabled: boolean;
}

export const DynamicTextarea: FC<Props> = ({ defaultValue, disabled }) => {
  const [value, setValue] = useState(defaultValue);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
  };

  useEffect(() => setValue(defaultValue), [defaultValue]);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);

  return (
    <textarea
      onChange={handleChange}
      ref={textAreaRef}
      value={value}
      defaultValue={defaultValue}
      className={styles.mainStyles}
      disabled={disabled}
    />
  );
};
