import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Table, Form, Space, Popconfirm, message } from "antd";
import { CashManagementRecord } from "../../../../types/cash-management";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { convertWithCommas } from "../../../../helpers/convertWithCommas";
import { Account } from "../../../../types/account";
import styles from "../../../CashBalance/CashBalance.module.css";
import { PlusOutlined, SaveOutlined, EditOutlined, CloseOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditableCell } from "..";
import { DataContext } from "../../../../contexts/DataContext";
import { cashManagementService } from "../../../../services/cash-management";

interface ICashManagementTable {
  account: Account & { code: string };
  data: CashManagementRecord[];
  handleCopy?: (arg: any) => void;
  handleSave?: (arg: CashManagementRecord[]) => void;
  isLoading?: boolean;
}

export const CashManagementTable: FC<ICashManagementTable> = ({ account, handleCopy, handleSave, data: sourceData, isLoading }) => {
  const { codes } = useContext(DataContext);
  const [data, setData] = useState(sourceData);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<number | undefined>(undefined);

  const isEditing = (record: CashManagementRecord) => record?.id === editingKey;
  const lastTransactionId = useMemo(
    () =>
      data &&
      data.reduce(
        (prev, curr) =>
          curr.gl_code === null && curr.description !== "Closing Amount" && moment(curr.date).isSameOrAfter(moment(), "day")
            ? (prev = curr.id)
            : prev,
        -1
      ),
    [data]
  );

  useEffect(() => setData(sourceData), [sourceData]);
  const formOnFinish = (values: any) => {
    const itemIndex = data.findIndex((item) => item.id === editingKey);
    handleSave!(
      itemIndex === data.length - 1 && editingKey === -1 && data[data.length - 2].description !== "Opening Ledger"
        ? [
            data[data.length - 2],
            {
              ...values,
              id: editingKey,
              // date: data[itemIndex].date,
              accountID: account.accountID
            }
          ]
        : [
            {
              ...values,
              id: editingKey,
              // date: data[itemIndex].date,
              accountID: account.accountID
            }
          ]
    );
    setEditingKey(undefined);
  };

  const cancel = () => {
    if (editingKey === -1) setData(data.filter((item: any) => item.id !== -1));
    setEditingKey(undefined);
  };

  const edit = (record: Partial<CashManagementRecord>) => {
    form.setFieldsValue({ ...record, date: moment(record.date) });
    setEditingKey(record.id);
  };

  const insertRow = (insertIndex: number) => {
    //not very great solution with the time, might need a change in the future

    let newArray: any = [...data];
    let date = moment();
    //DEPRECATED

    //if there is only one element add 24 hours to current opening balance
    // if (data.length === 1) {
    //   date = moment(data[0].date).endOf("day").subtract(6, "milliseconds").format("YYYY-MM-DDTHH:mm:ss.SSS");
    //   //if the element we are trying to add is last add half the amount that's left till end of the day so we dont go onto next day
    // } else if (insertIndex === data.length - 1) {
    //   const lastEntryDate = moment(data[data.length - 1].date);
    //   const dayBeforeDate = moment(data[data.length - 2].date);
    //   const difference = moment.duration(lastEntryDate.diff(dayBeforeDate));
    //   date = moment(data[0].date).endOf("day").subtract(6, "milliseconds").format("YYYY-MM-DDTHH:mm:ss.SSS");
    //   newArray.splice(insertIndex, 1, {
    //     ...newArray[insertIndex],
    //     date: dayBeforeDate.add(difference.asMilliseconds() / 2, "milliseconds").format("YYYY-MM-DDTHH:mm:ss.SSS")
    //   });

    //   //if element is somewhere in between always make the time half between those 2 elements
    // } else {
    //   const difference = moment.duration(moment(data[insertIndex + 1].date).diff(moment(data[insertIndex].date)));
    //   // // console.log(difference.asSeconds(), "DIFF");
    //   date = moment(data[insertIndex].date)
    //     .add(difference.asMilliseconds() / 2, "milliseconds")
    //     .format("YYYY-MM-DDTHH:mm:ss.SSS");
    // }

    ///
    newArray.splice(insertIndex + 1, 0, {
      id: -1,
      date,
      code: "",
      amount: 0,
      description: "",
      balance: 0
    });

    setData(newArray);
    form.setFieldsValue({
      code: "",
      amount: 0,
      description: ""
    });
  };

  const columns = [
    {
      title: (
        <strong style={{ display: "flex", justifyContent: "space-around", fontSize: "1rem" }}>
          <span>Account Name: {`${account.accountName} #${account.accountNumber}`}</span>
          <span>{`GL# ${account.glAccountCode}`}</span>
        </strong>
      ),
      children: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          width: 95,
          editable: true,
          render: (date: string) => moment(date).format("MM/DD/YYYY")
        },
        {
          title: "GL Code",
          dataIndex: "glCodeID",
          key: "glCodeID",
          width: "25%",
          editable: true,
          render: (text: string, record: CashManagementRecord) => (!record.gl_code ? "" : `${record.gl_code} (${record.account_name})`)
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          align: "right",
          width: 150,
          editable: true,
          render: (amount: number) => "$" + convertWithCommas(amount)
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          width: "30%",
          editable: true
        },
        {
          title: "Balance",
          dataIndex: "balance",
          key: "balance",
          align: "right",
          width: 150,
          render: (amount: number) => "$" + convertWithCommas(amount)
        }
      ],
      key: "accountInfo"
      // width: "100%"
    }
  ];

  const addLineColumn: ColumnsType<any[0]> = [
    {
      key: "add-line-column",
      render: (_, record) =>
        editingKey !== -1 &&
        ((record.gl_code !== null && moment(record.date).isSameOrAfter(moment(), "day")) ||
          (record.gl_code === null && lastTransactionId === record.id)) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative",
              width: "10",
              height: "100%"
            }}
            onClick={() => {
              // console.log(data, record.id);
              insertRow(data.findIndex((item: any) => item.id === record.id));
              setEditingKey(-1);
            }}
          >
            <PlusOutlined style={{ position: "absolute", left: "-30px", top: "-10px", color: "green", fontSize: "1.2rem" }} />
          </div>
        ) : (
          ""
        ),
      width: 0
    }
  ];

  const deleteRecord = async (id: string | number) => {
    try {
      await cashManagementService.deleteRecord(id);
      setData((oldData) => oldData.filter((record) => record.id !== id));
      message.success("Record successfully deleted");
    } catch (error) {
      console.error(error);
      message.error("There was an error deleting the record, please refresh and try again");
    }
  };

  const actionsColumn: ColumnsType<any[0]> = [
    {
      title: "Actions",
      align: "right",
      key: "actions",
      width: 90,
      className: "action-column",
      render: (_: any, record: CashManagementRecord) => {
        const editable = isEditing(record);
        if (record.description === "Opening Ledger") return <></>;
        return editable ? (
          <Space size="small">
            <Popconfirm placement="left" title="Save the record?" onConfirm={() => form.submit()}>
              <SaveOutlined style={{ fontSize: "1.2rem", color: "green" }} />
            </Popconfirm>
            <Popconfirm placement="left" title="Are you sure you want to cancel editing this record?" onConfirm={cancel}>
              <CloseOutlined style={{ fontSize: "1.2rem", color: "red" }} />
            </Popconfirm>
          </Space>
        ) : record.description !== "Opening Amount" && record.description !== "Closing Amount" ? (
          <Space>
            <EditOutlined
              style={{ fontSize: "1.2rem", color: "#1890ff" }}
              disabled={editingKey !== undefined}
              onClick={() => edit(record)}
            />
            {!record.isTransaction ? (
              <Popconfirm placement="left" title="Delete the record?" onConfirm={() => deleteRecord(record.id)}>
                <DeleteOutlined style={{ fontSize: "1.2rem", color: "#ff182b" }} />
              </Popconfirm>
            ) : (
              ""
            )}
            {handleCopy && record.gl_code !== null ? (
              <CopyOutlined
                style={{ fontSize: "1.2rem", color: "#1890ff", cursor: "pointer" }}
                onClick={() => handleCopy({ ...account, ...record })}
              />
            ) : null}
          </Space>
        ) : (
          ""
        );
      }
    }
  ];

  const mergedColumns = columns.map((column: any) => {
    if (column.children) {
      column.children = column.children.map((col: any) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record: CashManagementRecord) => ({
            record,
            inputType:
              col.dataIndex === "glCodeID" ? "select" : col.dataIndex === "amount" ? "number" : col.dataIndex === "date" ? "date" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record)
          })
        };
      });
    }
    return column;
  });

  return (
    <Form form={form} onFinish={formOnFinish}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        columns={handleCopy ? [...addLineColumn, ...mergedColumns, ...actionsColumn] : [...mergedColumns]}
        size={"small"}
        dataSource={data}
        bordered
        rowClassName={styles.stripedRow}
        sticky
        pagination={false}
        loading={isLoading}
        key="id"
        rowKey="id"
      />
    </Form>
  );
};
