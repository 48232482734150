import { UseMutateFunction } from "@tanstack/react-query";
import { Modal, Form } from "antd";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { CreditFacilityForm, ProjectForm } from "..";
import { ModalOptions } from "../../../../layout";
import { MutationType } from "../../../../types";
import { CreateCreditFacility, CreateProject } from "../../../../types/admin-management";
import { GlCodeForm } from "../GlCodeForm/GlCodeForm";
import { CreateGlCode } from "../../../../types/gl-code";
import { json } from "stream/consumers";

interface IAdminManagementModal {
  options: { opts: ModalOptions; setOptions: React.Dispatch<React.SetStateAction<ModalOptions>> };
  handleModalOk: (
    formType: "facility" | "project" | "glCode",
    {
      data,
      type,
      id
    }: {
      type: MutationType;
      id?: number | undefined;
      data?: CreateProject | CreateCreditFacility | CreateGlCode | undefined;
    }
  ) => void;
}

export const AdminManagementModal: FC<IAdminManagementModal> = ({ options, handleModalOk }) => {
  const [projectForm] = Form.useForm<CreateProject>();
  const [facilityForm] = Form.useForm<CreateCreditFacility>();
  const [glCodeForm] = Form.useForm<CreateGlCode>();
  const { opts, setOptions } = options;
  const { visible, payload } = opts;
  const isEditing = payload && payload.id;
  const [currentValues, setCurrentValues] = useState("");

  useEffect(() => {
    if (payload) {
      const { form, ...rest } = payload;

      switch (form) {
        case "project":
          projectForm.setFieldsValue(rest);
          setCurrentValues(JSON.stringify(projectForm.getFieldsValue()));
          break;
        case "facility":
          facilityForm.setFieldsValue({ ...rest, expiryDate: moment(rest.expiryDate) });
          setCurrentValues(JSON.stringify(facilityForm.getFieldsValue()));
          break;

        default:
          glCodeForm.setFieldsValue(rest);
          setCurrentValues(JSON.stringify(glCodeForm.getFieldsValue()));
          break;
      }
    }
    return () => {
      projectForm.resetFields();
      facilityForm.resetFields();
      glCodeForm.resetFields();
    };
  }, [payload]);

  return (
    <Modal
      title={isEditing ? "Edit" : "Add"}
      visible={visible}
      onCancel={() => setOptions({ visible: false, payload: null })}
      footer={null}
      centered
    >
      {payload && payload.form === "project" && (
        <ProjectForm
          form={projectForm}
          currentValues={currentValues}
          onFinish={(values) =>
            handleModalOk("project", {
              data: values,
              type: isEditing ? "update" : "create",
              id: isEditing ? payload.id : undefined
            })
          }
        />
      )}

      {payload && payload.form === "facility" && (
        <CreditFacilityForm
          form={facilityForm}
          currentValues={currentValues}
          onFinish={(values) =>
            handleModalOk("facility", {
              data: { ...values, expiryDate: (values.expiryDate as moment.Moment).format("YYYY-MM-DD") } as CreateCreditFacility,
              type: isEditing ? "update" : "create",
              id: isEditing ? payload.id : undefined
            })
          }
        />
      )}
      {payload && payload.form === "glCode" && (
        <GlCodeForm
          form={glCodeForm}
          currentValues={currentValues}
          onFinish={(values) => {
            handleModalOk("glCode", {
              data: { ...values } as CreateGlCode,
              type: isEditing ? "update" : "create",
              id: isEditing ? payload.id : undefined
            });
          }}
        />
      )}
    </Modal>
  );
};
