import { CreateShortTermLoan, EditShortTermLoan, Memo, ShortTermLoanRecord } from "../types/short-term-loan";
import { callApiInstance } from "./api";

class ShorTermLoanService {
  private rootRoute: string;
  constructor() {
    this.rootRoute = "/ShortTermLoan";
  }
  async createShortTermLoan(data: CreateShortTermLoan) {
    return await callApiInstance({ url: this.rootRoute, method: "POST", data });
  }
  async editShortTermLoan(data: EditShortTermLoan) {
    return await callApiInstance({ url: this.rootRoute, method: "PUT", data });
  }
  async getAllShortTermLoans(): Promise<ShortTermLoanRecord[]> {
    return await callApiInstance({ url: `${this.rootRoute}` });
  }
  async getAllMemos(): Promise<any> {
    return await callApiInstance({ url: `${this.rootRoute}/memo` });
  }
  async createMemoForDate(data: Memo): Promise<any> {
    return await callApiInstance({ url: `${this.rootRoute}/memo`, method: "POST", data });
  }
  async getAllowedAmount(creditFacilityID: number, startDate: string, isRollover: boolean): Promise<number> {
    const allowedAmount = (await callApiInstance({ url: `${this.rootRoute}/AvailableAmount?loanDate=${startDate}&creditFacilityID=${creditFacilityID}&isRollover=${isRollover ? isRollover : false}` }))[0].availableLoanAmount

    return allowedAmount
  }
  async deleteLoan(loanId: string | number): Promise<any> {
    return await callApiInstance({ url: `${this.rootRoute}?id=${loanId}`, method: 'DELETE' })


  }

  async downloadShortTermLoan(): Promise<any> {
    return await callApiInstance({
      url: `${this.rootRoute}/Excel`,
      responseType: "arraybuffer"
    });
  }
}
export const shortTermLoanService = new ShorTermLoanService();
