import { FC } from "react";
import { Table } from "antd";
import { tableColumns } from "./columns";
import style from "../../../BankStatement/components/BankStatementTable/BankStatementTable.module.css";
import { CashBalance } from "../../../../types/balance";
import styles from "../../../../components/DetailsTable/DetailsTable.module.css";
import { convertWithCommas } from "../../../../helpers/convertWithCommas";

const { Summary } = Table;

interface Props {
  data: CashBalance[];
  isLoading: boolean;
}

export const CreditDebitTable: FC<Props> = ({ data, isLoading }) => {
  return (
    <Table
      dataSource={data ? data : []}
      columns={tableColumns}
      bordered={true}
      size="small"
      style={{ marginTop: "2rem" }}
      scroll={{ x: 700 }}
      className={style.customThumbTable}
      loading={isLoading}
      pagination={false}
      sticky
      rowKey={"accountID"}
      rowClassName={`${styles.stripedRow}`}
      summary={(data) => {
        const totals: any = data.reduce(
          (prev: any, curr: any) => {
            return {
              totalDebit: (prev.totalDebit += curr.debit),
              totalCredit: (prev.totalCredit += curr.credit),
              totalVariances: (prev.totalVariances += curr.credit - curr.debit)
            };
          },
          { totalDebit: 0, totalCredit: 0, totalVariances: 0 }
        );

        return (
          <Summary fixed="top">
            <Summary.Row style={{ fontWeight: 700, background: "#fafafa" }}>
              <Summary.Cell index={0}>Total</Summary.Cell>
              <Summary.Cell index={1}></Summary.Cell>
              <Summary.Cell index={2}></Summary.Cell>
              <Summary.Cell index={3}>{"$" + convertWithCommas(totals.totalDebit)}</Summary.Cell>
              <Summary.Cell index={4}>{"$" + convertWithCommas(totals.totalCredit)}</Summary.Cell>
              <Summary.Cell index={5}>{"$" + convertWithCommas(totals.totalVariances)}</Summary.Cell>
            </Summary.Row>
          </Summary>
        );
      }}
    />
  );
};
