import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Col, Input, message, Row, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { authService } from "../../services/auth";
import styles from "./AuthPage.module.css";

interface Props {
  currentURl: string;
  //   isAuthenticated: boolean;
  //   recheck: any;
  //   userPhoneNumber: string;
  //   setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthPage: FC<Props> = ({ currentURl }) => {
  const { user, setUser } = useAuth();
  const { localAccountId, mobilePhone } = user;
  const [codeValue, setCodeValue] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const {
    data: codeData,
    refetch: resendSms,
    isFetching
  } = useQuery(["user-code"], () => authService.sendSms(localAccountId, mobilePhone), {
    enabled: false
  });

  const verifyCode = useMutation(["verify-code"], () => authService.verifyCode(mobilePhone, codeValue), {
    onSuccess: () => {
      message.success({ content: "Verified", key: "ver" });
      setUser({ ...user, requiresVerification: false });
      navigate("/");
    },
    onMutate: () => {
      message.loading({ content: "Verifying...", key: "ver", duration: 0 });
    },
    onError: () => {
      message.error({ content: "Wrong code provided", key: "ver" });
    }
  });
  // remove this for production

  useEffect(() => {
    setUser({ ...user, requiresVerification: false });
    console.log("current url ", currentURl);

    navigate(currentURl);
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Spin spinning={false}>
          <Col style={{ background: "#002c55", padding: "2rem", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
            <Row align="middle" style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1rem" }}>
                <img src={process.env.PUBLIC_URL + "/logo-menu.png"} alt="MFA-Logo" />
              </Col>
              <Col>
                <p style={{ textAlign: "center", color: "white" }}>
                  Two factor is required in order to log-in.
                  <br />
                  Click the button to get the number sent to your mobile phone
                </p>
              </Col>
              {codeData ? (
                <Col style={{ marginBottom: "1rem" }}>
                  <Input
                    size="large"
                    type="number"
                    status={codeValue.length === 6 ? "" : "error"}
                    className={styles.numberInput}
                    minLength={6}
                    maxLength={6}
                    value={codeValue}
                    onChange={(e) => setCodeValue(e.target.value.length > 6 ? codeValue : e.target.value)}
                  />
                </Col>
              ) : (
                ""
              )}
              <Col>
                <Button
                  loading={isFetching || verifyCode.isLoading}
                  type="primary"
                  style={{ marginRight: codeData ? "1rem" : "0" }}
                  onClick={() =>
                    codeData
                      ? codeValue.length === 6
                        ? verifyCode.mutate()
                        : message.warning("Code must be a 6 digit value.")
                      : resendSms()
                  }
                >
                  {codeData ? "Submit" : "Get"} Code
                </Button>
                {codeData !== undefined ? (
                  <Button
                    disabled={buttonDisabled || verifyCode.isLoading || isFetching}
                    // loading={isFetching}
                    type="primary"
                    onClick={() => {
                      resendSms();
                      setButtonDisabled(true);
                      setTimeout(() => setButtonDisabled(false), 10000);
                    }}
                  >
                    Resend Code
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Spin>
      </Row>
    </div>
  );
};
