import React, { FC, useContext, useEffect, useState } from "react";
import { DatePicker, Steps, Button, Radio, RadioChangeEvent, message, Row, Col, Form, Popconfirm } from "antd";
import {
  SelectOutlined,
  FileUnknownOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined
} from "@ant-design/icons";
import moment from "moment";
import style from "./BankStatement.module.css";
import { BankStatementTable } from "./components";
import { useWindowSize } from "../../helpers/useWindowSize";
import { DataContext } from "../../contexts/DataContext";
import { generateDownload } from "../../helpers/generateDownload";
import { Account } from "../../types/account";
import { useMutation } from "@tanstack/react-query";
import { tableColumns } from "./components/BankStatementTable/columns";
import { accountsService } from "../../services/accounts";

const { Step } = Steps;
const { Group, Button: RadioButton } = Radio;

type Status = "wait" | "process" | "finish" | "error";

export const BankStatement: FC = () => {
  ///////// Hooks
  const { startDate } = useContext(DataContext);
  // ['Select', 'File Type', 'Download'];
  const [currentStatuses, setCurrentStatuses] = useState<Status[]>(["process", "wait", "wait"]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(startDate);
  const [selectedFormat, setSelectedFormat] = useState<"pdf" | "excel" | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editingKey, setEditingKey] = useState<number | undefined>();
  const { accounts, loading, reloadAccounts } = useContext(DataContext);
  const windowSize = useWindowSize();
  const [form] = Form.useForm();

  useEffect(() => {
    const statuses: Status[] = ["process", "wait", "wait"];
    if (selectedDate) {
      statuses[0] = "finish";
      if (selectedFormat) {
        statuses[1] = "finish";
        statuses[2] = "process";
      } else statuses[1] = "process";
    }
    setCurrentStatuses(statuses);
  }, [selectedDate, selectedFormat]);

  //////////

  ///////// Mutations

  const accountMutation = useMutation((data: { id: number; name: string }) => accountsService.updateAccountName(data.id, data.name), {
    onSuccess: () => {
      message.success("Successfully edited account name.");
      reloadAccounts();
    },
    onError: () => {
      message.error("An error occured during name update.");
    }
  });

  //////////

  ///////// Handlers

  const onMonthSelection = (value: moment.Moment | null) => {
    setSelectedDate(value);
    if (!value) setSelectedFormat(null);
  };
  const handleFormatChange = (e: RadioChangeEvent) => setSelectedFormat(e.target.value);

  const handleDownload = async () => {
    try {
      if (selectedFormat && selectedDate) {
        message.loading({ content: "Fetching statements...", key: "statements", duration: 0 });
        if (selectedRowKeys.length === 1 && selectedFormat === "pdf") {
          const file = await accountsService.getBankStatement(
            { id: selectedRowKeys[0].toString(), startDate: selectedDate.startOf("month").format("MM/DD/YYYY") },
            selectedFormat
          );
          const account = accounts.filter((item) => item.accountID === selectedRowKeys[0])[0];
          const fileName = `Bank Statement`;
          // (${account.accountNumber})${account.accountName}-Bank Statement.${selectedFormat === "pdf" ? "pdf" : "xlsx"}
          generateDownload(file, fileName, selectedFormat);
        } else {
          const statements = await accountsService.getMultipleBankStatements(
            {
              startDate: selectedDate.startOf("month").format("MM/DD/YYYY"),
              accountIDs: selectedRowKeys.sort((a, b) => parseInt(a.toString()) - parseInt(b.toString()))
            },
            selectedFormat
          );
          const fileName = `Multiple Bank Statements - ${selectedDate.format("MMMM-YY")}.${selectedFormat === "pdf" ? "zip" : "xlsx"}`;
          generateDownload(statements, fileName, selectedFormat);
        }

        message.success({ content: "Success", key: "statements" });
      }
    } catch (error) {
      message.error({ content: error, key: "statements" });
    }
  };

  const isEditing = (record: Account) => record.accountID === editingKey;

  const handleEdit = (record: Partial<Account>) => {
    form.setFieldsValue({ accountName: record.accountName });
    setEditingKey(record.accountID);
  };

  const handleCancel = () => {
    setEditingKey(undefined);
  };

  const handleSave = async (key: number | undefined) => {
    try {
      const row = (await form.validateFields()) as Account;
      if (!!key) accountMutation.mutate({ id: key, name: row.accountName });
      setEditingKey(undefined);
    } catch (errInfo) {
      message.error(`Validate Failed: ${errInfo}`);
    }
  };

  /////////

  //////// Column definitions for editing

  const actionColumn = [
    {
      title: "",
      dataIndex: "actions",
      editable: false,
      width: 60,
      render: (_: any, record: Account) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Popconfirm title="Are you sure you want to change name of this account?" onConfirm={() => handleSave(record.accountID)}>
              <SaveOutlined style={{ fontSize: "1rem" }} />
            </Popconfirm>
            <Popconfirm title="Cancel editing?" onConfirm={handleCancel}>
              <CloseOutlined style={{ fontSize: "1rem" }} />
            </Popconfirm>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditOutlined disabled={editingKey !== record.accountID} onClick={() => handleEdit(record)} style={{ fontSize: "1rem" }} />
          </div>
        );
      }
    }
  ];

  const mergedColumns = [...tableColumns, ...actionColumn].map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Account) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const [typeAccount, setTypeAccount] = useState<string>("SMBC");

  const [accountsData, setAccountsData] = useState<any[]>([]);

  useEffect(() => {
    const external: number = typeAccount === "SMBC" ? 0 : 1;

    let dataAccounts: any[] = [];
    if (accounts) {
      dataAccounts = accounts.filter((a: any) => a.IsExternalBank === external);
    }
    setAccountsData(dataAccounts);
  }, [accounts, typeAccount]);

  return (
    <>
      <Steps direction={windowSize.width < 800 ? "vertical" : "horizontal"}>
        <Step
          status={currentStatuses[0]}
          title="Select Month"
          icon={<SelectOutlined />}
          description={
            <div className={style.descriptionWrapper}>
              <DatePicker
                onChange={onMonthSelection}
                defaultValue={selectedDate!}
                picker="month"
                format="MMMM-YY"
                size="large"
                placeholder="Select month..."
                style={{ width: 200 }}
                disabledDate={(date) => date > moment() || date < moment().add(-13, "month")}
              />
            </div>
          }
        />
        <Step
          status={currentStatuses[1]}
          title="Select File Format"
          icon={<FileUnknownOutlined />}
          description={
            <div className={style.descriptionWrapper}>
              <Group
                value={selectedFormat}
                size="large"
                buttonStyle="solid"
                style={{ width: 300 }}
                onChange={handleFormatChange}
                disabled={currentStatuses[0] !== "finish"}
              >
                <RadioButton value="pdf">
                  PDF <FilePdfOutlined />
                </RadioButton>
                <RadioButton value="excel">
                  <FileExcelOutlined /> Excel
                </RadioButton>
              </Group>
            </div>
          }
        />
        <Step
          status={currentStatuses[2]}
          title="Download"
          icon={<DownloadOutlined />}
          description={
            <div className={style.descriptionWrapper}>
              <Button
                size="large"
                type="primary"
                icon={<CloudDownloadOutlined />}
                disabled={currentStatuses[1] !== "finish" || !selectedRowKeys.length}
                onClick={handleDownload}
              >
                Download
              </Button>
            </div>
          }
        />
      </Steps>
      <Row>
        <Col xs={24} md={20} lg={16} xl={12}>
          <Form form={form} component={false}>
            <Row style={{ marginBottom: 15, margin: "1rem 1rem 1rem 1rem" }}>
              <Col span={24} style={{ display: "flex" }}>
                <h3 style={{ marginRight: 15 }}>Account type:</h3>

                <Group
                  options={["SMBC", "Non SMBC"]}
                  value={typeAccount}
                  size="large"
                  buttonStyle="solid"
                  optionType="button"
                  onChange={(e) => setTypeAccount(e.target.value)}
                />
              </Col>
            </Row>
            <BankStatementTable
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              data={accountsData}
              loading={loading}
              columns={mergedColumns}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};
