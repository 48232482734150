import { callApiInstance } from "./api";
import { DownloadFormat, MultipleStatementsParams, StatementParams } from "../types/account";
import { DateParams } from "../types/transactions";

class AccountsService {
  async getAllAccounts() {
    return await callApiInstance({ url: "/Accounts" });
  }

  async getAccountsTotals(data: DateParams, downloadFormat?: DownloadFormat) {
    const { startDate, endDate } = data;
    return await callApiInstance({
      url: `/Accounts/Totals${downloadFormat ? (downloadFormat === "pdf" ? "/Pdf" : "/Excel") : ""}${startDate || endDate ? "?" : ""}${
        startDate ? "startDate=" + startDate : ""
      }${endDate ? (startDate ? "&endDate=" + endDate : "endDate=" + endDate) : ""}`,
      responseType: downloadFormat ? "arraybuffer" : "json"
    });
  }

  async getBankStatement(data: StatementParams, downloadFormat?: DownloadFormat) {
    const { startDate, id, filterValue } = data;
    return await callApiInstance({
      url: `/Accounts/${id}/BankStatement${downloadFormat ? (downloadFormat === "pdf" ? "Pdf" : "Excel") : ""}${
        startDate ? "?startDate=" + startDate : ""
      }${filterValue ? "&filterValue=" + filterValue : ""}`,
      responseType: downloadFormat ? "arraybuffer" : "json"
    });
  }

  async getMultipleBankStatements(data: MultipleStatementsParams, downloadFormat?: DownloadFormat) {
    const { startDate, accountIDs } = data;
    return await callApiInstance({
      url: `/Accounts/Multiple/BankStatement${downloadFormat ? (downloadFormat === "pdf" ? "Pdf" : "Excel") : ""}${
        startDate ? "?startDate=" + startDate : ""
      }${accountIDs.length ? "&accounts=" + accountIDs.join(",") : ""}`,
      responseType: downloadFormat ? "arraybuffer" : "json"
    });
  }

  async getCashMovement(data: StatementParams & { balanceType?: string }) {
    const { startDate, id, balanceType } = data;
    return await callApiInstance({
      url: `/CashMovement/${id}${startDate ? "?startDate=" + startDate : ""}${balanceType ? "&balanceType=" + balanceType : ""}`
    });
  }

  async updateAccountName(id: number, name: string) {
    return await callApiInstance({
      url: `/Accounts/${id}/Edit`,
      method: "POST",
      data: name,
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}
export const accountsService = new AccountsService();
