import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./services/msal-config";
import './styles.css'


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <MsalProvider instance={new PublicClientApplication(msalConfig)}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MsalProvider>
);
