import { FC, useContext, useEffect, useState } from "react";
import { Row, Col, Table, message, Divider, Popconfirm, Space, Tooltip, Button } from "antd";
import { CreateCreditFacility, CreateProject, CreditFacility, EditProject, Project } from "../../types/admin-management";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { projectsService } from "../../services/projects";
import { creditFacilitiesService } from "../../services/credit-facilities";
import { glCodesService } from "../../services/gl-codes";
import { MutationType } from "../../types";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { ModalOptions } from "../../layout";
import { AdminManagementModal } from "./components/AdminManagementModal/AdminManagementModal";
import { DataContext } from "../../contexts/DataContext";
import { CreateGlCode } from "../../types/gl-code";

interface Props {}

const types = {
  project: "Project",
  facility: "Credit Facility",
  glCode: "GL Code"
};

type SelectedForm = "project" | "facility" | "glCode";

const projectColumns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Account",
    dataIndex: "accountName",
    render: (text: number) => (text ? text : "-Temporary-")
  }
];

const glCodeColumns: ColumnsType<any> = [
  {
    title: "Account Name",
    dataIndex: "account_name"
  },
  {
    title: "Gl Code",
    dataIndex: "gl_code"
  },
  {
    title: "Normal Balance",
    dataIndex: "normal_balance"
  }
];
const creditColumns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Rate",
    dataIndex: "rate"
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (text: number) => "$" + convertWithCommas(text)
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    render: (text: number) => moment(text).format("MM/DD/YYYY")
  }
];

export const AdminManagement: FC<Props> = () => {
  const { projects, refetchProjects, areProjectsLoading, accounts } = useContext(DataContext);
  const [options, setOptions] = useState<ModalOptions>({ visible: false, payload: null });
  const { createProject, editProject, deleteProject } = projectsService;
  const { createFacility, editFacility, deleteFacility } = creditFacilitiesService;
  const { createGlCode, editGlcode, deleteGlCode } = glCodesService;

  const {
    data: creditFacilities,
    isFetching: areCreditFacilitiesFetching,
    isLoading: areCreditFacilitiesLoading,
    refetch: refetchFacilities
  } = useQuery(["credit-facilities"], () => creditFacilitiesService.getAllFacilities(false), { initialData: [] });

  const {
    data: glCodes,
    isFetching: areGlCodesFetching,
    isLoading: areGlCodesLoading,
    refetch: refetchGlCodes
  } = useQuery(["gl-codes"], () => glCodesService.getAllGlCodes(), { initialData: [] });

  const { mutate: mutateProjects } = useMutation(
    ["projects-mutation"],
    ({ data, type, id }: { type: MutationType; id?: number; data?: CreateProject }) =>
      type === "create" ? createProject(data!) : type === "update" ? editProject({ id: id!, ...data! }) : deleteProject(id!),
    {
      onError: (error, { type }) => {
        closeModal();
        message.error(`An error ocurred while trying to ${type} project`);
      },
      onSuccess: (data, { type }) => {
        message.success(`Successfully ${type}d project`);
        closeModal();
        refetchProjects();
      }
    }
  );
  const { mutate: mutateFacilities } = useMutation(
    ["facilities-mutation"],
    ({ data, type, id }: { type: MutationType; id?: number; data?: CreateCreditFacility }) =>
      type === "create" ? createFacility(data!) : type === "update" ? editFacility(id!, data!) : deleteFacility(id!),
    {
      onError: (error, { type }) => {
        closeModal();
        message.error(`An error ocurred while trying to ${type} facility`);
      },
      onSuccess: (data, { type }) => {
        message.success(`Successfully ${type}d facility`);
        closeModal();
        refetchFacilities();
      }
    }
  );
  const { mutate: mutateGlCode } = useMutation(
    ["glCode-mutation"],
    ({ data, type, id }: { type: MutationType; id?: number; data?: CreateGlCode }) =>
      type === "create" ? createGlCode(data!) : type === "update" ? editGlcode(id!, data!) : deleteGlCode(id!),
    {
      onError: (error, { type }) => {
        closeModal();
        message.error(`An error ocurred while trying to ${type} GL Code`);
      },
      onSuccess: (data, { type }) => {
        message.success(`Successfully ${type}d GL Code`);
        closeModal();
        refetchGlCodes();
      }
    }
  );
  const handleModalOk = (
    formType: SelectedForm,
    { data, type, id }: { type: MutationType; id?: number; data?: CreateCreditFacility | CreateProject | CreateGlCode }
  ) => {
    closeModal();
    if (formType === "project") {
      const projectData = data as CreateProject;
      mutateProjects({ data: projectData, type, id });
    }
    if (formType === "facility") {
      const facilityData = data as CreateCreditFacility;
      mutateFacilities({ data: facilityData, type, id });
    }
    if (formType === "glCode") {
      const glCodeData = data as CreateGlCode;
      mutateGlCode({ data: glCodeData, type, id });
    }
  };
  const closeModal = () => setOptions({ visible: false, payload: null });

  const actionsColumn = (type: SelectedForm) => [
    {
      label: "Actions",
      key: "actions",
      width: "15%",
      render: (_: any, record: Project | CreditFacility) => {
        return (
          <Space>
            <Tooltip title={`Edit ${types[type]}`}>
              <EditOutlined
                style={{ fontSize: "1.2rem" }}
                onClick={() => setOptions({ visible: true, payload: { ...record, form: type } })}
              />
            </Tooltip>
            <Tooltip title={`Delete ${types[type]}`}>
              <Popconfirm
                title={`Are you sure you want to delete this ${types[type]}?`}
                onConfirm={() => {
                  switch (type) {
                    case "facility":
                      mutateFacilities({ id: record.id, type: "delete" });
                      break;
                    case "project":
                      mutateProjects({ id: record.id, type: "delete" });
                      break;

                    default:
                      mutateGlCode({ id: record.id, type: "delete" });
                      break;
                  }
                }}
              >
                <DeleteOutlined style={{ fontSize: "1.2rem" }} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      }
    }
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={10}>
        <Row style={{ marginBottom: "20px" }}>
          <Col style={{ width: "100%", marginBottom: "20px" }}>
            <Divider orientation="center">List of {"Projects"}</Divider>
            <Button icon={<PlusOutlined />} type="primary" onClick={() => setOptions({ visible: true, payload: { form: "project" } })}>
              Add {"Project"}
            </Button>
          </Col>
          <Col style={{ width: "100%", marginBottom: "20px", height: "40vh" }}>
            <Table
              rowKey="id"
              style={{ height: "100%", overflow: "auto" }}
              sticky
              bordered
              pagination={false}
              dataSource={projects.filter((item) => item.active)}
              columns={[...projectColumns, ...actionsColumn("project")]}
              loading={areProjectsLoading}
            />
          </Col>
        </Row>
      </Col>

      <Col span={14}>
        <Row style={{ marginBottom: "20px" }}>
          <Col style={{ width: "100%", marginBottom: "20px" }}>
            <Divider orientation="center">List of {"Credit Facilities"}</Divider>
            <Button icon={<PlusOutlined />} type="primary" onClick={() => setOptions({ visible: true, payload: { form: "facility" } })}>
              Add {"Credit Facility"}
            </Button>
          </Col>
          <Col style={{ width: "100%", marginBottom: "20px", height: "40vh" }}>
            <Table
              rowKey="id"
              style={{ height: "100%", overflow: "auto" }}
              sticky
              bordered
              pagination={false}
              dataSource={creditFacilities}
              columns={[...creditColumns, ...actionsColumn("facility")]}
              loading={areCreditFacilitiesLoading || areCreditFacilitiesFetching}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col style={{ width: "100%", marginBottom: "20px" }}>
            <Divider orientation="center">List of {"GL Codes"}</Divider>
            <Button icon={<PlusOutlined />} type="primary" onClick={() => setOptions({ visible: true, payload: { form: "glCode" } })}>
              Add {"GL Code"}
            </Button>
          </Col>
          <Col style={{ width: "100%", marginBottom: "20px", height: "40vh" }}>
            <Table
              style={{ height: "100%", overflow: "auto" }}
              sticky
              bordered
              pagination={false}
              dataSource={glCodes}
              columns={[...glCodeColumns, ...actionsColumn("glCode")]}
              loading={areGlCodesLoading || areGlCodesFetching}
            />
          </Col>
        </Row>
      </Col>
      <AdminManagementModal options={{ opts: options, setOptions }} handleModalOk={handleModalOk} />
    </Row>
  );
};
