
export const getCurrentDate = (objectResponse = false): { day: number, month: number, year: number } => {

    const currentDate = new Date();

    const day = Number(currentDate.getDate().toString().padStart(2, '0'));
    const month = Number((currentDate.getMonth() + 1).toString().padStart(2, '0'));
    const year = currentDate.getFullYear();

    return { day, month, year }

}
export const getCurrentDateFormatted = () => {

    const { day, month, year } = getCurrentDate()

    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;

}