export const federalBankHolyDays = [
  "New Year's Day",
  "Martin Luther King Jr. Day",
  "Washington's Birthday",
  "Memorial Day",
  "Juneteenth",
  "Independence Day",
  "Labor Day",
  "Columbus Day",
  "Veterans Day",
  "Thanksgiving Day",
  "Day after Thanksgiving Day",
  "Christmas Day",
  "New Year's Eve"
];
