import { ColumnsType } from "antd/lib/table";
import { convertWithCommas } from "../../../../helpers/convertWithCommas";

export interface DataType {
  key?: number;
  accountName: string;
  accountNumber: number;
  debit: number;
  credit: number;
  valiances?: number;
}

export const tableColumns: ColumnsType<DataType> = [
  {
    dataIndex: "accountID",
    key: "key",
    width: "5%",
    align: "center",
    render: (value) => (value ? value : "Total")
  },
  {
    title: "Account Name",
    dataIndex: "accountName",
    key: "accountName",
    width: "35%",
    render: (value) => (value !== "Total" ? value : "")
  },
  {
    title: "Account Number",
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: "15%",
    render: (value) => (value ? value : "")
  },
  {
    title: "Total Debits",
    dataIndex: "debit",
    key: "debit",
    width: "15%",
    render: (text: number) => "$ " + convertWithCommas(text)
  },
  {
    title: "Total Credits",
    dataIndex: "credit",
    key: "credit",
    width: "15%",
    render: (text: number) => "$ " + convertWithCommas(text)
  },
  {
    title: "Variances",
    dataIndex: "variances",
    key: "variances",
    width: "15%",
    render: (text: number, record) => "$ " + convertWithCommas(record.credit - record.debit)
  }
];
