import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Swal from "sweetalert2";

// export const apiInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
export const apiInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const callApiInstance = ({ url, method, data, responseType, headers, params }: AxiosRequestConfig) =>
  apiInstance({ url, method: method || "GET", data, responseType, headers, params })
    .then((response) => response.data)
    .catch((error) => {
      if (error instanceof AxiosError && error.response?.status === 401) {
        Swal.fire({
          title: "Session expired!",
          text: "Please refresh the browser",
          icon: "error",
          confirmButtonText: "refresh",
          allowOutsideClick: false
        }).then(({ isConfirmed }) => {
          if (!!isConfirmed) window.location.reload();
        });
        // window.location.reload();
      }
      if (error.response && error.response.data) throw error.response.data;
      else throw new Error("Error from API Call");
    });
