import { CashBalanceParams, AccountTransactionsParams, AccountDetailsQueryParams, SortersAndFilterParams } from "../types/transactions";
import { callApiInstance } from "./api";

class TransactionsService {
  async getCashBalance(data: CashBalanceParams, downloadFormat?: "pdf" | "excel") {
    const { balanceType, startDate, endDate } = data;
    return await callApiInstance({
      url: `/CashBalance${downloadFormat ? (downloadFormat === "pdf" ? "/pdf" : "/Excel") : ""}?balanceType=${balanceType}${
        startDate ? "&startDate=" + startDate : ""
      }${endDate ? "&endDate=" + endDate : ""}`,
      responseType: downloadFormat ? "arraybuffer" : "json"
    });
  }
  async getAccountTransactions(data: AccountTransactionsParams, additionalQueries: AccountDetailsQueryParams) {
    const { id, startDate, endDate } = data;
    const { sortColumn, sortDirection, searchValue, pageNumber, pageSize, filterValue } = additionalQueries;
    return await callApiInstance({
      url: `/Accounts/${id}/Details${startDate || endDate ? "?" : ""}${startDate ? "startDate=" + startDate : ""}${
        endDate ? (startDate ? "&endDate=" + endDate : "endDate=" + endDate) : ""
      }${pageSize ? "&pageSize=" + pageSize : ""}${pageNumber ? "&pageNumber=" + pageNumber : ""}${
        searchValue ? "&searchValue=" + searchValue : ""
      }${sortColumn ? "&sortColumn=" + sortColumn : ""}${sortDirection ? "&sortDirection=" + sortDirection : ""}${
        filterValue ? "&filterValue=" + filterValue : ""
      }`
    });
  }
  async getAccountDetailsFile(fileType: "pdf" | "excel", params: SortersAndFilterParams & AccountTransactionsParams) {
    const { id, startDate, endDate, sortColumn, sortDirection, searchValue, filterValue } = params;
    return await callApiInstance({
      url: `/Accounts/${id}/${fileType === "pdf" ? "Pdf" : "Excel"}?${startDate ? "startDate=" + startDate : ""}${
        endDate ? (startDate ? "&endDate=" + endDate : "endDate=" + endDate) : ""
      }${searchValue ? "&searchValue=" + searchValue : ""}${sortColumn ? "&sortColumn=" + sortColumn : ""}${
        sortDirection ? "&sortDirection=" + sortDirection : ""
      }${filterValue ? "&filterValue=" + filterValue : ""}`,
      responseType: "arraybuffer"
    });
  }
}
export const transactionsService = new TransactionsService();
