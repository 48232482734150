import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { convertWithCommas } from "../../../../helpers/convertWithCommas";

export type CheckListType = {
  bankReferenceNumber: number;
  date: number;
  amount: number;
};

export const checkListColumns: ColumnsType<CheckListType> = [
  {
    title: "Check",
    dataIndex: "bankReferenceNumber",
    key: "bankReferenceNumber",
    align: "center",
    render: (text: number) => (text ? text : <i>No data</i>)
  },
  {
    title: "Issued",
    dataIndex: "date",
    key: "date",
    align: "center",
    render: (value: number) => moment(value).format("D-MMM-YY")
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text: number) => (text ? "$ " + convertWithCommas(text) : <i>No data</i>)
  }
];
