import { Button, Col, message, Row, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { RcFile } from "antd/lib/upload";

export const UploadFileComponent = ({
  uploadService,
  afterUploadEvent = undefined,
  onRemoveEvent = undefined,
  onBeforeUploadEvent = undefined,
  accept = "",
  maxCount = 1,
  toast = true,
  errorMsg = "Upload failed, please refresh the page and try again",
  successMsg = "All files were uploaded",
  startText,
  text
}: {
  uploadService: (formData: FormData) => Promise<any>;
  afterUploadEvent?: (value: any) => any;
  onRemoveEvent?: (file: UploadFile<any>) => any;
  onBeforeUploadEvent?: (file: UploadFile<any>) => any;
  accept?: string;
  maxCount: number;
  toast?: boolean;
  errorMsg?: string;
  successMsg?: string;
  startText: string;
  text: string;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file as RcFile);
    });
    setUploading(true);

    try {
      const upload = await uploadService(formData);
      if (toast) message.success(successMsg);
      setFileList([]);
      if (afterUploadEvent) afterUploadEvent(upload);
    } catch (error) {
      if (toast) message.error(errorMsg);
      if (afterUploadEvent) afterUploadEvent(error);
    }
    setUploading(false);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const auxFile = file;
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      if (onRemoveEvent) onRemoveEvent({ ...auxFile });
    },
    beforeUpload: (file) => {
      setFileList((fileList) => [...fileList, file]);
      if (onBeforeUploadEvent) onBeforeUploadEvent(file);
      return false;
    },
    fileList,
    accept,
    multiple: maxCount > 1 ? true : false,
    maxCount: maxCount > 1 ? maxCount : 1
  };

  return (
    <>
      <Row justify="end">
        <Col span={24} className="col-justify-end">
          {fileList.length !== 0 && (
            <Button type="primary" onClick={handleUpload} disabled={fileList.length === 0} loading={uploading} style={{ marginTop: 16 }}>
              {uploading ? "Uploading" : startText}
            </Button>
          )}
        </Col>
        <Col span={24} className="col-justify-end">
          <Upload {...props}>{fileList.length === 0 && <Button icon={<UploadOutlined />}>{text}</Button>}</Upload>
        </Col>
      </Row>
    </>
  );
};
