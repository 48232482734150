


import {
    OutstandingMonthlyRecord
} from "../types/outstanding";
import { callApiInstance } from "./api";



const rootRoute = "/OutstandingCheck";
class OutstandingService {
    private rootRoute: string;
    constructor() {
        this.rootRoute = "/OutstandingCheck";
    }
    async getOutstandingCheck(
        // displayMode: OutstandingMonthlyMode,
        startDate?: string,
        endDate?: string,
        searchTerm?: string,
        isCleared?: boolean
    ): Promise<OutstandingMonthlyRecord[]> {
        return await callApiInstance({
            url: `${this.rootRoute}?isCleared=${isCleared === null ? '' : isCleared}&startDate=${startDate ? startDate : ''}&endDate=${endDate ? endDate : ''}&searchTerm=${searchTerm ? searchTerm : ''}`
        });
    }

    async uploadChecks(data: FormData) {
        return await callApiInstance({ url: this.rootRoute + "/upload", method: "POST", data });
    }


}
export const outstandingService = new OutstandingService();



export const uploadChecks = async (data: FormData) => {
    return await callApiInstance({
        url: `${rootRoute}/upload`, method: "POST", data
    });

}
export const editOutstanding = async (data: OutstandingMonthlyRecord): Promise<OutstandingMonthlyRecord> => {
    return await callApiInstance({
        url: `${rootRoute}`, method: "PUT", data
    });
}