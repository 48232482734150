import React, { useState } from "react";
import { Popconfirm, Space, Table } from "antd";
import { ColumnTypes, EditableTablePropsFC } from "./interfaces";
import styles from "./EditableTable.module.css";

import { EditableRow } from "./components/EditableRow";
import { EditableCell } from "./components/EditableCell";
import { SaveOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";

export const EditableTable: React.FC<EditableTablePropsFC> = ({
  data,
  defaultColumns,
  tableKey,
  onSave,
  isLoading,
  pagination = undefined,
  size = "small",
  sticky = false,
  scroll = undefined,
  summary = undefined
}) => {
  const [editingRecord, setEditingRecord] = useState<any | undefined>(undefined);
  const [editedRow, setEditRow] = useState<any | undefined>(undefined);

  const handleSave = (value: any) => {
    setEditRow(() => {
      if (!!editedRow) return { ...editedRow, ...value };
      return { ...value };
    });
  };

  const checkChanges = () => {
    const check = { normal: JSON.stringify(editingRecord), changed: JSON.stringify({ ...editingRecord, ...editedRow }) };

    return check.changed === check.normal;
  };

  const mainHandleSave = (record: any) => {
    if (editingRecord && editedRow) {
      onSave({ ...record, ...editedRow });
    }
    setEditingRecord(undefined);
    setEditRow(undefined);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        handleSave,
        editingRecord
      })
    };
  });

  const mainCancel = () => {
    setEditingRecord(undefined);
    setEditRow(undefined);
  };
  const mainEdit = (record: any) => {
    if (record) {
      setEditingRecord(record);
      setEditRow(undefined);
    }
  };

  const actionsColumn = [
    {
      title: "",
      dataIndex: "id",
      align: "right",
      width: "8%",
      key: "id",
      render: (_: any, record: any) => {
        let editable = editingRecord?.id === record.id;
        return editable ? (
          <Space key={record.id} size="small">
            {editedRow && !checkChanges() ? (
              <>
                <Popconfirm placement="left" title={`Save the record?`} onConfirm={() => mainHandleSave(record)}>
                  <SaveOutlined style={{ fontSize: "1.2rem", color: "green" }} />
                </Popconfirm>
                <Popconfirm placement="left" title="Are you sure you want to cancel editing this record?" onConfirm={mainCancel}>
                  <CloseOutlined style={{ fontSize: "1.2rem", color: "red" }} />
                </Popconfirm>
              </>
            ) : (
              <>
                <SaveOutlined style={{ fontSize: "1.2rem", color: "green" }} onClick={mainCancel} />
                <CloseOutlined style={{ fontSize: "1.2rem", color: "red" }} onClick={mainCancel} />
              </>
            )}
          </Space>
        ) : (
          <Space key={record.id}>
            {!editingRecord || !editedRow || checkChanges() ? (
              <>
                <EditOutlined
                  style={{ fontSize: "1.2rem" }}
                  disabled={editingRecord !== undefined}
                  onClick={() => mainEdit(record)}
                ></EditOutlined>
              </>
            ) : (
              <Popconfirm placement="left" title="please finish editing the other record" showCancel={false}>
                <EditOutlined style={{ fontSize: "1.2rem" }} disabled={editingRecord !== undefined} />
              </Popconfirm>
            )}
          </Space>
        );
      }
    }
  ];

  const tableColumns = [...columns, ...actionsColumn];

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => `${styles.editableRow} ${styles.stripedRow}`}
        bordered
        key={tableKey}
        rowKey={tableKey}
        dataSource={data}
        columns={tableColumns as ColumnTypes}
        loading={isLoading}
        pagination={pagination}
        size={size}
        sticky={sticky}
        scroll={scroll}
        summary={summary}
      />
    </div>
  );
};
