import { useContext, useState } from "react";
import { EditableContext } from "../../../contexts/EditableContext";
import { EditableCellProps } from "../interfaces";
import { DatePicker, Input, InputNumber } from "antd";
import moment from "moment";
import { formattedDate } from "../../../helpers/formattedDate";

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  editingRecord = undefined,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const handleDate = async (value: moment.Moment | null) => {
    if (value) save(formattedDate(value.toISOString()).changed);
    else save(null);
  };

  const save = async (value: any) => {
    try {
      const auxRow = { [dataIndex]: value };
      toggleEdit();
      handleSave({ ...auxRow });
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode =
      editingRecord?.id === (record as any).id ? (
        <>
          {type === "text" ? (
            <Input defaultValue={record[dataIndex]} onChange={(event) => save(event.target.value)} />
          ) : type === "date" ? (
            <DatePicker onChange={(value) => handleDate(value)} defaultValue={record[dataIndex] ? moment(record[dataIndex]) : undefined} />
          ) : type === "number" ? (
            <InputNumber />
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};
