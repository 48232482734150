import { FC } from "react";
import { Table, TableProps } from "antd";
import { detailsTableColumns, DetailsType } from "./columns";
import { FilterValue } from "antd/es/table/interface";
import styles from "./DetailsTable.module.css";
import { convertWithCommas } from "../../helpers/convertWithCommas";

const { Summary } = Table;

export interface TableSortersAndFilters {
  filters: FilterValue | null;
  field: string | null;
  direction: "ascend" | "descend" | null;
}

export interface Totals {
  totalDebit: number;
  totalCredit: number;
  totalCurrentBalance?: number;
}

interface Props {
  data: DetailsType[];
  sorters: string[];
  isLoading: boolean;
  totalTransactions: number;
  currentPage: number;
  currentPageSize: number;
  currentSortersAndFilters: TableSortersAndFilters;
  refetch?: any;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPageSize: React.Dispatch<React.SetStateAction<number>>;
  setCurrentSortersAndFilters: React.Dispatch<React.SetStateAction<TableSortersAndFilters>>;
}

export const DetailsTable: FC<Props> = ({
  data,
  sorters,
  isLoading,
  totalTransactions,
  currentPage,
  currentPageSize,
  setCurrentPage,
  setCurrentPageSize,
  setCurrentSortersAndFilters
}) => {
  const onChange: TableProps<DetailsType>["onChange"] = (pagination, filters, sorter, extra) => {
    setCurrentSortersAndFilters({
      filters: filters.description,
      //@ts-ignore
      field: !!sorter.field ? sorter.field : null,
      //@ts-ignore
      direction: !!sorter.order ? sorter.order : null
    });
    if (extra.action === "filter") {
      setCurrentPage(1);
    }
  };

  const pageSizeOptions = [10, 15, 20, 50, 100, 500, totalTransactions].sort((a, b) => a - b);

  return (
    <Table
      columns={detailsTableColumns(sorters)}
      dataSource={data ? data : []}
      size="small"
      bordered
      scroll={{ x: 1300 }}
      pagination={{
        total: totalTransactions,
        defaultPageSize: totalTransactions,
        current: currentPage,
        onChange: (page) => setCurrentPage(page),
        showSizeChanger: true,
        pageSize: currentPageSize,
        onShowSizeChange: (current: number, size: number) => {
          setCurrentPageSize(size ? size : totalTransactions);
          setCurrentPage(1);
        },
        pageSizeOptions: pageSizeOptions.slice(0, pageSizeOptions.findIndex((val) => val >= totalTransactions) + 1),
        position: ["bottomRight", "topRight"]
      }}
      loading={isLoading}
      onChange={onChange}
      rowClassName={`${styles.stripedRow}`}
      rowKey={"id"}
      sticky
      summary={(data) => {
        let totals: Totals = data.reduce(
          (prev: Totals, curr: DetailsType) => {
            return {
              totalDebit: curr.type === "DB" && curr.amount ? (prev.totalDebit += curr.amount) : prev.totalDebit,
              totalCredit: curr.type === "CR" && curr.amount ? (prev.totalCredit += curr.amount) : prev.totalCredit
            };
          },
          { totalDebit: 0, totalCredit: 0 }
        );
        if (data.length) totals = { ...totals, totalCurrentBalance: data[data.length - 1].runningTotal };
        return (
          <Summary fixed="top">
            <Summary.Row style={{ fontWeight: 700, background: "#fafafa" }}>
              <Summary.Cell index={0}>Total</Summary.Cell>
              <Summary.Cell index={1}></Summary.Cell>
              <Summary.Cell index={2}></Summary.Cell>
              <Summary.Cell index={3}>{"$" + convertWithCommas(totals.totalDebit)}</Summary.Cell>
              <Summary.Cell index={4}>{"$" + convertWithCommas(totals.totalCredit)}</Summary.Cell>
              <Summary.Cell index={4}>{"$" + convertWithCommas(totals.totalCurrentBalance)}</Summary.Cell>
              <Summary.Cell index={5}></Summary.Cell>
            </Summary.Row>
          </Summary>
        );
      }}
    />
  );
};
