import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { Row, Col, message, Input } from "antd";
import { IManipulateReportsFilters, IManipulateReportsOptions, ManipulateReports } from "../../components";
import { Account, DownloadFormat } from "../../types/account";
import { AccountModal } from "./components";
import moment, { isMoment } from "moment";
import { DataContext } from "../../contexts/DataContext";
import { CashManagementModalOptions } from "../../types";
import { cashManagementService } from "../../services/cash-management";
import { generateDownload } from "../../helpers/generateDownload";
import { BankStatementTable } from "../BankStatement/components";
import { tableColumns } from "./components";
const { Search } = Input;

interface Props {}

const options: IManipulateReportsOptions = {
  displayOptions: [{ value: "monthly", label: "Monthly" }],
  rangeOptions: [
    { value: "30-days", label: "Next 30 Days" },
    { value: "yesterday", label: "Yesterday" },
    { value: "today", label: "Today" },
    { value: "this-month", label: "This Month" },
    { value: "next-month", label: "Next Month" },
    { value: "range", label: "Range" }
  ],
  searchOption: false,
  downloadOptions: ["excel"]
};

export const Statements: FC<Props> = () => {
  const [filters, setFilters] = useState<IManipulateReportsFilters>({
    displayMode: "monthly",
    downloadType: "excel",
    rangeOptionValue: "30-days",
    searchValue: "",
    range: [moment(), moment().add(30, "day")]
  });
  const { accounts, loading: accountsLoading } = useContext(DataContext);
  const [searchedAccounts, setSearchedAccounts] = useState<Account[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [modalOptions, setModalOptions] = useState<CashManagementModalOptions>({ visible: false, payload: undefined });

  const onFiltersChange = (filters: IManipulateReportsFilters) => {
    setFilters(filters);
  };

  const handleDownload = async (value: DownloadFormat) => {
    try {
      if (selectedRowKeys.length === 0) {
        message.info({ content: "Please select at least 1 account", key: "downloading" });
        return;
      }

      message.loading({ content: "Downloading file...", key: "downloading" });
      const params = {
        id: selectedRowKeys,
        startDate: isMoment(filters.range)
          ? filters.range.format("MM/DD/YYYY")
          : (filters.range as moment.Moment[])[0].format("MM/DD/YYYY"),
        endDate: isMoment(filters.range) ? filters.range.format("MM/DD/YYYY") : (filters.range as moment.Moment[])[1].format("MM/DD/YYYY")
      };
      const file = await cashManagementService.downloadCashManagementRecords(params.id as number[], params.startDate, params.endDate);
      generateDownload(file, `Multi - Cash Management for ${params.startDate} - ${params.endDate}.xlsx`, "excel");
      message.success({ content: "Success", key: "downloading" });
    } catch (err) {
      message.error({ content: err, key: "downloading" });
    }
  };

  const mergedColumns = [...tableColumns].map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Account) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title
      })
    };
  });

  const [debounceSearch, setDebounceSearch] = useState<null | NodeJS.Timeout>(null);

  const filterAccounts = (value: string) => {
    const filteredAccounts = accounts.filter((account) => JSON.stringify(account).includes(value));
    setSearchedAccounts(filteredAccounts);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    if (debounceSearch) {
      clearTimeout(debounceSearch);
      setDebounceSearch(null);
    }
    const timer = setTimeout(() => {
      filterAccounts(value);
    }, 600);
    setDebounceSearch(timer);
  };

  useEffect(() => {
    filterAccounts(searchValue);
  }, [accounts]);

  return (
    <div>
      <Row justify="space-between" gutter={[60, 0]}>
        <Col span={14}>
          <ManipulateReports options={options} filters={filters} onFiltersChange={onFiltersChange} onDownloadClick={handleDownload} />
        </Col>
        <Col span={14} style={{ marginTop: "20px" }}>
          <Search
            style={{ width: "60%" }}
            size="large"
            value={searchValue}
            onChange={(element: ChangeEvent<HTMLInputElement>) => onSearch(element.target.value)}
          />
        </Col>
        <Col span={18} style={{ paddingLeft: "30px", marginTop: "10px" }}>
          <BankStatementTable
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            data={searchedAccounts}
            loading={accountsLoading}
            columns={mergedColumns}
          />
        </Col>
      </Row>
      <AccountModal options={modalOptions} setOptions={setModalOptions} />
    </div>
  );
};
