import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Form, FormInstance, Input, Button, Select } from "antd";
import { CreateProject } from "../../../../types/admin-management";
import moment from "moment";
import { DataContext } from "../../../../contexts/DataContext";

interface IProjectForm {
  form: FormInstance<CreateProject>;
  onFinish: ((values: CreateProject) => void) | undefined;
  currentValues: string;
}

const { Item: FormItem } = Form;

export const ProjectForm: FC<IProjectForm> = ({ form, onFinish, currentValues }) => {
  const { accounts, loading, projects } = useContext(DataContext);
  const currentAccountIDs = useMemo(() => projects.filter((item) => item.accountID).map((item) => item.accountID), [projects]);
  const [auxAccounts] = useState([
    { label: "-Temporary-", value: null },
    ...accounts.map((item) => ({ label: item.accountName + ` (${item.accountNumber})`, value: item.accountID }))
  ]);

  const [sameValue, setSameValue] = useState(true);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setSameValue(true);
    setWasSubmitted(false);
    setIsDisabled(false);
  }, [currentValues]);

  return (
    <Form
      name="project-form"
      labelCol={{ span: 6 }}
      labelAlign="left"
      form={form}
      initialValues={{ name: "", accountID: null }}
      onFinish={(values) => {
        if (onFinish) {
          setIsDisabled(true);
          onFinish(values);
        }
      }}
      onValuesChange={() => {
        setSameValue(currentValues === JSON.stringify(form.getFieldsValue()));
      }}
    >
      <FormItem label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </FormItem>
      <FormItem
        label="Account"
        name="accountID"
        rules={
          [
            // { required: true }
          ]
        }
      >
        <Select
          options={
            auxAccounts
            // accounts
            // // .filter((item) => !currentAccountIDs.includes(item.accountID!))
            //   .map((item) => ({ label: item.accountName + ` (${item.accountNumber})`, value: item.accountID }))
          }
          loading={loading}
        />
      </FormItem>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Button
          disabled={isDisabled}
          type="primary"
          style={{ width: "200px" }}
          onClick={() => {
            setWasSubmitted(true);
            if (!sameValue) form.submit();
          }}
        >
          Submit
        </Button>
        {(sameValue && wasSubmitted && <div style={{ height: "22px", color: "#ff4d4f" }}>values did not change</div>) || (
          <div style={{ height: "22px" }}></div>
        )}
      </div>
    </Form>
  );
};
