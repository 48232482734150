export const formattedDate = (date: string): { changed: string; normal: string } => {
    let auxDate = ''

    for (let i = 0; i < date.length; i++) {
        if (date[i] !== 'T')
            auxDate += date[i]
        else break
    }
    return {
        changed: `${auxDate}T00:00:00`, normal: `${auxDate}`
    }
}