import { Row, Col, Table, Select, Form, message } from "antd";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { IManipulateReportsFilters, IManipulateReportsOptions, ManipulateReports } from "../../components";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import { useQuery } from "@tanstack/react-query";
import { outstandingService, uploadChecks, editOutstanding } from "../../services/outstanding";
import { OutstandingMonthlyRecord } from "../../types/outstanding";
import { UploadFileComponent } from "../../components/UploadFile/UploadFileComponent";
import { useColumnsOutstanding } from "./hooks/useColumnsOutstanding";
import { EditableTable } from "../../components/EditableTable/EditableTable";

interface Props {}
const options: IManipulateReportsOptions = {
  rangeOptions: [
    { value: "all", label: "All" },
    { value: "this-month", label: "This Month" },
    { value: "last-month", label: "Last Month" }
  ],
  searchOption: true
};

export const OutstandingCheckList: FC<Props> = () => {
  const [filters, setFilters] = useState<IManipulateReportsFilters>({
    displayMode: "monthly",
    downloadType: "excel",
    rangeOptionValue: "all",
    searchValue: "",
    range: moment()
  });
  const { defaultColumns } = useColumnsOutstanding();

  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState<"all" | "cleared" | "uncleared">("all");
  const {
    // displayMode,
    range,
    selection,
    rangeOptionValue,
    searchValue
  } = filters;

  /**========================================================================
   *                           Table's data
   *========================================================================**/

  const {
    data: auxRecords,
    isLoading,
    isFetching
  } = useQuery(
    [
      "outstanding-check-list",
      rangeOptionValue === "all" ? null : (range as moment.Moment[])[0].format("MM/DD/YYYY"),
      rangeOptionValue === "all" ? null : (range as moment.Moment[])[1].format("MM/DD/YYYY"),
      searchValue,
      selectedFilter === "all" ? null : selectedFilter === "cleared"
    ],
    (context) =>
      outstandingService.getOutstandingCheck(
        context.queryKey[1] as string | undefined,
        context.queryKey[2] as string | undefined,
        context.queryKey[3] as string,
        context.queryKey[4] as boolean | undefined
      ),

    {
      initialData: []
    }
  );
  const [records, setRecords] = useState<OutstandingMonthlyRecord[]>([]);

  useEffect(() => {
    setRecords(auxRecords);
  }, [auxRecords]);

  /**============================================
   *               new prop table
   *=============================================**/
  const [loadingTable, setLoadingTable] = useState(false);

  const onSave = async (record: OutstandingMonthlyRecord) => {
    setLoadingTable(true);
    try {
      const newValue = await editOutstanding({ ...record });

      const auxData: OutstandingMonthlyRecord[] = records.map((auxRecord) => {
        if (auxRecord.id === newValue.id) return newValue;
        else return auxRecord;
      });
      setRecords([...auxData]);

      message.success("Record saved");
    } catch (error) {
      console.error(error);
      message.error("Something was wrong, please refresh the page and try again");
    }
    setLoadingTable(false);
  };

  /*============================ END OF SECTION ============================*/
  const onFiltersChange = (filters: IManipulateReportsFilters) => {
    setFilters(filters);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={16}>
        <ManipulateReports options={options} filters={filters} onFiltersChange={onFiltersChange} />
      </Col>
      <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
        <UploadFileComponent
          startText="Start upload"
          text="Upload files"
          maxCount={5}
          uploadService={uploadChecks}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel text/plain"
        ></UploadFileComponent>
      </Col>
      <Col span={24} style={{ justifyContent: "space-between", display: "flex" }}>
        <Select value={selectedFilter} onChange={setSelectedFilter} style={{ width: 150 }} size="large">
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="cleared">Cleared</Select.Option>
          <Select.Option value="uncleared">Uncleared</Select.Option>
        </Select>
      </Col>
      <Col span={24}>
        <Form form={form} component={false}>
          <EditableTable
            data={records}
            defaultColumns={defaultColumns}
            tableKey={"id"}
            onSave={onSave}
            isLoading={isLoading || isFetching || loadingTable}
            pagination={false}
            sticky
            summary={(data: any) => {
              const totalAmount = data.reduce((prev: any, curr: any) => (prev += curr.amount), 0);
              return (
                <Table.Summary key={2}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <strong>{"$" + convertWithCommas(totalAmount)}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
            // editingKey={editingKey}
          ></EditableTable>
        </Form>
      </Col>
    </Row>
  );
};
