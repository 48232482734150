import { Tag, Row, Col, Form, Select, DatePicker, Radio, Divider, RadioChangeEvent, Empty, Spin, message } from "antd";
import { FC, useContext, useState } from "react";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { Bar, BarChart, CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useWindowSize } from "../../helpers/useWindowSize";
import { useQuery } from "@tanstack/react-query";
import { accountsService } from "../../services/accounts";
import { DataContext } from "../../contexts/DataContext";

const { Item } = Form;
const { Option } = Select;
const { Group, Button: RadioButton } = Radio;

interface ChartData {
  day: string;
  amount: number;
  amount2?: number;
  amount3?: number;
}

interface CashMovement {
  id: number;
  accountID: number;
  date: string;
  balance: number;
  credit: number;
  debit: number;
  variance: number;
}

export const CustomLabel = () => (
  <g>
    <foreignObject x={0} y={"85%"} width={100} height={25}>
      <b>$(milion)</b>
    </foreignObject>
  </g>
);

export const DailyCashBalance: FC = () => {
  const { startDate, setStartDate } = useContext(DataContext);
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(startDate.clone());
  const [selectedRadio, setSelectedRadio] = useState<string>("Monthly");
  // Balance , Debit / Credit
  const [chartData, setChartData] = useState<ChartData[][]>([]);
  const { width } = useWindowSize();
  const { loading, accounts } = useContext(DataContext);

  ///////////// Queries

  const { data: cashMovementData, isFetching } = useQuery<CashMovement[]>(
    ["cash-movement", selectedAccount, selectedMonth.startOf("month").format("MM/DD/YYYY"), selectedRadio],
    async () => {
      message.loading({ content: "Fetching data...", key: "fetching", duration: 0 });
      const response = await accountsService.getCashMovement({
        id: selectedAccount !== null ? selectedAccount?.toString() : "",
        startDate: selectedMonth.startOf("month").format("MM/DD/YYYY"),
        balanceType: selectedRadio
      });
      setChartData([generateChartData(response, "balance"), generateChartData(response, "debit/credit")]);
      return response;
    },
    {
      enabled: selectedAccount !== null && !!selectedMonth,
      onSuccess: () => message.success({ content: "Success", key: "fetching", duration: 3 }),
      onError: () => message.error({ content: "Success", key: "fetching", duration: 3 })
    }
  );

  ////////////

  ///////////// Handlers

  /////////////

  //////////// Helpers

  const generateChartData = (data: CashMovement[], type: "balance" | "debit/credit"): ChartData[] => {
    switch (type) {
      case "balance":
        return data.map((item) => {
          return { day: item.date, amount: item.balance };
        });
      case "debit/credit":
        return data.map((item) => {
          return { day: item.date, amount: item.variance, amount2: item.credit, amount3: item.debit * -1 };
        });
    }
  };

  const handleRadioChange = (e: RadioChangeEvent) => setSelectedRadio(e.target.value);

  ////////////

  return (
    <>
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={24}>
          <Tag style={{ fontSize: "1rem", padding: "0.5rem" }} icon={<CalendarOutlined />} color="processing">
            Today's date {moment().format("MMM, DD, YYYY")}
          </Tag>
        </Col>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={24}>
          <Group value={selectedRadio} size="large" buttonStyle="solid" onChange={handleRadioChange}>
            <RadioButton value="Monthly">Monthly</RadioButton>
            <RadioButton value="Quarterly">Quarterly</RadioButton>
            <RadioButton value="Yearly">Yearly</RadioButton>
          </Group>
        </Col>
      </Row>
      <Form layout="vertical" initialValues={{ month: selectedMonth }}>
        <Row>
          <Col xs={20} sm={6}>
            <Item name="accountNumber" label="Account Name" colon>
              <Select
                placeholder="Select account..."
                showSearch
                filterOption={(input, option) => (option?.children?.toString().toLowerCase().includes(input.toLowerCase()) ? true : false)}
                allowClear
                value={selectedAccount}
                onSelect={(val: number) => setSelectedAccount(val)}
                onClear={() => setSelectedAccount(null)}
                loading={loading}
                size="large"
              >
                <Option value={0}>All</Option>
                {accounts.map((item) => (
                  <Option value={item.accountID} key={item.accountID}>
                    {item.accountName} ({item.accountNumber})
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={24} sm={10} md={6}>
            <Item name="month" label={selectedRadio === "Monthly" ? "Month" : selectedRadio === "Quarterly" ? "Quarter" : "Year"} colon>
              <DatePicker
                format={selectedRadio === "Monthly" ? "MMMM YYYY" : undefined}
                picker={selectedRadio === "Monthly" ? "month" : "year"}
                disabled={selectedAccount === null}
                value={selectedMonth}
                onSelect={(val: moment.Moment) => {
                  setSelectedMonth(val);
                  setStartDate(val);
                }}
                size="large"
              />
            </Item>
          </Col>
        </Row>
      </Form>
      {cashMovementData ? (
        isFetching ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 300, width: "100%" }}>
            <Spin />
          </div>
        ) : cashMovementData.length ? (
          <Row gutter={[32, 32]}>
            {chartData[0].length ? (
              <Col span={24}>
                <Divider orientation="left">
                  {selectedRadio === "Monthly" ? "Last 30/31 Days " : selectedRadio === "Yearly" ? "Yearly " : "Quarterly "}Cash Balance
                </Divider>
                <ResponsiveContainer height={width < 600 ? 450 : 600}>
                  <BarChart data={chartData[0]} margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis
                      dataKey="day"
                      // type="number"
                      domain={[chartData[0][0].day, chartData[0][chartData[0].length - 1].day]}
                      // ticks={chartData.map((item) => item.day)}
                      tickFormatter={(value) =>
                        selectedRadio === "Quarterly"
                          ? "Q" + moment(value).quarter()
                          : moment(value).format(selectedRadio !== "Monthly" ? "MM-YY" : "MM-DD")
                      }
                    />

                    <YAxis
                      dataKey="amount"
                      label={width < 700 ? "" : <CustomLabel />}
                      tickFormatter={(value) => (value / 1000000).toString()}
                    />
                    <ReferenceLine y={0} stroke="#000000" />
                    <Tooltip
                      formatter={(value: number, name: string) => [
                        `$${value < 0 ? "(" + Math.abs(value).toLocaleString("en-US") + ")" : value.toLocaleString("en-US")}`,
                        "Balance"
                      ]}
                      labelFormatter={(label) =>
                        selectedRadio === "Quarterly"
                          ? "Q" + moment(label).quarter()
                          : moment(label).format(selectedRadio !== "Monthly" ? "MMMM-YY" : "MMMM DD YYYY")
                      }
                    />
                    <Bar dataKey="amount" fill="rgb(20,86,138)" />
                  </BarChart>
                </ResponsiveContainer>
              </Col>
            ) : null}
            {chartData[1].length ? (
              <Col span={24}>
                <Divider orientation="left">
                  {selectedRadio === "Monthly" ? "Daily(Net) " : selectedRadio === "Yearly" ? "Yearly " : "Quarterly "}Credit/Debit
                </Divider>
                <ResponsiveContainer height={width < 600 ? 450 : 600}>
                  <LineChart data={chartData[1]} margin={{ top: 10, right: 10, bottom: 10, left: 0 }}>
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis
                      dataKey="day"
                      // type="number"
                      domain={[chartData[1][0].day, chartData[1][chartData[1].length - 1].day]}
                      // ticks={chartData.map((item) => item.day)}
                      tickFormatter={(value) =>
                        selectedRadio === "Quarterly"
                          ? "Q" + moment(value).quarter()
                          : moment(value).format(selectedRadio !== "Monthly" ? "MM-YY" : "MM-DD")
                      }
                    />

                    <YAxis
                      dataKey="amount"
                      label={width < 700 ? "" : <CustomLabel />}
                      tickFormatter={(value) => (value / 1000000).toString()}
                    />
                    <ReferenceLine y={0} stroke="#000000" />
                    <Tooltip
                      formatter={(value: number, name: string, props: any) => {
                        if (name === "amount")
                          return [
                            `$${value < 0 ? "(" + Math.abs(value).toLocaleString("en-US") + ")" : value.toLocaleString("en-US")}`,
                            "Variance"
                          ];
                        else if (name === "amount2") return [value.toLocaleString("en-US"), "Credit"];
                        else
                          return [
                            `$${value < 0 ? "(" + Math.abs(value).toLocaleString("en-US") + ")" : value.toLocaleString("en-US")}`,
                            "Debit"
                          ];
                      }}
                      labelFormatter={(label) =>
                        selectedRadio === "Quarterly"
                          ? "Q" + moment(label).quarter()
                          : moment(label).format(selectedRadio !== "Monthly" ? "MMMM-YY" : "MMMM DD YYYY")
                      }
                    />
                    <Line type="linear" dataKey="amount" fill="rgb(20,86,138)" strokeWidth={3} dot={{ stroke: "rgb(20,86,138)" }} />
                  </LineChart>
                </ResponsiveContainer>
              </Col>
            ) : null}
          </Row>
        ) : (
          <Empty style={{ minHeight: 500 }} />
        )
      ) : (
        <Empty style={{ minHeight: 500 }} />
      )}
    </>
  );
};
