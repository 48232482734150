import { FC, useContext, useMemo } from "react";
import { Table, Button, TableColumnsType, Select, Space, Popconfirm, Tag } from "antd";
import { CreateDrawRequest, DrawRequest, DrawRequestApprovalType } from "../../../../types/draw-request";
import { convertWithCommas } from "../../../../helpers/convertWithCommas";
import { DataContext } from "../../../../contexts/DataContext";
import { Account } from "../../../../types/account";
import moment from "moment";
import { GLCode } from "../../../../types/cash-management";

const { Option } = Select;

interface IDrawRequestsTable {
  data: DrawRequest[];
  onAction: (type: DrawRequestApprovalType, drawRequest?: DrawRequest) => void;
  setData: (value: DrawRequest[], id: number) => void;
  loading: boolean;
  hideApproveAll: boolean;
  isTreasury: boolean;
}

export const DrawRequestsTable: FC<IDrawRequestsTable> = ({ data, onAction, setData, loading, hideApproveAll, isTreasury }) => {
  const { accounts, projects, areProjectsLoading, codes } = useContext(DataContext);

  const renderAccountOptions = (options: Account[]) => [
    <Option value={null}>-Temporary-</Option>,
    ...options.map((item) => (
      <Option value={item.accountID}>
        {item.accountName} ({item.accountNumber})
      </Option>
    ))
  ];

  const renderCodesOptions = (options: GLCode[]) =>
    codes.map((item) => (
      <Option value={item.id}>
        {item.gl_code} ({item.account_name})
      </Option>
    ));

  const columns: TableColumnsType<DrawRequest> = useMemo(
    () => [
      {
        dataIndex: "initiator",
        title: "Initiator",
        key: "initiator",
        width: "10%",
        align: "left"
      },
      {
        dataIndex: "dateAdded",
        title: "Submit Date",
        key: "dateAdded",
        width: "10%",
        align: "left",
        render: (text: string) => moment(text).format("MM/DD/YYYY")
      },
      {
        dataIndex: "amount",
        title: "Amount",
        key: "amount",
        width: "15%",
        align: "right",
        render: (value) => "$" + convertWithCommas(value)
      },
      {
        title: "Project",
        dataIndex: "projectID",
        key: "projectID",
        width: "20%",
        align: "left",
        render: (text: number) => {
          if (!areProjectsLoading && projects.length && text) {
            let project = projects.filter((item) => item.id === text);
            if (project.length) return project[0].name;
          } else return "";
        }
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: "10%",
        align: "left",
        render: (text: string) => <strong>{moment(text).format("MM/DD/YYYY")}</strong>
      },
      {
        title: "MFA JV Account Number",
        dataIndex: "accountID",
        key: "dueDate",
        width: "25%",
        render: (value, record) => {
          const account = value ? accounts.filter((item) => item.accountID === value) : null;
          // const defaultAccount = !value
          //   ? accounts.filter((account) => account.accountID === projects.filter((item) => item.id === record.projectID)[0].accountID)
          //   : null;
          // const defaultAccount = null;
          // console.log("render options", renderAccountOptions(accounts));

          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={record.accountID}
              onChange={(value) =>
                setData(
                  data.map((item) => (item.id === record.id ? { ...item, accountID: value } : item)),
                  record.id
                )
              }
            >
              {[...renderAccountOptions(accounts)]}
            </Select>
          );
        }
      },
      // {
      //   title: "Gl Code",
      //   dataIndex: "MainCmsGlCodeID",
      //   key: "MainCmsGlCodeID",
      //   width: "25%",
      //   render: (value, record) => {
      //     const MainCmsGlCodeID = value ? codes.find((item) => item.id === value) : null;

      //     // const defaultAccount = null;

      //     return !hideApproveAll ? (
      //       <Select
      //         style={{ width: "100%" }}
      //         defaultValue={record.MainCmsGlCodeID}
      //         onChange={(value) => {
      //           console.log("new value", value);

      //           setData(data.map((item) => (item.id === record.id ? { ...item, MainCmsGlCodeID: value } : item)));
      //         }}
      //       >
      //         {renderCodesOptions(codes)}
      //       </Select>
      //     ) : MainCmsGlCodeID ? (
      //       `${MainCmsGlCodeID.gl_code} (${MainCmsGlCodeID.account_name}) 2.0`
      //     ) : (
      //       ""
      //     );
      //   }
      // },
      // {
      //   title: "JV Deposit GL Code",
      //   dataIndex: "JvDepositGlCodeID",
      //   key: "JvDepositGlCodeID",
      //   width: "25%",
      //   render: (value, record) => {
      //     const JvDepositGlCodeID = value ? codes.find((item) => item.id === value) : null;

      //     // const defaultAccount = null;

      //     return !hideApproveAll ? (
      //       <Select
      //         style={{ width: "100%" }}
      //         defaultValue={record.JvDepositGlCodeID}
      //         onChange={(value) => {
      //           console.log("new value", value);

      //           setData(data.map((item) => (item.id === record.id ? { ...item, MainCmsGlCodeID: value } : item)));
      //         }}
      //       >
      //         {renderCodesOptions(codes)}
      //       </Select>
      //     ) : JvDepositGlCodeID ? (
      //       `${JvDepositGlCodeID.gl_code} (${JvDepositGlCodeID.account_name}) 2.0`
      //     ) : (
      //       ""
      //     );
      //   }
      // },
      // {
      //   title: "JV Withdrawal GL Code",
      //   dataIndex: "JvWithdrawalGlCodeID",
      //   key: "JvWithdrawalGlCodeID",
      //   width: "25%",
      //   render: (value, record) => {
      //     const JvWithdrawalGlCodeID = value ? codes.find((item) => item.id === value) : null;

      //     // const defaultAccount = null;

      //     return !hideApproveAll ? (
      //       <Select
      //         style={{ width: "100%" }}
      //         defaultValue={record.JvWithdrawalGlCodeID}
      //         onChange={(value) => {
      //           console.log("new value", value);

      //           setData(data.map((item) => (item.id === record.id ? { ...item, MainCmsGlCodeID: value } : item)));
      //         }}
      //       >
      //         {renderCodesOptions(codes)}
      //       </Select>
      //     ) : JvWithdrawalGlCodeID ? (
      //       `${JvWithdrawalGlCodeID.gl_code} (${JvWithdrawalGlCodeID.account_name}) 2.0`
      //     ) : (
      //       ""
      //     );
      //   }
      // },

      {
        title: () =>
          !hideApproveAll ? (
            <Space>
              <Popconfirm title="Are you sure you want to approve all draw requests?" onConfirm={() => onAction("approve-all")}>
                <Button type="primary">Approve All</Button>
              </Popconfirm>
            </Space>
          ) : (
            "Status"
          ),
        align: "center",
        key: "actions",
        width: "20%",
        render: (value, record) =>
          !record.status && isTreasury ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Space>
                <Popconfirm
                  title="Are you sure you want to approve this draw request?"
                  onConfirm={() => onAction("approve", { ...record, status: "approve" })}
                >
                  <Button type="primary">Approve</Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure you want to deny this draw request?"
                  onConfirm={() => onAction("deny", { ...record, status: "deny" })}
                >
                  <Button type="primary" danger>
                    Deny
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          ) : (
            <Tag color={record.status === "approve" ? "success" : record.status === null ? "blue" : "error"}>
              {record.status === "approve" ? "Approved" : record.status === null ? "Pending" : "Denied"}
            </Tag>
          )
      }
    ],
    [data, hideApproveAll]
  );
  return <Table dataSource={data} columns={columns} loading={loading} pagination={false} bordered scroll={{ x: 1000 }} />;
};
