import { Table } from "antd";
import { FC } from "react";
import { checkListColumns, CheckListType } from "./columns";
import styles from "../../../../components/DetailsTable/DetailsTable.module.css";

export interface Props {
  data: CheckListType[];
  loading: boolean;
}

export const CheckListTable: FC<Props> = ({ data, loading }) => {
  return (
    <Table
      columns={checkListColumns}
      rowClassName={styles.stripedRow}
      dataSource={data}
      size="small"
      bordered
      rowKey={"customerReferenceNumber"}
      loading={loading}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        position: ["bottomRight", "topRight"]
      }}
      sticky
    />
  );
};
