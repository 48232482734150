import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FC, createContext, ReactNode, useState } from "react";
import { accountsService } from "../services/accounts";
import { apiInstance } from "../services/api";
import { cashManagementService } from "../services/cash-management";
import { projectsService } from "../services/projects";
import { Account } from "../types/account";
import { Project } from "../types/admin-management";
import { GLCode } from "../types/cash-management";
import { useAuth } from "./AuthContext";

interface ContextProps {
  accounts: Account[];
  loading: boolean;
  codes: GLCode[];
  startDate: moment.Moment;
  reloadAccounts: () => void;
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  projects: (Project & { accountName?: string })[];
  refetchProjects: () => void;
  areProjectsLoading: boolean;
}

interface Props {
  children?: ReactNode;
}

export const DataContext = createContext<ContextProps>({
  codes: [],
  accounts: [],
  loading: true,
  reloadAccounts: () => {},
  startDate: moment(),
  setStartDate: () => {},
  projects: [],
  refetchProjects: () => {},
  areProjectsLoading: true
});

export const DataContextProvider: FC<Props> = ({ children }) => {
  const { user } = useAuth();
  const hasTreasuryGroup = user.accountGroups.filter((item: string) => item === "Treasury").length;
  //Queries
  const {
    isLoading: loadingAccounts,
    data: accounts,
    refetch
  } = useQuery<Account[]>(["accounts"], () => accountsService.getAllAccounts(), {
    initialData: [],
    enabled: !!apiInstance.defaults.headers.common["Authorization"]
  });

  const {
    data: projects,
    isLoading: areProjectsLoading,
    isFetching,
    refetch: refetchProj
  } = useQuery(["projects"], () => projectsService.getAllProjects(true), {
    initialData: [],
    select: (data) => {
      return data.map((item) => {
        if (item.accountID) {
          const { accountNumber, accountName } = accounts.filter((account) => item.accountID === account.accountID)[0];
          return { ...item, accountName: `${accountName} (${accountNumber})` };
        } else {
          return item;
        }
      });
    },
    enabled: !!accounts.length
  });

  const { data: codes } = useQuery(["gl-codes"], () => cashManagementService.getGLCodes(), { initialData: [] });

  const [startDate, setStartDate] = useState(moment());

  const reloadAccounts = () => refetch();
  const refetchProjects = () => refetchProj();

  return (
    <DataContext.Provider
      value={{
        accounts,
        codes,
        loading: loadingAccounts,
        reloadAccounts,
        startDate,
        setStartDate,
        refetchProjects,
        projects,
        areProjectsLoading: areProjectsLoading || isFetching
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
