import { FC, useContext, useState } from "react";
import { Radio, RadioChangeEvent, Select, Row, Col } from "antd";
import { DataContext } from "../../../../contexts/DataContext";

interface IDisplaySelection {
  selection: { selectedValue: number | number[]; setSelectedValue: React.Dispatch<React.SetStateAction<number | number[]>> };
}

const { Group } = Radio;

const switchOptions = [
  { value: "one", label: "One Account" },
  { value: "two", label: "Two Accounts" }
];

export const DisplaySelection: FC<IDisplaySelection> = ({ selection }) => {
  const { selectedValue, setSelectedValue } = selection;
  const [displayMode, setDisplayMode] = useState("one");
  const { accounts } = useContext(DataContext);

  return (
    <Row justify="center" gutter={[16, 16]}>
      <Col span={24}>
        <Select
          mode={displayMode === "two" ? "multiple" : undefined}
          onChange={(value) => {
            if (Array.isArray(value)) {
              if (value.length > 2) return;
              else setSelectedValue(value);
            } else setSelectedValue(value);
          }}
          value={selectedValue}
          showSearch
          filterOption={(value, option) => !!option?.label?.toString().toLowerCase().includes(value.toLowerCase())}
          placeholder={displayMode === "two" ? "Select accounts" : "Select account"}
          options={accounts.map((item) => ({ value: item.accountID, label: `${item.accountName} (${item.accountNumber})` }))}
          size="large"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={24}>
        <Group
          onChange={(e: RadioChangeEvent) => {
            // We are checking if its one or two accounts so we have consistency with type for select value,
            // it has to be array when its multiple mode, and number when its single mode.
            const display = e.target.value;
            setDisplayMode(display);
            if (display === "one" && Array.isArray(selectedValue)) {
              if (selectedValue.length) setSelectedValue(selectedValue[0]);
              else setSelectedValue(1);
            } else if (display === "two" && !Array.isArray(selectedValue)) {
              setSelectedValue([selectedValue]);
            }
          }}
          value={displayMode}
          optionType="button"
          buttonStyle="solid"
          size="large"
          options={switchOptions}
        />
      </Col>
    </Row>
  );
};
