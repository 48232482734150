import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Row, Col, Form, Divider, message, Select } from "antd";
import moment, { isMoment } from "moment";
import { FC, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { drawRequestsService } from "../../services/draw-request";
import { CreateDrawRequest, DrawRequest, DrawRequestApprovalType } from "../../types/draw-request";
import { DrawRequestForm, DrawRequestsTable } from "./components";
import { IManipulateReportsFilters, IManipulateReportsOptions, ManipulateReports } from "../../components";

interface Props {}

const options: IManipulateReportsOptions = {
  displayOptions: [{ value: "monthly", label: "Monthly" }],
  rangeOptions: [
    // { value: "30-days", label: "Next 30 Days" },
    // { value: "yesterday", label: "Yesterday" },
    // { value: "today", label: "Today" },
    { value: "this-month", label: "This Month" },
    { value: "last-month", label: "Last Month" },
    { value: "range", label: "Range" }
  ],
  searchOption: false
  // downloadOptions: ["excel"]
};

const filterOptions = [
  {
    value: null,
    label: "Pending"
  },
  {
    value: "approve",
    label: "Approved"
  },
  {
    value: "deny",
    label: "Denied"
  },
  {
    value: "",
    label: "All"
  }
];

export const DrawRequests: FC<Props> = () => {
  const [filters, setFilters] = useState<IManipulateReportsFilters>({
    displayMode: "monthly",
    downloadType: "excel",
    rangeOptionValue: "this-month",
    searchValue: "",
    range: [moment().startOf("month"), moment().endOf("month")]
  });
  const { user } = useAuth();
  const { accounts } = useMsal();
  const accountName = accounts && accounts.length ? accounts[0].name : "";
  const isTreasury = !!user.accountGroups.filter((item: string) => item === "Treasury").length;
  const isAccountant = !!user.accountGroups.filter((item: string) => item === "Accounting").length;
  const [drawRequestsData, setDrawRequestsData] = useState<DrawRequest[]>([]);
  const [filterValue, setFilterValue] = useState<"approve" | "deny" | null>(null);
  const [form] = Form.useForm<CreateDrawRequest>();
  const { createDrawRequest, getAllDrawRequests, editDrawRequest, approveAllRequests } = drawRequestsService;

  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      "draw-requests",
      filterValue,
      isMoment(filters.range) ? filters.range.format("MM/DD/YYYY") : (filters.range as moment.Moment[])[0].format("MM/DD/YYYY"),
      isMoment(filters.range) ? filters.range.format("MM/DD/YYYY") : (filters.range as moment.Moment[])[1].format("MM/DD/YYYY")
    ],
    (context) => getAllDrawRequests(filterValue, context.queryKey[2] as string, context.queryKey[3] as string),
    {
      initialData: [],
      onSuccess: (data) => {
        setDrawRequestsData(
          (isAccountant && !isTreasury) || (!isAccountant && !isTreasury) ? data.filter((item) => item.initiator === accountName) : data
        );
      }
    }
  );

  const { mutate: addDrawRequest } = useMutation(["add-draw-request"], (data: CreateDrawRequest) => createDrawRequest(data), {
    onError: () => {
      message.error(`An error ocurred while trying to create draw request`);
    },
    onSuccess: () => {
      message.success(`Successfully created draw request`);
      refetch();
    }
  });

  const { mutate: modifyDrawRequest } = useMutation(
    ["modify-draw-request"],
    ({ data, type, id }: { type: DrawRequestApprovalType; id?: number; data?: CreateDrawRequest | DrawRequest[] }) =>
      type === "approve-all" ? approveAllRequests(data as DrawRequest[]) : editDrawRequest(id!, data! as CreateDrawRequest),
    {
      onSuccess: (_, { type }) => {
        message.success(
          ` ${
            type === "approve"
              ? "The draw request has been approved successufully"
              : type === "deny"
              ? "The draw request has been denied successufully"
              : "All draw request have been approved successfully"
          }`
        );
        refetch();
      }
    }
  );

  const onFinish = (values: CreateDrawRequest) => {
    // console.log(values, "payload values");

    addDrawRequest({ ...values, dueDate: isMoment(values.dueDate) ? values.dueDate.format("YYYY-MM-DD") : values.dueDate });
    refetch();
    form.resetFields();
  };
  const onAction = (type: DrawRequestApprovalType, drawRequest?: DrawRequest) => {
    if (type === "approve-all") {
      // const checkIfAllAccountsAreSet = drawRequestsData.findIndex((item) => item.accountID === null) === -1;
      // if (checkIfAllAccountsAreSet) modifyDrawRequest({ type, data: drawRequestsData.map((item) => ({ ...item, status: "approve" })) });
      // else message.warning("Every draw request needs to have account selected in order to approve them all.");
      modifyDrawRequest({ type, data: drawRequestsData.map((item) => ({ ...item, status: "approve" })) });
    } else {
      if (!!drawRequest) {
        const { id, ...data } = drawRequest;
        // if (data.status === "approve" && !data.accountID) {
        //   message.warning("Select JV Account first.");
        //   return;
        // } else {
        //   modifyDrawRequest({ type, id, data });
        // }
        modifyDrawRequest({ type, id, data });
      }
    }
  };
  const onFiltersChange = (filters: IManipulateReportsFilters) => {
    // console.log("take a look ===>", filters);

    setFilters(filters);
  };

  const setValue = (value: DrawRequest[], id: number) => {
    const newValue = value.find((item) => item.id === id);
    try {
      editDrawRequest(id, newValue as CreateDrawRequest);
      setDrawRequestsData(value);
      message.success(`Successfully edited draw request`);
    } catch (error) {
      console.error(error);

      message.error(`An error ocurred while trying to edit draw request`);
    }
  };

  return (
    <Row gutter={40}>
      <Col xl={8} lg={isTreasury || isAccountant ? 10 : 12} span={18}>
        <Divider orientation="center">Make Draw Request</Divider>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <DrawRequestForm form={form} onFinish={onFinish} />
          </Col>
        </Row>
      </Col>
      <Col xl={16} lg={14}>
        <Divider orientation="center">Draw Requests List</Divider>
        <Row>
          <Col style={{ marginBottom: "15px" }}>
            <ManipulateReports options={options} filters={filters} onFiltersChange={onFiltersChange} onDownloadClick={() => console.log} />
          </Col>
        </Row>
        Filter:
        <Select
          onChange={setFilterValue}
          style={{ width: 200, marginBottom: "1rem", marginLeft: "0.5rem" }}
          options={filterOptions}
          value={filterValue}
        />
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <DrawRequestsTable
              data={drawRequestsData}
              onAction={onAction}
              setData={setValue}
              loading={isLoading || isFetching}
              hideApproveAll={filterValue !== null || !isTreasury}
              isTreasury={isTreasury}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
