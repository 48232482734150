import { Account } from "./account";
import { CashManagementRecord } from "./cash-management";

export type CashManagementModalOptions = {
  payload: (Account & CashManagementRecord) | undefined;
  visible: boolean;
};

export type MutationType = "delete" | "create" | "update";

export const months: { [key: number]: string } = {
  1: "JAN",
  2: "FEB",
  3: "MAR",
  4: "APR",
  5: "MAY",
  6: "JUN",
  7: "JUL",
  8: "AUG",
  9: "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC"
};
