import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Table, Segmented, Row, Col, Form, Button, Tag, Radio, message } from "antd";
import { dailyMonthlyColumns, MixedColumnType, quarterlyColumns } from "./columns";
import moment from "moment";
import styles from "./CashBalance.module.css";
import { useWindowSize } from "../../helpers/useWindowSize";
import style from "../BankStatement/components/BankStatementTable/BankStatementTable.module.css";
import { useQuery } from "@tanstack/react-query";
import { transactionsService } from "../../services/transactions";
import { SegmentedValue } from "rc-segmented";
import { convertWithCommas } from "../../helpers/convertWithCommas";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { generateDownload } from "../../helpers/generateDownload";
import { MainDatePicker } from "../../components";
import { DataContext } from "../../contexts/DataContext";
import { CashBalanceParams } from "../../types/transactions";

const { Summary } = Table;
const { Group, Button: RadioButton } = Radio;

interface QuarterlyTotals {
  totalQ1: number;
  totalQ2: number;
  totalQ3: number;
  totalQ4: number;
}

export const CashBalance: FC = () => {
  //// Hooks
  const [typeAccount, setTypeAccount] = useState<string>("SMBC");
  const { startDate } = useContext(DataContext);
  const [cashBalanceType, setCashBalanceType] = useState<SegmentedValue>("Daily");
  // const [dateValue, setDateValue] = useState<moment.Moment>(moment().startOf("month"));
  const [form] = Form.useForm();
  const { width } = useWindowSize();

  const currentColumns = useMemo(() => {
    switch (cashBalanceType) {
      case "Daily":
        return dailyMonthlyColumns("daily");
      case "Monthly":
        return dailyMonthlyColumns("monthly", startDate);
      case "Quarterly":
        return quarterlyColumns;
    }
  }, [cashBalanceType, startDate]);

  //// Queries
  const {
    isLoading: isCashBalanceLoading,
    data: cashBalanceData,
    isFetching
  } = useQuery(
    ["cash-balance", cashBalanceType, startDate.startOf("month").format("MM/DD/YYYY")],
    async () => {
      const params: CashBalanceParams = {
        balanceType: cashBalanceType,
        startDate:
          cashBalanceType === "Daily"
            ? moment().format("MM/DD/YYYY")
            : cashBalanceType === "Monthly"
            ? startDate.clone().startOf("month").format("MM/DD/YYYY")
            : startDate.clone().startOf("year").format("MM/DD/YYYY"),
        endDate: cashBalanceType === "Monthly" ? startDate.clone().endOf("month").format("MM/DD/YYYY") : null
      };
      let response = await transactionsService.getCashBalance(params);
      return response;
    },
    { retry: 1, onError: () => message.error("Error fetching data.") }
  );

  const [accountsData, setAccountsData] = useState<any[]>([]);

  useEffect(() => {
    const external: number = typeAccount === "SMBC" ? 0 : 1;

    let dataAccounts = [];
    if (cashBalanceData) {
      dataAccounts = cashBalanceData.filter((a: any) => a.IsExternalBank === external);
    }
    setAccountsData(dataAccounts);
  }, [cashBalanceData, typeAccount]);

  ////
  const onFormFinished = async (values: any) => {
    message.loading({ content: "Downloading file...", key: "downloading", duration: 0 });
    try {
      const params: CashBalanceParams = {
        balanceType: cashBalanceType,
        startDate:
          cashBalanceType === "Daily"
            ? moment().format("MM/DD/YYYY")
            : cashBalanceType === "Monthly"
            ? startDate.clone().startOf("month").format("MM/DD/YYYY")
            : startDate.clone().startOf("year").format("MM/DD/YYYY"),
        endDate: cashBalanceType === "Monthly" ? startDate.clone().endOf("month").format("MM/DD/YYYY") : null
      };
      const file = await transactionsService.getCashBalance(params, values.fileFormat);
      const fileName = `${
        cashBalanceType === "Monthly"
          ? startDate.clone().format("MMMM-YY") + "-"
          : cashBalanceType === "Daily"
          ? moment().format("MM-DD-YYYY") + "-"
          : startDate.clone().format("YYYY") + "-"
      }Accounts ${cashBalanceType} Report.${values.fileFormat === "pdf" ? "pdf" : "xlsx"}`;
      generateDownload(file, fileName, values.fileFormat);
      message.success({ content: "Success", key: "downloading" });
    } catch (error) {
      message.error({ content: error, key: "downloading" });
    }
  };

  return (
    <div>
      <Row justify="space-between" style={{ margin: "2rem 0" }}>
        <Col xs={24} sm={24} md={18} className={styles.colWrapper}>
          <h3 className="today-header">Today's date:</h3>
          <Tag color="#108ee9" className="tag">
            {moment().format("MMMM Do YYYY")}
          </Tag>
          <Row style={{ marginBottom: 15, margin: "0 1rem 1rem 1rem" }}>
            <Col span={24} style={{ display: "flex" }}>
              <h3 style={{ marginRight: 15 }}>Account type:</h3>

              <Group
                options={["SMBC", "Non SMBC"]}
                value={typeAccount}
                size="large"
                buttonStyle="solid"
                optionType="button"
                onChange={(e) => setTypeAccount(e.target.value)}
              />
            </Col>
          </Row>
          <div className={styles.typeFilterOption}>
            <Segmented
              options={[
                { label: width < 600 ? "Daily" : "Daily Cash Balance", value: "Daily" },
                { label: width < 600 ? "Monthly" : "Monthly Cash Balance", value: "Monthly" },
                { label: width < 600 ? "Quarterly" : "Quarterly Cash Balance", value: "Quarterly" }
              ]}
              value={cashBalanceType}
              onChange={setCashBalanceType}
              size="large"
            />
          </div>
        </Col>
        <Col xs={24} sm={16} md={6} style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Form form={form} onFinish={onFormFinished} initialValues={{ date: startDate }}>
              {cashBalanceType !== "Daily" ? (
                <Form.Item label="" name="date" rules={[{ required: true, message: "Please select date." }]}>
                  <MainDatePicker type={cashBalanceType === "Quarterly" ? "year" : "month"} />
                </Form.Item>
              ) : (
                ""
              )}
              <Form.Item name="fileFormat" rules={[{ required: true, message: "Please select file format." }]}>
                <Group size="large" buttonStyle="solid">
                  <RadioButton value="pdf">
                    PDF <FilePdfOutlined />
                  </RadioButton>
                  <RadioButton value="excel">
                    <FileExcelOutlined /> Excel
                  </RadioButton>
                </Group>
              </Form.Item>
              <Button block htmlType="submit" size="large" type="primary">
                Download {cashBalanceType} Report
              </Button>
            </Form>
          </div>
        </Col>
      </Row>

      <Table
        rowKey="accountID"
        columns={currentColumns}
        dataSource={accountsData}
        pagination={false}
        size="small"
        scroll={{ x: 1000 }}
        className={style.customThumbTable}
        loading={isCashBalanceLoading || isFetching}
        rowClassName={styles.stripedRow}
        bordered
        sticky
        summary={(data) => {
          const totals: any =
            cashBalanceType === "Quarterly"
              ? data.reduce(
                  (prev: QuarterlyTotals, curr: MixedColumnType) => {
                    return {
                      totalQ1: (prev.totalQ1 += curr.q1),
                      totalQ2: (prev.totalQ2 += curr.q2),
                      totalQ3: (prev.totalQ3 += curr.q3),
                      totalQ4: (prev.totalQ4 += curr.q4)
                    };
                  },
                  { totalQ1: 0, totalQ2: 0, totalQ3: 0, totalQ4: 0 }
                )
              : data.reduce(
                  (prev: any, curr: MixedColumnType) => {
                    return {
                      totalYesterday: (prev.totalYesterday += curr.lastBalance),
                      totalCurrent: (prev.totalCurrent += curr.currentBalance),
                      totalDebit: (prev.totalDebit += curr.debit),
                      totalCredit: (prev.totalCredit += curr.credit),
                      totalVariances: (prev.totalVariances += curr.currentBalance - curr.lastBalance)
                    };
                  },
                  { totalYesterday: 0, totalCurrent: 0, totalDebit: 0, totalCredit: 0, totalVariances: 0 }
                );
          return (
            <Summary fixed="top">
              <Summary.Row style={{ fontWeight: 700, background: "#fafafa" }}>
                <Summary.Cell index={0}>Total</Summary.Cell>
                <Summary.Cell index={1}></Summary.Cell>
                <Summary.Cell index={2}>
                  {"$" + convertWithCommas(cashBalanceType === "Quarterly" ? totals.totalQ1 : totals.totalYesterday)}
                </Summary.Cell>
                <Summary.Cell index={3}>
                  {"$" + convertWithCommas(cashBalanceType === "Quarterly" ? totals.totalQ2 : totals.totalDebit)}
                </Summary.Cell>
                <Summary.Cell index={4}>
                  {"$" + convertWithCommas(cashBalanceType === "Quarterly" ? totals.totalQ3 : totals.totalCredit)}
                </Summary.Cell>
                <Summary.Cell index={5}>
                  {"$" + convertWithCommas(cashBalanceType === "Quarterly" ? totals.totalQ4 : totals.totalCurrent)}
                </Summary.Cell>
                {cashBalanceType !== "Quarterly" ? (
                  <Summary.Cell index={5}>
                    {"$" + convertWithCommas(cashBalanceType === "Quarterly" ? "" : totals.totalVariances)}
                  </Summary.Cell>
                ) : null}
              </Summary.Row>
            </Summary>
          );
        }}
      />
    </div>
  );
};
