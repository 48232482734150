import { CreateGlCode, GlCode } from "../types/gl-code";
import { callApiInstance } from "./api";

class GlCodesService {
  async getAllGlCodes(): Promise<GlCode[]> {
    return await callApiInstance({ url: `/Glcode` });
  }
  async createGlCode(data: CreateGlCode) {
    return await callApiInstance({ url: "/Glcode", method: "POST", data });
  }
  async getSingleGlCode(id: number) {
    return await callApiInstance({ url: `/Glcode/${id}` });
  }
  async editGlcode(id: number, data: CreateGlCode) {
    return await callApiInstance({ url: `/Glcode/${id}`, method: "PUT", data });
  }
  async deleteGlCode(id: number) {
    return await callApiInstance({ url: `/Glcode/${id}`, method: "DELETE" });
  }
}
export const glCodesService = new GlCodesService();
