import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { FC, createContext, ReactNode, useContext, useState, useEffect, useCallback } from "react";
import { apiInstance } from "../services/api";
import { scopes } from "../services/msal-config";
import { authService } from "../services/auth";
import { message } from "antd";
import { useQueryClient } from "@tanstack/react-query";

// const accessTokenAux = `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiIwMTU3YWRkYi0yMjc5LTQwYzktODE4Yy0zYWE1MzA0Y2E2NjEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vYzk1NDA0YjUtOGJhMi00MmUwLTk4MjgtN2VmZjJjOGQ0NTdkL3YyLjAiLCJpYXQiOjE3MDE4MDI5NTUsIm5iZiI6MTcwMTgwMjk1NSwiZXhwIjoxNzAxODA2ODU1LCJhaW8iOiJBVFFBeS84VkFBQUFQMGZEcS9pZU52bWNFdmI0aFRwMEVMUEVTd2s3aVRJcUlSMFl4dDR6SUhtUUxUNDBlTkFTeTk0MHVyUUhDQmxGIiwiaXBhZGRyIjoiNTAuNzMuMTM4LjEiLCJsb2dpbl9oaW50IjoiTy5DaVF6WWpkaVptWTNPUzB6TWpWakxUUmpaV010T0RrM015MDRZamhpWTJJeU5qUmpabU1TSkdNNU5UUXdOR0kxTFRoaVlUSXROREpsTUMwNU9ESTRMVGRsWm1ZeVl6aGtORFUzWkJveU0yNXZiWE52YkhWMGFXOXVjMEJ0YVhSemRXbG1kV1J2YzJGdVlXMWxjbWxqWVM1dmJtMXBZM0p2YzI5bWRDNWpiMjBnY0E9PSIsIm5hbWUiOiIzbm9tIiwibm9uY2UiOiI2MzRiMWZhOS1iNDY4LTRmMzItYWFiZS1mMDkxZWM5NjQzZmQiLCJvaWQiOiIzYjdiZmY3OS0zMjVjLTRjZWMtODk3My04YjhiY2IyNjRjZmMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiIzbm9tc29sdXRpb25zQG1pdHN1aWZ1ZG9zYW5hbWVyaWNhLm9ubWljcm9zb2Z0LmNvbSIsInJoIjoiMC5BUnNBdFFSVXlhS0w0RUtZS0g3X0xJMUZmZHV0VndGNUlzbEFnWXc2cFRCTXBtSFlBT2MuIiwic3ViIjoidTNfZVZmUXctMGI4bTV3eUJVdnh6U05DYmREeWNMMnhvQzh6Sl9JSW1ITSIsInRpZCI6ImM5NTQwNGI1LThiYTItNDJlMC05ODI4LTdlZmYyYzhkNDU3ZCIsInV0aSI6InhaWVFIWUg5SzA2RTVpZGpFaFF5QUEiLCJ2ZXIiOiIyLjAiLCJ3aWRzIjpbIjYyZTkwMzk0LTY5ZjUtNDIzNy05MTkwLTAxMjE3NzE0NWUxMCIsImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdLCJncm91cENsYWltIjpbIjhkMzAzNzJhLWRkMDYtNDRjNi05NDY4LTc0ZmNjNzAwYTEyMSIsIjRhYjgwODY1LTdlZjgtNDI2NS1hNzQ5LTlhZWI0YzU0NDNiNiJdfQ.L5snhDndwE8RN--gUfJuwxrFpkbVNyrNQu7Km-9MaK-ZfLryrk4ssmBEzVMShRc6SCyAnPJPesnlqmFwwnzcddH0GmUHL2ZNqPdZ1wh_lKsWq8hQ_5q9dKg8wle72Rlnq3Nc9Y4U_DE-wYwGINjJUUNMpESz1gzVe3oygd6WR8Rk2P9Yl3EJcAPXw6471R8ftRhs5OUNbA7NxwnBWGU6OGa6OK6ifQvkTANmbPyedpl89gyZAu8h-a-Ab5AuCWRfNwbIkSPmTHBjakPhEGyv01TSlStgElY0bV6Wq4WP2HJlAa6HXo3wFj6xRGmz0cdznM4rFFBY3CqClbVZWGrpug`;

interface AuthContextProps {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  checkIfUserRequiresVerification?: (id: string) => Promise<boolean>;
}

interface Props {
  children?: ReactNode;
}

const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  setUser: () => {}
});

export const AuthContextProvider: FC<Props> = ({ children }) => {
  const { accounts, instance } = useMsal();
  const [user, setUser] = useState<any>(undefined);
  const queryClient = useQueryClient();

  const checkIfUserRequiresVerification = useCallback(
    async (id: string): Promise<boolean> => await authService.userRequiresVerification(id),
    []
  );

  const getUserTokensAndPhoneNumber = useCallback(async () => {
    try {
      const { accessToken } = await instance.acquireTokenSilent({
        scopes,
        account: accounts[0]
      });
      apiInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      const { accessToken: graphToken } = await instance.acquireTokenSilent({
        scopes: ["https://graph.microsoft.com/.default"],
        account: accounts[0]
      });

      const { data } = await axios.get("https://graph.microsoft.com/v1.0/me?$expand=memberOf", {
        headers: { Authorization: `Bearer ${graphToken}` }
      });

      const requiresVerification = await checkIfUserRequiresVerification(accounts[0].localAccountId);

      setUser({
        ...accounts[0],
        mobilePhone: `${data.officeLocation === "USA" ? "+1" : data.officeLocation === "Japan" ? "+81" : "+1"}${data.mobilePhone}`,
        requiresVerification,
        accountGroups: data.memberOf
          .filter((item: any) => item.displayName !== "Mitsui Fudosan America")
          .map((item: any) => item.displayName)
      });
      queryClient.setDefaultOptions({
        queries: {
          refetchOnWindowFocus: false,
          retry: 1
        },
        mutations: {
          onMutate: (variables: any) => {
            const accountGroups = data.memberOf
              .filter((item: any) => item.displayName !== "Mitsui Fudosan America")
              .map((item: any) => item.displayName);
            if (accountGroups.includes("Treasury")) return variables;
            else if (accountGroups.includes("Accounting")) {
              if (variables.hasOwnProperty("drawNumber") && !variables.hasOwnProperty("drawRequestGUID")) return variables;
              else {
                message.error("You don't have permissions to perform this action.");
                return Promise.reject();
              }
            } else return variables;
          }
        }
      });
    } catch (err) {
      message.error(err as string);
    }
  }, [accounts, instance, checkIfUserRequiresVerification]);

  useEffect(() => {
    if (accounts[0]) {
      getUserTokensAndPhoneNumber();
    }
  }, [accounts, getUserTokensAndPhoneNumber]);

  return <AuthContext.Provider value={{ user, setUser, checkIfUserRequiresVerification }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
