// import { useMutation } from "@tanstack/react-query";
import { Modal, Form, Select, InputNumber, Radio, DatePicker, message, Button } from "antd";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { shortTermLoanService } from "../../../../services/short-term-loan";
import { CreditFacility } from "../../../../types/admin-management";
import { CreateShortTermLoan, ShortTermLoanRecord } from "../../../../types/short-term-loan";
// import { MainTableRecord } from "../../ShortTermLoanPlan";
import { convertWithCommas, formattedDate } from "../../../../helpers";
import { OkCancelModal } from "../../../../components/OkCancelModal/OkCancelModal";
import { MainTableRecord } from "../../ShortTermLoanPlan";

interface ILoanModal {
  banksInfo: CreditFacility[];
  modalOptions: {
    visible: boolean;
    payload: { name: string; date: string; id: string | number; recordId: number | string; amount: number } | undefined;
  };
  facilities: CreditFacility[];
  setData: React.Dispatch<React.SetStateAction<MainTableRecord[]>>;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      payload: { name: string; date: string; id: string | number; recordId: number | string; amount: number } | undefined;
    }>
  >;
  handleSubmit: (data: CreateShortTermLoan) => void;
  loans: ShortTermLoanRecord[];
  totalUsedPerFacility: any;
  onDeleteLoan: any;
}

const { Item: FItem } = Form;
const { Group } = Radio;
const { Option } = Select;

const radioOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

export const LoanModal: FC<ILoanModal> = ({
  modalOptions,
  setVisible,
  facilities,
  handleSubmit,
  loans,
  totalUsedPerFacility,
  setData,
  onDeleteLoan,
  banksInfo
}) => {
  // console.log("-==-=- ALL CREDIT FACILITIES-=-=-=", banksInfo);

  const [form] = Form.useForm();
  // const facility = Form.useWatch("creditFacilityID", form);
  const startDate = Form.useWatch("startDate", form);
  const creditFacilityID = Form.useWatch("creditFacilityID", form);
  const isRollover = Form.useWatch("rollover", form);
  const { visible, payload } = modalOptions;

  const isEditing = !!payload;

  // const maxAmount = useMemo(() => {
  //   if (facility) {
  //     let loan = payload
  //       ? loans.filter((item) => item.creditFacilityName === payload.name && moment(item.startDate).isSame(moment(payload.date), "day"))[0]
  //       : undefined;
  //     let current = facilities.filter((item) => item.id === facility)[0];
  //     if (!!totalUsedPerFacility[current.name])
  //       return isEditing && loan
  //         ? current.amount - (totalUsedPerFacility[current.name] - loan?.amount)
  //         : current.amount - totalUsedPerFacility[current.name];
  //     else return current.amount;
  //   } else return Infinity;
  // }, [facility, payload, loans, facilities, totalUsedPerFacility, isEditing]);

  console.log(" PAYLOAD =-=-=-=-=-888888888 ==>", { payload });

  const handleModalClose = () => {
    form.resetFields();
    setVisible({ visible: false, payload: undefined });
  };

  const handleFormFinish = (values: any) => {
    const { rollover, ...rest } = values;
    // console.log(values, "VALUES");
    const finalPayload = {
      ...rest,
      interestRate: rest.interestRate + "%",
      startDate: rest.startDate.format("YYYY-MM-DD"),
      endDate: rest.endDate.format("YYYY-MM-DD")
    };
    if (isEditing)
      // finalPayload.id = loans.filter(
      //   (item) => item.creditFacilityName === payload.name && moment(item.startDate).isSame(moment(payload.date), "day")
      // )[0].id;

      finalPayload.id = loans.find((lo) => lo.id === payload.id)?.id;
    handleSubmit(finalPayload);
    form.resetFields();
  };

  const handleValuesChange = (changedValues: any, values: any) => {
    if (changedValues.hasOwnProperty("creditFacilityID") || changedValues.hasOwnProperty("rollover")) {
      // console.log(values, "HERE");
      if (values.rollover && values.creditFacilityID) {
        let sortedLoans = loans
          .filter((item) => item.creditFacilityID === values.creditFacilityID)
          .sort((a, b) => {
            const aDate = moment(a.endDate).toDate().getTime();
            const bDate = moment(b.endDate).toDate().getTime();
            return aDate > bDate ? 1 : -1;
          });
        if (sortedLoans.length) {
          form.setFieldsValue({
            amount: sortedLoans[0].amount,
            startDate: moment(sortedLoans[sortedLoans.length - 1].endDate),
            endDate: undefined,
            interestRate: parseFloat(sortedLoans[0].interestRate)
          });
        } else message.info("No Previous loan from this bank.");
      }
    }
  };

  // console.log(totalUsedPerFacility);

  const [availableAmount, setAvailableAmount] = useState<number | null>(null);

  const checkBankAndDate = useCallback(() => {
    return !!startDate && !!creditFacilityID;
  }, [startDate, creditFacilityID]);

  const getAllowedAmount = useCallback(async () => {
    try {
      const allowedAmount = await shortTermLoanService.getAllowedAmount(
        creditFacilityID,
        formattedDate(startDate.toISOString()).normal,
        isRollover
      );
      setAvailableAmount(allowedAmount ? allowedAmount : 0);
    } catch (error) {
      console.error(error);
    }
  }, [startDate, creditFacilityID]);

  const [bankAvailableAmount, setBankAvailableAmount] = useState(0);

  const fixBankAvailableAmount = () => {
    if (creditFacilityID) {
      const facilityAmount = banksInfo.find((bank) => (bank.id = creditFacilityID))?.amount || 0;
      setBankAvailableAmount(facilityAmount);
    } else setBankAvailableAmount(0);
  };

  useEffect(() => {
    form.setFieldsValue({ endDate: null });
    form.setFieldsValue({ amount: null });
    setAvailableAmount(null);
    fixBankAvailableAmount();

    if (checkBankAndDate()) {
      getAllowedAmount();
    }
  }, [startDate, creditFacilityID, isRollover]);

  useEffect(() => {
    if (payload) {
      // console.log(payload);
      const loan = loans.find((item) => item.id === payload.id);
      if (loan) {
        form.setFieldsValue({
          creditFacilityID: loan.creditFacilityID,
          startDate: moment(loan.startDate),
          interestRate: parseFloat(loan.interestRate)
        });
        setTimeout(() => {
          form.setFieldsValue({
            amount: loan.amount,
            endDate: moment(loan.endDate)
          });

          setFixAmount(loan.amount);
        }, 300);
      }
    }
  }, [form, loans, payload]);

  const renderFacilities = (facilities: CreditFacility[]) => facilities.map((item) => <Option value={item.id}>{item.name}</Option>);

  const [okCancelModal, setOkCancelModal] = useState(false);

  const deleteLoan = async () => {
    let id = null;
    try {
      setOkCancelModal(false);

      await shortTermLoanService.deleteLoan(payload!.id);
      setData((oldData) => {
        return oldData
          .map((record) => {
            if (record.id !== payload!.recordId) return record;

            const auxRecord = { ...record };
            id = auxRecord[`${payload!.name}_id`];
            delete auxRecord[payload!.name];
            delete auxRecord[`${payload!.name}_id`];

            return auxRecord;
          })
          .filter((nextRecord) => Object.keys(nextRecord).length > 5);
      });

      onDeleteLoan(payload!.amount, id);
      handleModalClose();
      message.success("Loan successfully deleted");
    } catch (error) {
      console.error(error);
      message.error("There was an error deleting the loan, please refresh and try again");
    }
  };

  const editButtons = [
    <Button key="cancel" onClick={() => handleModalClose()}>
      Cancel
    </Button>,
    <Button key="delete" type="primary" danger onClick={() => setOkCancelModal(true)}>
      Delete
    </Button>,
    <Button key="submit" type="primary" onClick={() => form.submit()}>
      Edit
    </Button>
  ];
  const createButtons = [
    <Button key="cancel" onClick={() => handleModalClose()}>
      Cancel
    </Button>,

    <Button key="submit" type="primary" onClick={() => form.submit()}>
      Create
    </Button>
  ];

  const [fixAmount, setFixAmount] = useState(0);

  const fixAmountRollOver = (value: any) => {
    // if (value.target.value) {
    //   const creditFacilityID = form.getFieldValue("creditFacilityID");
    //   let sortedLoans = loans
    //     .filter((item) => item.creditFacilityID === creditFacilityID)
    //     .sort((a, b) => {
    //       const aDate = moment(a.endDate).toDate().getTime();
    //       const bDate = moment(b.endDate).toDate().getTime();
    //       return aDate > bDate ? 1 : -1;
    //     });
    //   if (sortedLoans.length) {
    //     let index = 0;
    //     for (let i = 1; i < sortedLoans.length; i++) {
    //       const endDateOne = moment(sortedLoans[index].endDate);
    //       const endDateTwo = moment(sortedLoans[i].endDate);
    //       if (endDateOne.isSame(endDateTwo)) index = i;
    //       else break;
    //     }
    //     let auxAmount = 0;
    //     for (let i = 0; i <= index; i++) {
    //       auxAmount += sortedLoans[i].amount;
    //     }
    //     console.log("setFixAmount-=-=->>>", auxAmount, index);
    //     setFixAmount(auxAmount);
    //   }
    // } else setFixAmount(0);
  };

  return (
    <>
      <Modal
        visible={visible}
        title={isEditing ? "Edit short term loan" : "Short term loan register"}
        centered
        onCancel={() => handleModalClose()}
        onOk={() => form.submit()}
        // okText={isEditing ? "Edit" : "Submit"}
        footer={isEditing ? editButtons : createButtons}
      >
        <Form
          labelCol={{ span: 12 }}
          labelAlign="left"
          form={form}
          onFinish={handleFormFinish}
          onValuesChange={handleValuesChange}
          initialValues={payload || { rollover: false }}
        >
          <FItem name="creditFacilityID" label="Select Bank" rules={[{ required: true }]}>
            <Select disabled={isEditing}>{renderFacilities(facilities)}</Select>
          </FItem>
          <FItem name="rollover" label="Rollover to previous loan?" labelAlign="left">
            <Group options={radioOptions} disabled={!form.getFieldValue("creditFacilityID") || isEditing} onChange={fixAmountRollOver} />
          </FItem>
          <FItem name="startDate" label="Start date" rules={[{ required: true }]}>
            <DatePicker
              disabled={isRollover}
              style={{ width: "100%" }}
              format="MM/DD/YYYY"
              // disabledDate={(currentDate) => moment().subtract(1, "day").isAfter(currentDate)}
              onChange={() => setFixAmount(0)}
            />
          </FItem>
          <FItem name="endDate" label="End Date" rules={[{ required: true }]}>
            <DatePicker
              disabled={!checkBankAndDate()}
              style={{ width: "100%" }}
              format="MM/DD/YYYY"
              disabledDate={(currentDate) => {
                const auxDate = moment(formattedDate(currentDate.toISOString()).changed);
                return startDate?.isSameOrAfter(auxDate.subtract(0, "day"));
              }}
            />
          </FItem>

          <FItem
            name="amount"
            label={
              availableAmount !== null
                ? `Amount (Max - ${!isEditing ? convertWithCommas(availableAmount) : convertWithCommas(availableAmount + fixAmount)})`
                : "Amount"
            }
            rules={[
              {
                required: true,
                max: availableAmount! + fixAmount > bankAvailableAmount ? bankAvailableAmount : availableAmount! + fixAmount,
                type: "number"
              }
            ]}
          >
            <InputNumber disabled={availableAmount === null} prefix={"$"} style={{ width: "100%" }} />
          </FItem>
          <FItem name="interestRate" label="Interest Rate" rules={[{ required: true }]}>
            <InputNumber prefix={"%"} />
          </FItem>
        </Form>
      </Modal>
      <OkCancelModal handleCancel={setOkCancelModal} handleOk={deleteLoan} isModalOpen={okCancelModal} />
    </>
  );
};
