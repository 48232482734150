import { CashManagementRecord, CreateCashManagementRecord, GLCode } from "../types/cash-management";
import { callApiInstance } from "./api";

class CashManagementService {
  private rootRoute: string;
  constructor() {
    this.rootRoute = "/CashManagement";
  }
  async getGLCodes(): Promise<GLCode[]> {
    return await callApiInstance({ url: `${this.rootRoute}/getGLCodes` });
  }
  async getCashManagementRecords(accounts: number | number[], startDate: string, endDate: string, searchTerm: string): Promise<CashManagementRecord[][]> {
    return await callApiInstance({
      url: `${this.rootRoute}?ids=${Array.isArray(accounts) ? accounts.join(",") : accounts}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`
    });
  }
  async downloadCashManagementRecords(accounts: number | number[], startDate: string, endDate: string): Promise<any> {
    return await callApiInstance({
      url: `${this.rootRoute}/excel?ids=${Array.isArray(accounts) ? accounts.join(",") : accounts
        }&startDate=${startDate}&endDate=${endDate}`,
      responseType: "arraybuffer"
    });
  }
  async addRecord(data: CreateCashManagementRecord[]) {
    return await callApiInstance({ url: `${this.rootRoute}`, method: "POST", data });
  }
  async editRecord(data: Omit<CashManagementRecord, "balance" | "gl_code">[]) {
    return await callApiInstance({ url: `${this.rootRoute}`, method: "PUT", data });
  }
  async deleteRecord(id: string | number) {
    return await callApiInstance({ url: `${this.rootRoute}?id=${id}`, method: "DELETE" });
  }
}
export const cashManagementService = new CashManagementService();
