

import {
  DistributionCapitalCallDisplayMode,
  DistributionCapitalCallMonthlyRecord,
  DistributionCapitalCallYearlyRecord,
  DistributionCapitalCallSummary
} from "../types/capital-call";
import { callApiInstance } from "./api";


class DistributionCapitalCallService {
  private rootRoute: string;
  constructor() {
    this.rootRoute = "/DistributionCapitalCall";
  }
  async getDistributionsAndCapitalCall(
    displayMode: DistributionCapitalCallDisplayMode,
    startDate?: string,
    endDate?: string,
    selection?: "distribution" | "capital_call"
  ): Promise<DistributionCapitalCallMonthlyRecord[][] | DistributionCapitalCallYearlyRecord[] | DistributionCapitalCallSummary[]> {

    console.log('check the params here -=-=-> ', {
      displayMode,
      startDate,
      endDate,
      selection
    });
    return await callApiInstance({
      url: `${this.rootRoute}?displayMode=${displayMode}${startDate ? "&startDate=" + startDate : ""}${endDate ? "&endDate=" + endDate : ""
        }${selection ? "&type=" + selection : ""}`
    });
  }

  async downloadExcel(displayMode: DistributionCapitalCallDisplayMode,
    startDate?: string,
    endDate?: string,
  ) {
    const url = `/DistributionCapitalCall/excel`
    return await callApiInstance({
      url,
      // responseType: downloadFormat ? "arraybuffer" : "json",
      responseType: "arraybuffer",
      params: {
        displayMode,
        startDate,
        endDate,
      }

    });

  }


}
export const distributionCapitalCallService = new DistributionCapitalCallService();
