import moment from "moment";
import { ShortTermLoanRecord } from "../types/short-term-loan"
import { formattedDate } from "./formattedDate";

export const totalUsedPerFacilityCurrentDate = (facility: string, loans: ShortTermLoanRecord[]) => {
    let total = 0

    const currentDate = moment(formattedDate(moment().toISOString()).changed).toDate().getTime()

    const loansAux = loans.filter(auxLoan => auxLoan.creditFacilityName === facility)

    const inRange = loansAux.filter(auxLoan => {
        const start = moment(auxLoan.startDate).toDate().getTime()
        const end = moment(auxLoan.endDate).toDate().getTime()
        return ((start <= currentDate) && (currentDate < end))
    })

    inRange.forEach(auxLoan => {
        total += auxLoan.amount
    })

    return total
}