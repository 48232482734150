import { Modal } from "antd";

export const OkCancelModal = ({
  handleOk,
  handleCancel,
  isModalOpen,
  centered = true,
  body = "Are you sure you want to delete this record?",
  title = "Delete"
}: {
  handleOk: any;
  handleCancel: any;
  isModalOpen: boolean;
  centered?: boolean;
  body?: string;
  title?: string;
}) => {
  return (
    <>
      <Modal centered={centered} visible={isModalOpen} title={title} onOk={handleOk} onCancel={() => handleCancel(false)}>
        {body}
      </Modal>
    </>
  );
};
