import { CreateDrawRequest, DrawRequest, DrawRequestApprovalType } from "../types/draw-request";
import { callApiInstance } from "./api";

class DrawRequestsService {
  async getAllDrawRequests(status: "approve" | "deny" | "" | null, startDate: string, endDate: string): Promise<DrawRequest[]> {
    return await callApiInstance({ url: `/DrawRequest?status=${status}&startDate=${startDate}&endDate=${endDate}` });
  }
  async approveAllRequests(data: DrawRequest[]) {
    return await callApiInstance({
      url: "/DrawRequest/ApproveAllDrawRequest",
      method: "POST",
      data
    });
  }
  async createDrawRequest(data: CreateDrawRequest) {
    return await callApiInstance({ url: "/DrawRequest", method: "POST", data });
  }
  //   async getSingle(id: string) {
  //     return await callApiInstance({ url: `/Project/${id}` });
  //   }
  async editDrawRequest(id: number, data: CreateDrawRequest) {
    return await callApiInstance({ url: `/DrawRequest/${id}`, method: "PUT", data });
  }
  //   async de(id: string) {
  //     return await callApiInstance({ url: `/DrawRequest/${id}`, method: "DELETE" });
  //   }
}
export const drawRequestsService = new DrawRequestsService();
