import { ColumnsType } from "antd/es/table/interface";
import moment from "moment";
import { convertWithCommas } from "../../helpers/convertWithCommas";
export type DetailsType = {
  date: number | string;
  description: string;
  check: number | null;
  debit: number | null;
  credit: number | null;
  currentBalance: number;
  reference: string;
  runningTotal?: number;
  amount?: number;
  code?: string;
  transactionTypeID?: number;
  type?: string;
};

export const detailsTableColumns = (sorters: string[]): ColumnsType<DetailsType> => {
  let columns: ColumnsType<DetailsType> = [
    {
      title: "Posting Date",
      dataIndex: "date",
      key: "date",
      width: "10%",
      render: (value: string) => (value === "Total" ? value : moment(value).format("D-MMM-YY")),
      sorter: sorters.includes("date") ? (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime() : false
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "18%",

      filters: [
        {
          text: "Sweep Credit",
          value: "Sweep Credit"
        },
        {
          text: "Sweep Debit",
          value: "Sweep Debit"
        },
        {
          text: "Fedwire Credit",
          value: "Fedwire Credit"
        },
        {
          text: "Fedwire Debit",
          value: "Fedwire Debit"
        },
        {
          text: "Check Credit",
          value: "Check Credit"
        },
        {
          text: "Check Debit",
          value: "Check Debit"
        },
        {
          text: "ACH Credit",
          value: "ACH Credit"
        },
        {
          text: "ACH Debit",
          value: "ACH Debit"
        },
        {
          text: "Interest Paid",
          value: "Interest Paid"
        },
        {
          text: "Lockbox",
          value: "Lockbox"
        }
      ]
    },
    {
      title: "Check",
      dataIndex: "customerReferenceNumber",
      key: "customerReferenceNumber",
      width: "8%",
      render: (text: string, record) => (text ? (record.description === "CHECK WITHDRAWAL" ? text : "") : ""),
      // sorter: (a: any, b: any) => a.description === 'CHECK WITHDRAWAL' ? parseInt(a.customerReferenceNumber) - parseInt(b.customerReferenceNumber)
      sorter: (a: any, b: any) =>
        a.description === "CHECK WITHDRAWAL"
          ? b.description === "CHECK WITHDRAWAL"
            ? parseInt(a.customerReferenceNumber) - parseInt(b.customerReferenceNumber)
            : 1
          : -1
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      width: "10%",
      render: (text: number, record: any) =>
        text !== undefined && text !== null && record.transactionType !== "Opening Ledger" && record.transactionType !== "Closing Ledger"
          ? "$ " + convertWithCommas(text)
          : record.type === "DB"
          ? "$ " + convertWithCommas(record.amount)
          : "",
      sorter: (a: any, b: any) =>
        a.debit ? (b.debit ? a.debit - b.debit : a.debit) : a.type === "DB" ? (b.type === "DB" ? a.amount - b.amount : a.amount) : -1
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      width: "10%",
      render: (text: number, record: any) =>
        text !== undefined &&
        text !== null &&
        record.transactionType !== "Opening Ledger" &&
        record.transactionType !== "Closing Ledger" &&
        record.description !== "Opening Balance"
          ? "$ " + convertWithCommas(text)
          : record.type === "CR" && record.transactionType !== "Opening Ledger" && record.transactionType !== "Closing Ledger"
          ? "$ " + convertWithCommas(record.amount)
          : "",
      sorter: (a: any, b: any) =>
        a.credit ? (b.credit ? a.credit - b.credit : a.credit) : a.type === "CR" ? (b.type === "CR" ? a.amount - b.amount : a.amount) : -1
    },
    {
      title: "Current Balance",
      dataIndex: "runningTotal",
      key: "runningTotal",
      width: "12%",
      render: (text: number, record: any) => {
        console.log({ text, record });

        return text !== undefined && text !== null
          ? "$ " + convertWithCommas(text)
          : record.transactionType && record.transactionType.includes("Ledger")
          ? "$ " + convertWithCommas(record.amount)
          : "";
      },
      sorter: sorters.includes("runningTotal") ? (a: any, b: any) => a.runningTotal - b.runningTotal : false
    },
    {
      title: "Reference",
      dataIndex: "bankReferenceNumber",
      key: "bankReferenceNumber",
      width: "32%",
      render: (text) => <p style={{ fontSize: "0.7rem", marginBottom: 0 }}>{text}</p>
    }
  ];
  return columns;
};
