import { InputNumber, Input, Select, Form, DatePicker } from "antd";
import moment from "moment";
import { FC, HTMLAttributes, useContext } from "react";
import { DynamicTextarea } from "../../../../components/DynamicTextearea/DynamicTextarea";
import { DataContext } from "../../../../contexts/DataContext";
import { CashManagementRecord } from "../../../../types/cash-management";

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "select" | "date";
  record: CashManagementRecord;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const { codes } = useContext(DataContext);
  const editOnlyDescription = record?.isTransaction;
  const isMemo = editing && dataIndex === "memo";
  const inputNode =
    inputType === "number" ? (
      <InputNumber prefix={"$"} style={{ width: "100%", textAlign: "right" }} />
    ) : isMemo ? (
      <Input.TextArea
      // defaultValue={record[dataIndex as keyof typeof record] ? (record[dataIndex as keyof typeof record] as string) : ""}
      // disabled={false}
      />
    ) : inputType === "text" ? (
      <Input style={{ width: "100%" }} />
    ) : inputType === "date" ? (
      <DatePicker
        format="MM/DD/YYYY"
        style={{ width: "100%" }}
        disabledDate={(currentDate) => moment().subtract(1, "day").isAfter(currentDate)}
      />
    ) : (
      <Select
        showSearch={true}
        filterOption={(input, option) => !!option?.label.toLowerCase().includes(input.toLowerCase())}
        options={codes.map((item) => ({ label: `${item.gl_code} (${item.account_name})`, value: item.id }))}
      />
    );

  return (
    <td {...restProps}>
      {editing ? (
        dataIndex !== "description" && dataIndex !== "glCodeID" ? (
          !editOnlyDescription || isMemo ? (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              initialValue={record[dataIndex as keyof typeof record] ? record[dataIndex as keyof typeof record] : null}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ]}
            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )
        ) : (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            initialValue={record[dataIndex as keyof typeof record]}
            rules={[
              {
                required: dataIndex !== "glCodeID",
                message: `Please Input ${title}!`
              }
            ]}
          >
            {inputNode}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};
