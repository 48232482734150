import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal, Form, Input, Select, Radio, message } from "antd";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { CashManagementTable } from "..";
import { DataContext } from "../../../../contexts/DataContext";
import { cashManagementService } from "../../../../services/cash-management";
import { CashManagementModalOptions } from "../../../../types";
import { Account } from "../../../../types/account";
import { CreateCashManagementRecord } from "../../../../types/cash-management";

interface IAccountModal {
  options: CashManagementModalOptions;
  setOptions: React.Dispatch<React.SetStateAction<CashManagementModalOptions>>;
}
const { Item: FItem } = Form;
const { Group } = Radio;
const { Option } = Select;

const radioOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

export const AccountModal: FC<IAccountModal> = ({ options, setOptions }) => {
  const [modalType, setModalType] = useState<"form" | "table">("form");
  const [form] = Form.useForm();
  const isForm = modalType === "form";
  const { accounts, codes } = useContext(DataContext);
  const { visible, payload } = options;

  const { mutate: createRecord } = useMutation((data: CreateCashManagementRecord) => cashManagementService.addRecord([data]), {
    onSuccess: () => {
      message.success({ content: `Sucessfully copied record.`, key: "copying", duration: 3 });
      setModalType("table");
    },
    onError: () => {
      message.error({ content: `An error ocurred while copying the record.`, key: "copying", duration: 3 });
    }
  });

  const {
    data: records,
    isLoading,
    isFetching
  } = useQuery(
    ["cash-management", form.getFieldValue("accountID")],
    () =>
      cashManagementService.getCashManagementRecords(
        form.getFieldValue("accountID"),
        moment().format("MM/DD/YYYY"),
        moment().add(1, "month").format("MM/DD/YYYY"),
        ""
      ),
    {
      initialData: [],
      enabled: !isForm && !!form.getFieldValue("accountID")
    }
  );

  const renderAccounts = (accounts: Account[]) =>
    accounts
      .filter((item) => item.accountID !== payload?.accountID)
      .map((item) => (
        <Option value={item.accountID}>
          {item.accountName} ({item.accountNumber})
        </Option>
      ));

  const renderGLCodes = () => {
    return codes.map((item) => (
      <Option value={item.id}>
        {item.gl_code} ({item.account_name})
      </Option>
    ));
  };

  const handleFormFinish = (values: any) => {
    const { reverse, ...rest } = values;
    message.loading({ content: "Copying record to other account...", key: "copying", duration: 0 });

    const auxRecord = { ...rest, date: payload?.date, amount: payload && reverse ? payload.amount * -1 : payload?.amount };

    createRecord({ ...auxRecord });
    // setModalType("table");
  };

  const handleModalClose = () => {
    setOptions({ visible: false, payload: undefined });
    form.resetFields();
    setModalType("form");
  };

  useEffect(() => {
    if (payload) form.setFieldsValue({ glCodeID: payload.glCodeID, description: payload.description });
  }, [payload, form]);

  return (
    <Modal
      visible={visible}
      title={isForm ? "Copy data to other account" : "Preview of account"}
      width={isForm ? undefined : "auto"}
      centered
      closeIcon={<></>}
      onCancel={() => handleModalClose()}
      onOk={() => (isForm ? form.submit() : handleModalClose())}
    >
      {modalType === "form" ? (
        <Form labelCol={{ span: 6 }} labelAlign="left" form={form} onFinish={handleFormFinish} initialValues={{ reverse: false }}>
          <FItem name="accountID" label="Copy to" required>
            <Select showSearch filterOption={(value, option) => !!option?.children?.toString().toLowerCase().includes(value.toLowerCase())}>
              {renderAccounts(accounts)}
            </Select>
          </FItem>
          <FItem name="glCodeID" label="GL Code" required>
            <Select>{renderGLCodes()}</Select>
          </FItem>
          <FItem name="description" label="Memo">
            <Input />
          </FItem>
          <FItem name="reverse" label="Reverse the number sign?" labelCol={{ span: 12 }} labelAlign="left">
            <Group options={radioOptions} />
          </FItem>
        </Form>
      ) : (
        <CashManagementTable
          account={{
            ...accounts.filter((item) => item.accountID === form.getFieldValue("accountID"))[0],
            code: "----"
          }}
          data={records[0]}
          isLoading={isLoading || isFetching}
        />
      )}
    </Modal>
  );
};
